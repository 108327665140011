import { useEffect, useState, useRef } from "react"
import { useDownloadExcel } from 'react-export-table-to-excel';
import AppLayout from "../../components/AppLayout"
import InputTable from "../../components/InputTable"
import { NavLink } from "react-router-dom"
import OilModalAdd from "../../components/OilModalAdd"
import { useStoreSelector } from "../../store/useStore"
import oilFunction from "../../hooks/oilFunction"
import EmptyTable from "../../components/EmptyTable"
import Loader from "../../components/Loader"
import helpers from "../../utils/helpers"
import Pagination from "../../components/Pagination"
import DeleteModal from "../../components/DeleteModal"
import OilModalEdit from "../../components/OilModalEdit"
import OilModalPrice from "../../components/OilModalPrice"
import InputOilPop from "../../components/inputOilPop";
import InputGasPop from "../../components/inputGasPop";
const deleteBtn: string = require('../../assets/icons/delete.svg').default

export default () => {
   const tableRef = useRef(null);
   const [exportBtn, setExportBtn] = useState(false)
   const tableRef2 = useRef(null);




   const [newDate, setNewDate] = useState({ start: "", end: "" })
   const [newDataAdd, setNewDataAdd] = useState(false)

   const [vessel, setVessel] = useState(true)
   const [price, setPrice] = useState(false)

   const { onDownload } = useDownloadExcel({
      currentTableRef: price ? tableRef2.current : tableRef.current,
      filename: price ? 'price-table' : 'Vessel table',
      sheet: price ? 'Price' : 'Vessel'
   })
   const { getOilEntry, getPriceEntry, getAllPriceEntryCount, getAllOilEntryCount, deletePriceEntry, deleteVesselEntry, getpriceCountStatus2, getOilEntryCountStatus } = oilFunction()
   const { userData, gasData, isLoading, priceData, priceCount, gasCount, singlePriceData, singleOilData } = useStoreSelector(["userData", "gasData", "isLoading", "priceData", "priceCount", "gasCount", "singlePriceData", "singleOilData"])
   const [deleteModal1, setDeleteModal1] = useState({ data: {}, status: false })
   const [deleteModal2, setDeleteModal2] = useState({ data: {}, status: false })
   const [editData, setEditData] = useState({ data: {}, status: false })
   const [editDataPrice, setEditDataPrice] = useState({ data: {}, status: false })
   const [dataModPrice, setDataModPrice] = useState({ data: {}, status: false })
   const [dataModOil, setDataModOil] = useState({ data: {}, status: false })
   const setTable = (type: string) => {
      if (type === "price") {
         setVessel(false)
         setPrice(true)
      } else {
         setVessel(true)
         setPrice(false)
      }
   }
   const [activePage, setActivePage] = useState(1)
   const [activePage1, setActivePage1] = useState(1)
   useEffect(() => {
      getOilEntry()
      getPriceEntry()
      getAllPriceEntryCount()
      getAllOilEntryCount()
      getpriceCountStatus2()
      getOilEntryCountStatus()
   }, [])
   let userType = userData?.user_type
   let dept = userData?.department
   return (
      <AppLayout active="input" title="Data Inputs">
         <div className="container-fluid">
            <div className="card h-auto">
               <div className="row">
                  <div className="col-xl-12">
                     <div className="page-titles myheaderNew">
                        <div className="custom-tab-1">
                           {userType === "superadmin" || userType === "admin" ?
                              <ul className="nav nav-tabs liPad">
                                 <li className="nav-item"><NavLink to="/input/gas" className="nav-link active">Oil & Gas</NavLink>
                                 </li>
                                 <li className="nav-item"><NavLink to="/input/mining" className="nav-link">Mining</NavLink>
                                 </li>
                                 <li className="nav-item"><NavLink to="/input/comodity" className="nav-link">Commodity Prices</NavLink>
                                 </li>
                                 <li className="nav-item"><NavLink to="/input/trading" className="nav-link">Trading</NavLink>
                                 </li>
                                 <li className="nav-item"><NavLink to="/input/human" className="nav-link">Human Resources</NavLink>
                                 </li>
                              </ul>
                              : ""}
                           {userType === "data-officer" || dept === "oil & gas" ?
                              <ul className="nav nav-tabs">
                                 <li className="nav-item"><NavLink to="/input/gas" className="nav-link active">Oil & Gas</NavLink>
                                 </li>
                              </ul>
                              : ""}
                        </div>
                        <div className="d-flex flex-wrap my-0 my-sm-0 py-sm-2 py-xl-0">
                           <div className="invoice-btn ">
                              <div className="btn-group mb-1">
                                 <button type="button" className="btn btn-primary brandGreen" onClick={() => { setNewDataAdd(true) }}>Add New</button>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-6">
                     <div className="container mttop">
                        <div className="d-flex align-items-center ml-2">
                           <select className="image-select default-select wide dashboard-select pselect3" id="box" aria-label="Default" onChange={(e) => setTable(e.target.value)}>
                              {/* <option value="">Select Entry</option> */}
                              <option value="vessel">Vessel Entry</option>
                              <option value="price">Price Entry</option>
                           </select>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-6">
                     <div className="row">

                        <div className="col-xl-8 col-lg-8 col-8">
                           <div className=" mttop">
                              {/* <label className="form-label">Start - End</label> */}
                              <div className="input-group">
                                 <input className="form-control newInput" required onChange={(e) => setNewDate(edata => ({ ...edata, start: e.target.value.trim() }))} type="date" placeholder="Now" />
                                 <input className="form-control newInput" required onChange={(e) => setNewDate(edata => ({ ...edata, end: e.target.value.trim() }))} type="date" placeholder="Now" />
                                 <button type="button" id="check-minutes" className="btn btn-ft primaryBG3 newInput"
                                    onClick={(e) => {
                                       e.preventDefault()
                                       if (newDate.start === '') return helpers.showToast("error", "Start date is required")
                                       if (newDate.end === '') return helpers.showToast("error", "End date is required")
                                       vessel ? getOilEntry(newDate.start, newDate.end, '', 1, 500) : getPriceEntry(newDate.start, newDate.end, '', 1, 500)
                                       vessel ? getOilEntryCountStatus(newDate.start, newDate.end) : getpriceCountStatus2(newDate.start, newDate.end)
                                       setExportBtn(true)

                                    }}>Filter</button>
                              </div>
                           </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-6">
                           <div className="btn-group dropdown mb-1 mll20">
                              <button type="button" disabled={!exportBtn} className={`btn btn-primary ${exportBtn ? "brandGreen" : "brandGray"}`} onClick={onDownload}>Export Excel</button>
                              <button type="button" disabled={!exportBtn} className={`btn btn-primary ${exportBtn ? "brandGreen" : "brandGray"} dropdown-toggle dropdown-toggle-split`} data-bs-toggle="dropdown">
                              </button>
                              <div className="dropdown-menu">
                                 {price && <a className="dropdown-item" href="javascript:void(0);" onClick={() => setDataModPrice({ status: true, data: singlePriceData })}>View Price Graph</a>}
                                 {vessel && <a className="dropdown-item" href="javascript:void(0);" onClick={() => setDataModOil({ status: true, data: singleOilData })}>View Oil Graph</a>}
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>

               </div>
               {isLoading && <Loader style={{ position: "relative" }} />}
               {vessel ?
                  <div>
                     {!isLoading && gasData && gasData.length > 0 ?
                        <div className="row" style={{ padding: "20px" }}>
                           <div className="col-12 mbb-40">
                              <InputTable refData={tableRef} data={gasData || []} headers={["Input Type", "Data officer", "Total Vol", "discharge Vol", "Date", "Time", "Action"]} tableTitle="">
                                 {(gasData || []).map((item: any, i: any) => (
                                    <tr>
                                       <th>{item?.title.toUpperCase()}</th>
                                       <th>{item?.admin_data?.fullname}</th>
                                       <th>{helpers.formatMoney(item?.total_volume)}</th>
                                       <th>{helpers.formatMoney(item?.discharge_volume)}</th>
                                       <td>{new Date(item.date).toDateString()}</td>
                                       <td>{helpers.get24HourTo12Hours((item.createdAt))}</td>
                                       <td>
                                          <div className="d-flex jspace2">
                                             <a href="#" onClick={() => setEditData({ status: true, data: item })} className="btn btn-xs sharp editText">Update</a>
                                             <a href="#" onClick={() => setDeleteModal1({ status: true, data: { auth_id: item.entry_id } })} className="btn btn-xs sharp"><img className='delete-img' src={deleteBtn} /></a>
                                          </div>
                                       </td>
                                    </tr>))}

                              </InputTable>
                           </div>
                        </div>
                        : !isLoading ? <EmptyTable /> : null}
                     <div className="">
                        {gasCount && gasCount?.total > 5 ?
                           <Pagination activePage={activePage1} itemPerPage={50} totalPage={gasCount?.total} onPress={(nextPage) => {
                              getOilEntry('', '', '', nextPage)
                              setActivePage1(nextPage)
                           }} /> : ""}
                     </div>
                  </div>
                  : ""}
               {price ?
                  <div>
                     {!isLoading && priceData && priceData.length > 0 ?
                        <div className="row" style={{ padding: "20px" }}>
                           <div className="col-12 mbb-40">
                              <InputTable refData={tableRef2} data={priceData || []} headers={["Input Type", "Data officer", "sold vol", "Unit price", "Date", "Time", "Action"]} tableTitle="">
                                 {(priceData || []).map((item: any, i: any) => (
                                    <tr>
                                       <th>{item?.type.toUpperCase()}</th>
                                       <th>{item?.admin_data?.fullname}</th>
                                       <th>{helpers.formatMoney(item?.sold_volume)}</th>
                                       <th>{helpers.formatMoney(item?.unit_price)}</th>
                                       <td>{new Date(item.created).toDateString()}</td>
                                       <td>{helpers.get24HourTo12Hours((item.createdAt))}</td>
                                       <td>
                                          <div className="d-flex jspace2">
                                             <a href="#" onClick={() => setEditDataPrice({ status: true, data: item })} className="btn btn-xs sharp editText">Update</a>
                                             <a href="#" onClick={() => setDeleteModal2({ status: true, data: { auth_id: item.entry_id } })} className="btn btn-xs sharp"><img className='delete-img' src={deleteBtn} /></a>
                                          </div>
                                       </td>
                                    </tr>))}

                              </InputTable>
                           </div>
                        </div>
                        : !isLoading ? <EmptyTable /> : null}
                     <div className="">
                        {priceCount && priceCount?.total > 5 ?
                           <Pagination activePage={activePage} itemPerPage={20} totalPage={priceCount?.total} onPress={(nextPage) => {
                              getPriceEntry('', '', '', nextPage)
                              setActivePage(nextPage)
                           }} /> : ""}
                     </div>
                  </div>
                  : ""}
            </div>
            {newDataAdd &&
               <OilModalAdd onPress={() => setNewDataAdd(false)} />
            }
            {deleteModal1.status &&
               <DeleteModal data={deleteModal1.data} onSubmit={deleteVesselEntry} onPress={() => setDeleteModal1({ status: false, data: {} })} />
            }
            {deleteModal2.status &&
               <DeleteModal data={deleteModal2.data} onSubmit={deletePriceEntry} onPress={() => setDeleteModal2({ status: false, data: {} })} />
            }
            {editData.status &&
               <OilModalEdit data={editData.data} onPress={() => setEditData({ status: false, data: {} })} />
            }
            {editDataPrice.status &&
               <OilModalPrice data={editDataPrice.data} onPress={() => setEditDataPrice({ status: false, data: {} })} />
            }
            {dataModOil.status &&
               <InputOilPop data={dataModOil.data} onPress={() => setDataModOil({ status: false, data: {} })} />
            }
            {dataModPrice.status &&
               <InputGasPop data={dataModPrice.data} onPress={() => setDataModPrice({ status: false, data: {} })} />
            }
         </div>
      </AppLayout>
   )
}