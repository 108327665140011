import react, { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import * as SVGIcon from '../assets/svg'
import userAdminFunction from '../hooks/userAdminFunction';
import { useStoreSelector } from '../store/useStore';
import LogoutModal from './LogoutModal';
const logo: string = require('../assets/icons/logo.svg').default
const exit: string = require('../assets/icons/exit.svg').default
interface sidebarProps {
   activeClass?: string;
   title?: string
}
export default (props: sidebarProps) => {
   let getGuestMode = localStorage.getItem('guest')
   const [showSide, setShowSide] = useState(getGuestMode ? false : true)
   const [logout, setLogout] = useState(false)
   const { getprofile } = userAdminFunction()
   const { userData } = useStoreSelector(["userData"])

   useEffect(() => {
      getprofile()
   }, [])
   let adminName = userData?.fullname
   let userType = userData?.user_type
   let dept = userData?.department
   let inputUrl = "/input"
   if (userType === "data-officer") {
      if (dept === "oil & gas") {
         inputUrl = "/input/gas"
      } else if (dept === "mining") {
         inputUrl = "/input/mining"
      } else if (dept === "commodity pricing") {
         inputUrl = "/input/comodity"
      } else if (dept === "trading") {
         inputUrl = "/input/trading"
      } else if (dept === "human resources") {
         inputUrl = "/input/human"
      }
   }

   let initials = adminName ? adminName.slice(0, 2).toUpperCase() : "AD"
   if (userType === "guest") {
      let element = document.querySelector(".content-body")
      let element2 = document.querySelector(".newHeight1")
      let element3 = document.querySelector(".pbottom301")
      let element4 = document.querySelector(".pbottom302")
      let element5 = document.querySelector(".pbottom303")
      element?.classList.add("mygoLeft")
      element2?.classList.add("newHeight")
      element3?.classList.add("pbottom30")
      element4?.classList.add("pbottom30")
      element5?.classList.add("pbottom30")
   }
   if (!showSide && getGuestMode) {
      let element = document.querySelector(".content-body")
      let element2 = document.querySelector(".newHeight1")
      let element3 = document.querySelector(".pbottom301")
      let element4 = document.querySelector(".pbottom302")
      let element5 = document.querySelector(".pbottom303")
      element?.classList.add("mygoLeft")
      element2?.classList.add("newHeight")
      element3?.classList.add("pbottom30")
      element4?.classList.add("pbottom30")
      element5?.classList.add("pbottom30")
   }
   const setSideBar = () => {
      setShowSide(!showSide)
      let element = document.querySelector(".content-body")
      let element2 = document.querySelector(".newHeight1")
      let element3 = document.querySelector(".pbottom301")
      let element4 = document.querySelector(".pbottom302")
      let element5 = document.querySelector(".pbottom303")
      if (showSide) {
         Navigate('/gas')
         element?.classList.add("mygoLeft")
         element2?.classList.add("newHeight")
         element3?.classList.add("pbottom30")
         element4?.classList.add("pbottom30")
         element5?.classList.add("pbottom30")
      } else {
         element?.classList.remove("mygoLeft")
         element2?.classList.remove("newHeight")
         element3?.classList.remove("pbottom30")
         element4?.classList.remove("pbottom30")
         element5?.classList.remove("pbottom30")
      }
   }
   const Navigate = useNavigate()
   const LogOut = () => {
      localStorage.removeItem('__mtoken')
      localStorage.removeItem('__mauth_id')
      Navigate("/")
   }
   return (
      <div>

         <div>
            <div className="nav-header">
               <NavLink to="/gas" className="brand-logo">
                  <img src={logo} />
               </NavLink>
               {/* {userType !== "guest" ?
                  <div className="nav-control">
                     <div className="hamburger" onClick={(e) => { setSideBar() }}>
                        <span className="line"></span><span className="line"></span><span className="line"></span>
                        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <rect x="22" y="11" width="4" height="4" rx="2" fill="#2A353A" />
                           <rect x="11" width="4" height="4" rx="2" fill="#2A353A" />
                           <rect x="22" width="4" height="4" rx="2" fill="#2A353A" />
                           <rect x="11" y="11" width="4" height="4" rx="2" fill="#2A353A" />
                           <rect x="11" y="22" width="4" height="4" rx="2" fill="#2A353A" />
                           <rect width="4" height="4" rx="2" fill="#2A353A" />
                           <rect y="11" width="4" height="4" rx="2" fill="#2A353A" />
                           <rect x="22" y="22" width="4" height="4" rx="2" fill="#2A353A" />
                           <rect y="22" width="4" height="4" rx="2" fill="#2A353A" />
                        </svg>
                     </div>
                  </div>
                  : ""} */}
            </div>
            <div className="header">
               <div className="header-content">
                  {userType === "guest" ?
                     <nav className="navbar navbar-expand-lg ">
                        <a className="navbar-brand" href="#"></a>
                        <button className="navbar-toggler no-transition" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                           <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse justify-content-between no-transition" id="navbarNav">
                           <ul className="navbar-nav" style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                              <li className={`${props.activeClass === 'gas' ? "activate mm-active nav-item mm-left5" : "nav-item mm-left5"}`}>
                                 <NavLink className="nav-link" to="/gas">Oil & Gas</NavLink>
                              </li>
                              <li className={`${props.activeClass === 'mining' ? "activate mm-active nav-item mm-left5" : "nav-item mm-left5"}`}>
                                 <NavLink className="nav-link" to="/mining">Mining</NavLink>
                              </li>
                              <li className={`${props.activeClass === 'comodity' ? "activate mm-active nav-item mm-left5" : "nav-item mm-left5"}`}>
                                 <NavLink className="nav-link" to="/comodity">Commodity Prices</NavLink>
                              </li>
                              <li className={`${props.activeClass === 'trade' ? "activate mm-active nav-item mm-left5" : "nav-item mm-left5"}`}>
                                 <NavLink className="nav-link" to="/trade">Trade</NavLink>
                              </li>
                              <li className={`${props.activeClass === 'human' ? "activate mm-active nav-item mm-left5" : "nav-item mm-left5"}`} style={{ marginRight: "0" }}>
                                 <NavLink className="nav-link" to="/human">Human Resources</NavLink>
                              </li>
                           </ul>
                           <div className='goRight showCursor' onClick={() => setLogout(true)}>
                              <div className="d-flex aligna">
                                 <div className="menu-icon">
                                    <img src={exit} />
                                 </div>
                                 <span className="nav-text ml-2"> Exit</span>
                              </div>
                           </div>
                        </div>
                     </nav>
                     : ""}
                  {userType !== "guest" && !showSide ?
                     <nav className="navbar navbar-expand-lg ">
                        <a className="navbar-brand" href="#"></a>
                        <button className="navbar-toggler no-transition" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                           <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse justify-content-between no-transition" id="navbarNav">
                           <ul className="navbar-nav" style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                              <li className={`${props.activeClass === 'gas' ? "activate mm-active nav-item mm-left5" : "nav-item mm-left5"}`}>
                                 <NavLink className="nav-link" to="/gas">Oil & Gas</NavLink>
                              </li>
                              <li className={`${props.activeClass === 'mining' ? "activate mm-active nav-item mm-left5" : "nav-item mm-left5"}`}>
                                 <NavLink className="nav-link" to="/mining">Mining</NavLink>
                              </li>
                              <li className={`${props.activeClass === 'comodity' ? "activate mm-active nav-item mm-left5" : "nav-item mm-left5"}`}>
                                 <NavLink className="nav-link" to="/comodity">Commodity Prices</NavLink>
                              </li>
                              <li className={`${props.activeClass === 'trade' ? "activate mm-active nav-item mm-left5" : "nav-item mm-left5"}`}>
                                 <NavLink className="nav-link" to="/trade">Trade</NavLink>
                              </li>
                              <li className={`${props.activeClass === 'human' ? "activate mm-active nav-item mm-left5" : "nav-item mm-left5"}`} style={{ marginRight: "0" }}>
                                 <NavLink className="nav-link" to="/human">Human Resources</NavLink>
                              </li>
                           </ul>
                           <div className='goRight showCursor' onClick={(e) => {
                              setSideBar()
                              localStorage.removeItem('guest')
                           }}>
                              <div className="d-flex aligna">
                                 <div className="menu-icon">
                                    <img src={exit} />
                                 </div>
                                 <span className="nav-text ml-2"> Exit</span>
                              </div>
                           </div>
                        </div>
                     </nav>
                     : ""}
                  {userType !== "guest" && showSide ?
                     <nav className="navbar navbar-expand" >
                        <div className="collapse navbar-collapse justify-content-between">

                           <div className="header-left">
                              <div className="header-title d-flex">
                                 <h3 className="mb-0 colorMog2">Data Centre</h3>
                                 <h3 className="mb-0 colorMog" style={{ borderLeft: "2px solid #C7C7C7", marginLeft: "10px", paddingLeft: "10px", }}>{props.title ? props.title : ""}</h3>
                              </div>
                           </div>
                           {/* {!showSide ?
                              <div className='goRight showCursor' onClick={(e) => { setSideBar() }}>
                                 <div className="d-flex aligna">
                                    <div className="menu-icon">
                                       <img src={exit} />
                                    </div>
                                    <span className="nav-text ml-2"> Exit</span>
                                 </div>
                              </div> : */}
                           <ul className="navbar-nav header-right">
                              <li className="nav-item">
                                 <div className="dropdown header-profile2">
                                    <a className="nav-link" href="javascript:void(0);" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                       <div className="header-info2 d-flex align-items-center">
                                          <div className="d-flex align-items-center sidebar-info">
                                             <div style={{ background: "#E8E8E8", borderRadius: "20px", padding: "5px" }}>
                                                <div className='d-flex'>
                                                   <h4 data-letters={initials} className="mb-0" style={{ marginRight: "10px" }}>{adminName}</h4>
                                                   {/* <i style={{ marginTop: "5px" }} className="fa-solid fa-angle-down"></i> */}
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </a>
                                 </div>
                              </li>
                           </ul>
                           {/* } */}
                        </div>
                     </nav>
                     : ""}
               </div>
            </div>
         </div>



         {showSide && userType !== "guest" &&
            <div className="deznav">
               <div className="deznav-scroll">
                  <ul className="metismenu" id="menu">
                     <li> <a><span className="nav-text" style={{ color: "#666666" }}>Sectors</span></a></li>
                     <li className={`${props.activeClass === 'gas' ? "active mm-active" : ""}`}><NavLink className="" to="/gas" aria-expanded="false">
                        <div className="menu-icon">
                           <SVGIcon.DashboardOil />
                        </div>
                        <span className="nav-text">Oil & Gas</span>
                     </NavLink>
                     </li>
                     <li className={`${props.activeClass === 'mining' ? "active mm-active" : ""}`}><NavLink className="" to="/mining" aria-expanded="false">
                        <div className="menu-icon">
                           <SVGIcon.DashboardMining />
                        </div>
                        <span className="nav-text">Mining</span>
                     </NavLink>
                     </li>
                     <li className={`${props.activeClass === 'comodity' ? "active mm-active" : ""}`}><NavLink className="" to="/comodity" aria-expanded="false">
                        <div className="menu-icon">
                           <SVGIcon.DashboardComodity />
                        </div>
                        <span className="nav-text">Commodity Prices</span>
                     </NavLink>
                     </li>
                     <li className={`${props.activeClass === 'trade' ? "active mm-active" : ""}`}><NavLink className="" to="/trade" aria-expanded="false">
                        <div className="menu-icon">
                           <SVGIcon.DashboardTrade />
                        </div>
                        <span className="nav-text">Trading</span>
                     </NavLink>
                     </li>
                     <li className={`${props.activeClass === 'human' ? "active mm-active" : ""}`}><NavLink className="" to="/human" aria-expanded="false">
                        <div className="menu-icon">
                           <SVGIcon.DashboardHuman />
                        </div>
                        <span className="nav-text">Human Resources</span>
                     </NavLink>
                     </li>
                  </ul>
                  <ul className="metismenu" id="menu" style={{ marginTop: "-20px" }}>
                     <li> <a><span className="nav-text" style={{ color: "#666666" }}>Quick Actions</span></a></li>
                     <li className={`${props.activeClass === 'input' ? "active mm-active" : ""}`}><NavLink className="" to={inputUrl} aria-expanded="false">
                        <div className="menu-icon">
                           <SVGIcon.DashboardInput />
                        </div>
                        <span className="nav-text">Daily Input</span>
                     </NavLink>
                     </li>
                     {userType === "superadmin" ?
                        <li className={`${props.activeClass === 'invite' ? "active mm-active" : ""}`}><NavLink className="" to="/invite" aria-expanded="false">
                           <div className="menu-icon">
                              <SVGIcon.DashboardInvite />
                           </div>
                           <span className="nav-text">Invite</span>
                        </NavLink>
                        </li>
                        : ""}
                     <li className={`${props.activeClass === 'account' ? "active mm-active" : ""}`}><NavLink className="" to="/account" aria-expanded="false">
                        <div className="menu-icon">
                           <SVGIcon.DashboardAccount />
                        </div>
                        <span className="nav-text">Account</span>
                     </NavLink>
                     </li>
                  </ul>
                  <ul className="metismenu" id="menu" style={{ marginTop: "-20px" }}>
                     <li><a className="" href="javascript:void(0);" onClick={(e) => {
                        setSideBar()
                        localStorage.setItem("guest", "yes");
                     }} aria-expanded="false">
                        <div className="menu-icon">
                           <SVGIcon.DashboardGuest />
                        </div>
                        <span className="nav-text">Guest view</span>
                     </a>
                     </li>
                     <li onClick={() => setLogout(true)}><a className="" href="javascript:void(0);" aria-expanded="false">
                        <div className="menu-icon">
                           <SVGIcon.DashboardLogout />
                        </div>
                        <span className="nav-text">Log out</span>
                     </a>
                     </li>
                  </ul>
               </div>
            </div>
         }
         {logout &&
            <LogoutModal onPress={() => setLogout(false)} />
         }
      </div>
   )
}