import React, { useEffect, useState } from 'react'
import { Properties as CSSProperties } from 'csstype'
import { ObjectPayload } from '../typings/general';
import hrFunction from '../hooks/hrFunction';
import { useStoreSelector } from '../store/useStore';

export type DataState = {
   data?: ObjectPayload;
   onPress?: Function;
   containerStyle?: CSSProperties;
}
export default (props: DataState) => {
   let newData = props?.data
   const [nData, setNData] = useState({ kra: '', performance: '' })
   const { getCompany, createEntry, updateEntry } = hrFunction()
   const { humanData } = useStoreSelector(["humanData"])
   useEffect(() => {
      getCompany()
   }, [])
   return (
      <div style={{
         position: 'fixed', left: 0, top: 0,
         display: 'flex',
         justifyContent: 'center',
         alignItems: 'center',
         backgroundColor: 'rgba(0, 0, 0, 0.3)',
         backgroundSize: "cover",
         width: '100%',
         height: '100vh',
         zIndex: 999,
      }}>
         <div style={{ borderRadius: '20px', padding: '20px', width: '600px', ...props.containerStyle }}>
            <div className='bg-white' style={{ borderRadius: "20px" }}>
               <div className="filterForm w-100">
                  <div className='popupClass1'>
                     <div className="modal-content">
                        <div className="modal-body" style={{ marginTop: "0" }}>
                           <div className="mb-3 d-block">
                              <div className="d-flex align-items-center justify-content-between flex-wrap">
                                 <div>
                                    <h3 className="modal-title fs-5 inviteColor goFonte" id="exampleModalLabel">Human Resources Data</h3>
                                    <span>Input relevant data for monitoring KRA Performance</span>
                                 </div>
                                 <i onClick={() => props.onPress && props.onPress()} style={{ fontSize: '20px', marginRight: '-5px' }} className="fa fa-times m-color-red showCursor" aria-hidden="true"></i>
                              </div>
                           </div>
                           <div className="mb-3 d-block">
                              <label className="form-label d-block mb-2">Company</label>
                              <input type="text" defaultValue={newData?.company_data?.name} className="form-control w-100 mb-0" />
                           </div>
                           <div className="row">
                              <div className="col-lg-6 col-6">
                                 <div className="mb-3 d-block">
                                    <label className="form-label d-block mb-2">Select Quater</label>
                                    <input type="text" defaultValue={newData?.quater} className="form-control w-100 mb-0" />
                                 </div>
                              </div>
                              <div className="col-lg-6 col-6">
                                 <div className="mb-3 d-block">
                                    <label className="form-label d-block mb-2">Select KRA</label>
                                    <select className="form-control w-100 mb-0 selectH pselect4" aria-label="Default select example" onChange={(e) => setNData(edata => ({ ...edata, kra: e.target.value.trim() }))}>
                                       <option value="">Select</option>
                                       <option value="KRA 1">KRA 1</option>
                                       <option value="KRA 2">KRA 2</option>
                                       <option value="KRA 3">KRA 3</option>
                                       <option value="KRA 4">KRA 4</option>
                                    </select>
                                 </div>
                              </div>
                           </div>
                           <div className="row">
                              <div className="col-lg-6 col-6" style={{ paddingLeft: "0px" }}>
                                 <div className="form-check custom-checkbox mb-2 checkbox-primary">
                                    <label className="form-check-label">Current performance?</label>
                                 </div>
                              </div>
                              <div className="col-lg-3 col-3">
                                 <div className="form-check custom-checkbox mb-2 checkbox-primary">
                                    <input type="radio" className="form-check-input" id="customRadioBox1" name="optradioCustom" onChange={(e) => setNData(edata => ({ ...edata, performance: "2" }))} />
                                    <label className="form-check-label">On Course</label>
                                 </div>
                              </div>
                              <div className="col-lg-3 col-3" style={{ paddingRight: "0px" }}>
                                 <div className="form-check custom-checkbox mb-2 checkbox-primary">
                                    <input type="radio" className="form-check-input" id="customRadioBox2" name="optradioCustom" onChange={(e) => setNData(edata => ({ ...edata, performance: "1" }))} />
                                    <label className="form-check-label">Behind</label>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="modal-footer">
                           <button type="button" className="btn btn-lg light primaryBG2 ww-50" onClick={() => props.onPress && props.onPress()}>Close</button>
                           <button type="button" className="btn btn-lg primaryBG ww-50" onClick={() => updateEntry(nData, newData?.entry_id, props.onPress)}>Submit</button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

      </div>
   )
}