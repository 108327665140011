import { ObjectPayload } from "../../typings/general";
import { StoreStateCreator } from "../useStore";

export interface HumanSliceState {
   humanData?: ObjectPayload;
   setHumanData?: (value: ObjectPayload) => void;

   humanCount?: ObjectPayload;
   setHumanCount?: (value: ObjectPayload) => void;

   humanEntryData?: ObjectPayload;
   setHumanEntryData?: (value: ObjectPayload) => void;

   humanEntryCount?: ObjectPayload;
   setHumanEntryCount?: (value: ObjectPayload) => void;

   humanEntryAllData?: ObjectPayload;
   setHumanEntryAllData?: (value: ObjectPayload) => void;

   humanEntryAllCount?: ObjectPayload;
   setHumanEntryAllCount?: (value: ObjectPayload) => void;
}

export const humanStoreSlice: StoreStateCreator<HumanSliceState> = (
   set,
   get
) => ({
   humanData: [],
   setHumanData: (value: ObjectPayload) => set({ humanData: value }),

   humanCount: {},
   setHumanCount: (value: ObjectPayload) => set({ humanCount: value }),

   humanEntryData: [],
   setHumanEntryData: (value: ObjectPayload) => set({ humanEntryData: value }),

   humanEntryCount: {},
   setHumanEntryCount: (value: ObjectPayload) => set({ humanEntryCount: value }),

   humanEntryAllData: [],
   setHumanEntryAllData: (value: ObjectPayload) => set({ humanEntryAllData: value }),
});
