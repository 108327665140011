import { Routes, RouteObject } from "react-router-dom"
import LoginIndex from "../pages/login/LoginIndex"
import LoginNew from "../pages/login/NewLogin"


export const LoginRoute: RouteObject = {
   path: "/",
   children: [
      {
         path: "/",
         element: <LoginIndex />
      },
      {
         path: "/login/:id",
         element: <LoginNew />
      },
   ]
}