import React, { useEffect, useState } from 'react'
import { Properties as CSSProperties } from 'csstype'
import { ObjectPayload } from '../typings/general';
import commodityFunction from '../hooks/commodityFunction';
import { useStoreSelector } from '../store/useStore';
import helpers from '../utils/helpers';

export type DataState = {
   data?: ObjectPayload;
   onPress?: Function;
   containerStyle?: CSSProperties;
}
export default (props: DataState) => {
   let nData = props?.data
   const [comState, setComState] = useState({ data: { amount: nData?.amount, location_id: nData?.location_id, commodity_id: nData?.commodity_id, weight_type: nData?.weight_type } })
   const { getLocation, getCommodity, updateMining } = commodityFunction()
   const { locationData, commodityData } = useStoreSelector(["locationData", "commodityData"])
   useEffect(() => {
      getLocation()
      getCommodity()
   }, [])
   return (
      <div style={{
         position: 'fixed', left: 0, top: 0,
         display: 'flex',
         justifyContent: 'center',
         alignItems: 'center',
         backgroundColor: 'rgba(0, 0, 0, 0.3)',
         backgroundSize: "cover",
         width: '100%',
         height: '100vh',
         zIndex: 999,
      }}>
         <div style={{ borderRadius: '20px', padding: '20px', width: '600px', ...props.containerStyle }}>
            <div className='bg-white' style={{ borderRadius: "20px" }}>
               <div className="filterForm w-100">
                  <div className='popupClass1'>
                     <div className="modal-content">
                        <div className="modal-body" style={{ marginTop: "0" }}>
                           <div className="mb-3 d-block">
                              <div className="d-flex align-items-center justify-content-between flex-wrap">
                                 <h3 className="modal-title fs-5 inviteColor" id="exampleModalLabel">Daily Mining Entry price</h3>
                                 <i onClick={() => props.onPress && props.onPress()} style={{ fontSize: '20px', marginRight: '-5px' }} className="fa fa-times m-color-red showCursor" aria-hidden="true"></i>
                              </div>
                           </div>
                           <div className="mb-3 d-block">
                              <label className="form-label d-block mb-2">Element Type</label>
                              <input type="text" className="form-control w-100 mb-0" value={nData?.commodity_data?.name} />
                           </div>
                           <div className="mb-3 d-block">
                              <label className="form-label d-block mb-2">Amount</label>
                              <input type="text" className="form-control w-100 mb-0" defaultValue={helpers.formatMoney(nData?.amount)} onChange={(e) => {
                                 setComState(state => ({ ...state, data: { ...state.data, amount: e.target.value } }))
                                 e.target.value = helpers.formatMoney(e.target.value.replace(/\,/g, '')) as any
                              }} />
                           </div>
                           <div className="mb-3 d-block">
                              <label className="form-label d-block mb-2">Weight Type</label>
                              <select className="form-control w-100 mb-0 selectH pselect4" aria-label="Default select example" defaultValue={nData?.weight_type} onChange={(e) => {
                                 setComState(state => ({ ...state, data: { ...state.data, weight_type: e.target.value } }))
                              }}>
                                 <option value="">Select</option>
                                 <option selected={nData?.weight_type === "g"} value="g">Gram</option>
                                 <option selected={nData?.weight_type === "kg"} value="kg">Kilogram</option>
                                 <option selected={nData?.weight_type === "Barrel"} value="Barrel">Barrel</option>
                                 <option selected={nData?.weight_type === "mt"} value="ltr">Metric-Ton</option>
                              </select>
                           </div>
                           <div className="mb-3 d-block">
                              <label className="form-label d-block mb-2">Location</label>
                              <select className="form-control w-100 mb-0 selectH pselect4" defaultValue={nData?.location_id} aria-label="Default select example" onChange={(e) => {
                                 setComState(state => ({ ...state, data: { ...state.data, location_id: e.target.value } }))
                              }}>
                                 <option value="">Select</option>
                                 {locationData ?
                                    (locationData || []).map((item: any, i: any) => (
                                       <option selected={nData?.location_id === item.location_id} value={item.location_id}>{item.name}</option>
                                    )) : "Loading"}
                              </select>
                           </div>
                        </div>
                        <div className="modal-footer">
                           <button type="button" className="btn btn-lg light primaryBG2 ww-50" onClick={() => props.onPress && props.onPress()}>Close</button>
                           <button type="button" className="btn btn-lg primaryBG ww-50" onClick={() => updateMining(comState.data, nData?.entry_id, props.onPress)}>Submit</button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

      </div>
   )
}