import React from "react";
import Loader from "./Loader";
import EmptyTable from "./EmptyTable";

interface AppTableProps {
   tableTitle?: string;
   headers?: string[];
   data?: Array<any>;
   isLoading?: boolean;
   children: React.ReactNode;
   refData?: any
}

export default (props: AppTableProps) => {
   return (

      <div>
         {(!props.data || props.data?.length === 0) ? props.isLoading ? null : <EmptyTable /> :
            <div className="card nobbottom">
               <div className="card-body pAll">
                  <div className="table-responsive tableRad">
                     <table className="table table-bordered bgBrand3" ref={props?.refData}>
                        <thead>
                           {props.headers && props.headers.map((item, i) => <th key={i}>{item}</th>)}
                        </thead>
                        <tbody>
                           {props.children}
                        </tbody>
                     </table>
                  </div>
               </div>
            </div>
         }

      </div>
   )
}