import React, { useState } from 'react'
import { Properties as CSSProperties } from 'csstype'
import { ObjectPayload } from '../typings/general';
import helpers from '../utils/helpers';
import tradeFunction from '../hooks/tradeFunction';

export type DataState = {
   data?: ObjectPayload;
   onPress?: Function;
   containerStyle?: CSSProperties;
}
export default (props: DataState) => {
   const [nData, setNData] = useState({ deal: '', achat_qty: "", nxp_no: "", app_no: "", inspect_date: "", container_no: "", veritek_gross_weight: "", veritek_dry_net: "", first_payment: "", second_payment: "", rci_sn: "", ship_abroad: "", obl_sent: "", estimated_delivery: "", ahk_dry: "", ahk_sample_date: "", asi_final: "", last_payment: "0" })
   const { createTradeEntry } = tradeFunction()
   const [stepOne, setStepOne] = useState(true)
   const [stepTwo, setStepTwo] = useState(false)
   const [stepThree, setStepThree] = useState(false)
   const checkLastPayment = () => {
      let checkedValue = document.querySelector('.messageCheckbox:checked');
      if (checkedValue) {
         setNData(edata => ({ ...edata, last_payment: "1" }))
      } else {
         setNData(edata => ({ ...edata, last_payment: "0" }))
      }
   }
   return (
      <div style={{
         position: 'fixed', left: 0, top: 0,
         display: 'flex',
         justifyContent: 'center',
         alignItems: 'center',
         backgroundColor: 'rgba(0, 0, 0, 0.3)',
         backgroundSize: "cover",
         width: '100%',
         height: '100vh',
         paddingBottom: "20px",
         zIndex: 999,
      }}>
         <div style={{ borderRadius: '20px', padding: '20px', width: '700px', ...props.containerStyle }}>
            <div className='bg-white' style={{ borderRadius: "20px" }}>
               <div className="filterForm w-100">
                  <div className='popupClass1'>
                     <div className="modal-content">
                        <div className="mb-2 d-block" style={{ paddingTop: "20px", paddingLeft: "30px" }}>
                           <div className="d-flex align-items-center justify-content-between flex-wrap">
                              <div>
                                 <h3 className="modal-title fs-5 inviteColor goFonte">Trading Data</h3>
                              </div>
                           </div>
                        </div>
                        <div className="card-header flex-wrap border-0">
                           <span>Trading stage</span>
                           <ul className="nav nav-tabs dzm-tabs" id="myTab" role="tablist" style={{ backgroundColor: "none", width: "100%" }}>
                              <li className={`nav-item oilLi showCursor ${stepOne ? "active2" : ""}`} role="presentation" onClick={() => {
                                 setStepOne(true)
                                 setStepTwo(false)
                                 setStepThree(false)
                              }}>
                                 <a className="nav-link oilBtn" id="home-tab">Step 1</a>
                              </li>
                              <li className={`nav-item oilLi showCursor ${stepTwo ? "active2" : ""}`} role="presentation" onClick={() => {
                                 setStepOne(false)
                                 setStepTwo(true)
                                 setStepThree(false)
                              }}>
                                 <a className="nav-link oilBtn" id="profile-tab">Step 2</a>
                              </li>
                              <li className={`nav-item oilLi showCursor ${stepThree ? "active2" : ""}`} role="presentation" onClick={() => {
                                 setStepOne(false)
                                 setStepTwo(false)
                                 setStepThree(true)
                              }}>
                                 <a className="nav-link oilBtn" id="profile-tab">Step 3</a>
                              </li>

                           </ul>
                        </div>
                        {/* vessle entering */}
                        <div className="tab-content" id="myTabContent">
                           {stepOne ?
                              <div className="">
                                 <div className="modal-body" style={{ marginTop: "0", paddingBottom: "0", paddingTop: "0" }}>
                                    <div className="row">
                                       <div className="col-lg-4 col-6">
                                          <div className="mb-3 d-block">
                                             <label className="form-label d-block mb-1">Deals</label>
                                             <input type="text" defaultValue={nData.deal} className="form-control w-100 mb-0" placeholder='eg Placeholder' onChange={(e) => setNData(edata => ({ ...edata, deal: e.target.value.trim() }))} />
                                          </div>
                                       </div>
                                       <div className="col-lg-4 col-6">
                                          <div className="mb-3 d-block">
                                             <label className="form-label d-block mb-1">ACHAT QTY JOS</label>
                                             <input type="tel" defaultValue={nData.achat_qty} className="form-control w-100 mb-0" placeholder='eg Placeholder' onChange={
                                                (e) => {
                                                   setNData(edata => ({ ...edata, achat_qty: e.target.value.trim() }))
                                                   e.target.value = helpers.formatMoney(e.target.value.replace(/\,/g, '')) as any
                                                }} />
                                          </div>
                                       </div>
                                       <div className="col-lg-4 col-6">
                                          <div className="mb-3 d-block">
                                             <label className="form-label d-block mb-1">NXP No.</label>
                                             <input type="text" defaultValue={nData.nxp_no} className="form-control w-100 mb-0" placeholder='eg Placeholder' onChange={(e) => setNData(edata => ({ ...edata, nxp_no: e.target.value.trim() }))} />
                                          </div>
                                       </div>
                                       <div className="col-lg-4 col-6">
                                          <div className="mb-3 d-block">
                                             <label className="form-label d-block mb-1">APP No.</label>
                                             <input type="text" defaultValue={nData.app_no} className="form-control w-100 mb-0" placeholder='eg Placeholder' onChange={(e) => setNData(edata => ({ ...edata, app_no: e.target.value.trim() }))} />
                                          </div>
                                       </div>
                                       <div className="col-lg-4 col-6">
                                          <div className="mb-3 d-block">
                                             <label className="form-label d-block mb-1">Insp. date VERITEK</label>
                                             <input type="date" defaultValue={nData.inspect_date} className="form-control w-100 mb-0" placeholder='eg Placeholder' onChange={(e) => setNData(edata => ({ ...edata, inspect_date: e.target.value.trim() }))} />
                                          </div>
                                       </div>
                                       <div className="col-lg-4 col-6">
                                          <div className="mb-3 d-block">
                                             <label className="form-label d-block mb-1">Container No.</label>
                                             <input type="text" defaultValue={nData.container_no} className="form-control w-100 mb-0" placeholder='eg Placeholder' onChange={(e) => setNData(edata => ({ ...edata, container_no: e.target.value.trim() }))} />
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="modal-footer">
                                    <button type="button" className="btn btn-lg light primaryBG2 ww-50" onClick={() => props.onPress && props.onPress()}>Close</button>
                                    <button type="button" className="btn btn-lg primaryBG ww-50" onClick={() => {
                                       setStepOne(false)
                                       setStepTwo(true)
                                       setStepThree(false)
                                    }}>Next</button>
                                 </div>
                              </div> : ""}
                           {/* Price entering */}
                           {stepTwo ?
                              <div className="">
                                 <div className="modal-body" style={{ marginTop: "0", paddingBottom: "0", paddingTop: "0" }}>
                                    <div className="row">
                                       <div className="col-lg-4 col-6">
                                          <div className="mb-3 d-block">
                                             <label className="form-label d-block mb-1">VERITEK Gross weight</label>
                                             <input type="tel" defaultValue={nData.veritek_gross_weight} className="form-control w-100 mb-0" placeholder='eg Placeholder' onChange={(e) => {
                                                setNData(edata => ({ ...edata, veritek_gross_weight: e.target.value.trim() }))
                                                e.target.value = helpers.formatMoney(e.target.value.replace(/\,/g, '')) as any
                                             }} />
                                          </div>
                                       </div>
                                       <div className="col-lg-4 col-6">
                                          <div className="mb-3 d-block">
                                             <label className="form-label d-block mb-1">VERITEK dry net weight</label>
                                             <input type="tel" defaultValue={nData.veritek_dry_net} className="form-control w-100 mb-0" placeholder='eg Placeholder' onChange={(e) => {
                                                setNData(edata => ({ ...edata, veritek_dry_net: e.target.value.trim() }))
                                                e.target.value = helpers.formatMoney(e.target.value.replace(/\,/g, '')) as any
                                             }} />
                                          </div>
                                       </div>
                                       <div className="col-lg-4 col-6">
                                          <div className="mb-3 d-block">
                                             <label className="form-label d-block mb-1">1st 80% payment</label>
                                             <input type="text" defaultValue={nData.first_payment} className="form-control w-100 mb-0" placeholder='eg Placeholder' onChange={(e) => setNData(edata => ({ ...edata, first_payment: e.target.value.trim() }))} />
                                          </div>
                                       </div>
                                       <div className="col-lg-4 col-6">
                                          <div className="mb-3 d-block">
                                             <label className="form-label d-block mb-1">RCI SN Content</label>
                                             <input type="text" defaultValue={nData.rci_sn} className="form-control w-100 mb-0" placeholder='eg Placeholder' onChange={(e) => setNData(edata => ({ ...edata, rci_sn: e.target.value.trim() }))} />
                                          </div>
                                       </div>
                                       <div className="col-lg-4 col-6">
                                          <div className="mb-3 d-block">
                                             <label className="form-label d-block mb-1">Shipped on board</label>
                                             <input type="date" defaultValue={nData.ship_abroad} className="form-control w-100 mb-0" placeholder='eg Placeholder' onChange={(e) => setNData(edata => ({ ...edata, ship_abroad: e.target.value.trim() }))} />
                                          </div>
                                       </div>
                                       <div className="col-lg-4 col-6">
                                          <div className="mb-3 d-block">
                                             <label className="form-label d-block mb-1">OBL SENT</label>
                                             <input type="text" defaultValue={nData.obl_sent} className="form-control w-100 mb-0" placeholder='eg Placeholder' onChange={(e) => setNData(edata => ({ ...edata, obl_sent: e.target.value.trim() }))} />
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="modal-footer">
                                    <button type="button" className="btn btn-lg light primaryBG2 ww-50" onClick={() => props.onPress && props.onPress()}>Close</button>
                                    <button type="button" className="btn btn-lg primaryBG ww-50" onClick={() => {
                                       setStepOne(false)
                                       setStepTwo(false)
                                       setStepThree(true)
                                    }}>Next</button>
                                 </div>
                              </div> : ""}
                           {stepThree ?
                              <div className="">
                                 <div className="modal-body" style={{ marginTop: "0", paddingBottom: "0", paddingTop: "0" }}>
                                    <div className="row">
                                       <div className="col-lg-4 col-6">
                                          <div className="mb-3 d-block">
                                             <label className="form-label d-block mb-1">2nd 95% Payment</label>
                                             <input type="text" defaultValue={nData.second_payment} className="form-control w-100 mb-0" placeholder='eg Placeholder' onChange={(e) => setNData(edata => ({ ...edata, second_payment: e.target.value.trim() }))} />
                                          </div>
                                       </div>
                                       <div className="col-lg-4 col-6">
                                          <div className="mb-3 d-block">
                                             <label className="form-label d-block mb-1">Estimated Delivery</label>
                                             <input type="date" defaultValue={nData.estimated_delivery} className="form-control w-100 mb-0" placeholder='eg Placeholder' onChange={(e) => setNData(edata => ({ ...edata, estimated_delivery: e.target.value.trim() }))} />
                                          </div>
                                       </div>
                                       <div className="col-lg-4 col-6">
                                          <div className="mb-3 d-block">
                                             <label className="form-label d-block mb-1">AHK Dry net weight</label>
                                             <input type="tel" defaultValue={nData.ahk_dry} className="form-control w-100 mb-0" placeholder='eg Placeholder' onChange={(e) => {
                                                setNData(edata => ({ ...edata, ahk_dry: e.target.value.trim() }))
                                                e.target.value = helpers.formatMoney(e.target.value.replace(/\,/g, '')) as any
                                             }} />
                                          </div>
                                       </div>
                                       <div className="col-lg-4 col-6">
                                          <div className="mb-3 d-block">
                                             <label className="form-label d-block mb-1">AHK Sample arrival date</label>
                                             <input type="date" defaultValue={nData.ahk_sample_date} className="form-control w-100 mb-0" placeholder='eg Placeholder' onChange={(e) => setNData(edata => ({ ...edata, ahk_sample_date: e.target.value.trim() }))} />
                                          </div>
                                       </div>
                                       <div className="col-lg-4 col-6">
                                          <div className="mb-3 d-block">
                                             <label className="form-label d-block mb-1">ASI Final result</label>
                                             <input type="text" defaultValue={nData.asi_final} className="form-control w-100 mb-0" placeholder='eg Placeholder' onChange={(e) => setNData(edata => ({ ...edata, asi_final: e.target.value.trim() }))} />
                                          </div>
                                       </div>
                                       <div className="col-8">
                                          <div className="mb-2 d-block">
                                             <div className="form-check custom-checkbox mb-3">
                                                <input type="checkbox" className="form-check-input messageCheckbox" id="customCheckBox1" onChange={(e) => { checkLastPayment() }} />
                                                <label className="form-check-label">Check box once last payment is complete</label>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="modal-footer">
                                    <button type="button" className="btn btn-lg light primaryBG2 ww-50" onClick={() => props.onPress && props.onPress()}>Close</button>
                                    <button type="button" className="btn btn-lg primaryBG ww-50" onClick={(e) => {
                                       e.preventDefault()
                                       createTradeEntry(nData, props.onPress)
                                    }}>Submit</button>
                                 </div>
                              </div> : ""}
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

      </div>
   )
}