import { useEffect, useState } from "react"
import AppLayout from "../../components/AppLayout"
import CurrentTank from "../../components/CurrentTank"
import MtVirgo from "../../components/MtVirgo"
import MonthlyRevenue from "../../components/MonthlyRevenue"
import SalesRevenue from "../../components/SalesRevenue"
import { useStoreSelector } from "../../store/useStore"
import oilFunction from "../../hooks/oilFunction"

export default () => {
   const { getOilTankDay, getOilMTEntry } = oilFunction()
   const { gasTankChart, isLoading, singleMTData } = useStoreSelector(["gasTankChart", "isLoading", "singleMTData"])
   useEffect(() => {
      getOilTankDay()
      getOilMTEntry()
   }, [])

   return (
      <AppLayout active="gas" title="Oil & Gas">
         <div className="container-fluid">
            <div className="row">
               <div className="col-xl-4 col-lg-6 col-md-6 col-12">
                  <CurrentTank data={gasTankChart} />
               </div>
               <div className="col-xl-4 col-lg-6 col-md-6 col-12">
                  <MtVirgo data={singleMTData} />
               </div>
               <div className="col-xl-4 col-lg-6 col-md-6 col-12">
                  <MonthlyRevenue />
               </div>
               <div className="col-12">
                  <SalesRevenue />
               </div>
            </div>
         </div>
      </AppLayout>
   )
}