import React, { useState } from 'react'
import { Properties as CSSProperties } from 'csstype'
import { ObjectPayload } from '../typings/general';
import helpers from '../utils/helpers';
import oilFunction from '../hooks/oilFunction';
const editBtn: string = require('../assets/icons/date.svg').default

export type DataState = {
   data?: ObjectPayload;
   onPress?: Function;
   containerStyle?: CSSProperties;
}
export default (props: DataState) => {
   let newData = props?.data
   const { updateOilEntry } = oilFunction()
   const todayDate = () => {
      let now = new Date();
      let month: any = (now.getMonth() + 1);
      let day: any = now.getDate();
      if (month < 10)
         month = "0" + month;
      if (day < 10)
         day = "0" + day;
      return now.getFullYear() + '-' + month + '-' + day;
   }
   let oldDate = todayDate()
   let dbDate = newData?.date ? newData?.date.split('T')[0] : oldDate
   const [nData, setNData] = useState({
      title: newData?.title,
      throughput: newData?.through_put,
      ndate: dbDate,
      pre_sold: newData?.presold_volume, total_pms: newData?.type === "pms" && newData?.total_volume, total_ago: newData?.type === "ago" && newData?.total_volume, total_dpk: newData?.type === "dpk" && newData?.total_volume, dis_pms: "", dis_ago: "", dis_dpk: "", lost: newData?.stock_lost, vessel_output: newData?.vessel_output
   })
   let mPMS = newData?.type === "pms" ? newData?.discharge_volume : 0
   let mAGO = newData?.type === "ago" ? newData?.discharge_volume : 0
   let mDPK = newData?.type === "dpk" ? newData?.discharge_volume : 0

   const [oPrice, setoPrice] = useState({ dpk: 0, pms: 0, ago: 0 })
   const getTotal = (ab: any, bc: any, type: string) => {
      if (ab && bc) {
         let nab = parseFloat(ab.replace(/\,/g, ''))
         let nab1 = parseFloat(bc.replace(/\,/g, ''))
         let newAb = nab === 0 ? 1 : nab
         let newBc = nab1 === 0 ? 1 : nab1
         let dc = newAb * newBc
         if (type === "dpk") {
            setoPrice((e) => ({ ...e, dpk: dc }))
         }
         if (type === "pms") {
            setoPrice((e) => ({ ...e, pms: dc }))
         }
         if (type === "ago") {
            setoPrice((e) => ({ ...e, ago: dc }))
         }
      } else {
         return 0.00
      }
   }

   return (
      <div style={{
         position: 'fixed', left: 0, top: 0,
         display: 'flex',
         justifyContent: 'center',
         alignItems: 'center',
         backgroundColor: 'rgba(0, 0, 0, 0.3)',
         backgroundSize: "cover",
         width: '100%',
         height: '100vh',
         paddingBottom: "20px",
         zIndex: 999,
      }}>
         <div style={{ borderRadius: '20px', padding: '20px', width: '600px', ...props.containerStyle }}>
            <div className='bg-white' style={{ borderRadius: "20px" }}>
               <div className="filterForm w-100">
                  <div className='popupClass1'>
                     <div className="modal-content">
                        <div className="tab-content" id="myTabContent">
                           <div className="">
                              <div className="modal-body modalBody" style={{ marginTop: "0", paddingBottom: "0", paddingTop: "0" }}>
                                 <div className="mb-2 d-block" style={{ marginTop: "-10px" }}>
                                    <div className="row mb-2">
                                       <div className='col-8'></div>
                                       <div className='col-4'>
                                          <div className='container onContainer'>
                                             <div className='d-flex showCursor'>
                                                <input type="date" defaultValue={nData.ndate} className="form-control w-100 mb-0 npinput" placeholder='eg Placeholder' onChange={(e) => setNData(edata => ({ ...edata, ndate: e.target.value.trim() }))} />
                                                <img className='goMyLeft' src={editBtn} />
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between flex-wrap">
                                       <div>
                                          <h3 className="modal-title fs-5 inviteColor goFonte">Vessel Entry</h3>
                                          <span>N.B: All volumes are measured in litres</span>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="row mb-2">
                                    <div className="col-lg-6 col-6">
                                       <div className="mb-2 d-block">
                                          <label className="form-label d-block mb-1">Vessel Name</label>
                                          <input type="text" defaultValue={nData.title} className="form-control w-100 mb-0" placeholder='eg Placeholder' onChange={
                                             (e) => {
                                                setNData(edata => ({ ...edata, title: e.target.value.trim() }))
                                             }} />
                                       </div>
                                    </div>
                                    <div className="col-lg-6 col-6">
                                       <div className="mb-2 d-block">
                                          <label className="form-label d-block mb-1">Vessel Output</label>
                                          <input type="text" defaultValue={helpers.formatMoney(nData.vessel_output)} className="form-control w-100 mb-0" placeholder='eg Placeholder' onChange={
                                             (e) => {
                                                setNData(edata => ({ ...edata, vessel_output: e.target.value.trim() }))
                                                e.target.value = helpers.formatMoney(e.target.value.replace(/\,/g, '')) as any
                                             }} />
                                       </div>
                                    </div>
                                    <div className="col-lg-6 col-6">
                                       <div className="mb-2 d-block">
                                          <label className="form-label d-block mb-1">Throughput</label>
                                          <input type="text" defaultValue={nData.throughput} className="form-control w-100 mb-0" placeholder='eg Placeholder' onChange={
                                             (e) => {
                                                setNData(edata => ({ ...edata, throughput: e.target.value.trim() }))
                                             }} />
                                       </div>
                                    </div>
                                    <div className="col-lg-6 col-6">
                                       <div className="mb-2 d-block">
                                          <label className="form-label d-block mb-1">Pre-sold Volume</label>
                                          <input type="tel" defaultValue={helpers.formatMoney(nData.pre_sold)} className="form-control w-100 mb-0" placeholder='eg Placeholder' onChange={
                                             (e) => {
                                                setNData(edata => ({ ...edata, pre_sold: e.target.value.trim() }))
                                                e.target.value = helpers.formatMoney(e.target.value.replace(/\,/g, '')) as any
                                             }} />
                                       </div>
                                    </div>
                                 </div>
                                 <hr />
                                 <div className="mb-2 d-block mt-2">
                                    <div className="d-flex align-items-center justify-content-between flex-wrap">
                                       <h3 className="modal-title fs-5 inviteColor">Total Volume</h3>
                                    </div>
                                 </div>
                                 <div className="row">
                                    <div className="col-lg-4 col-6">
                                       <div className="mb-2 d-block">
                                          <label className="form-label d-block mb-1">DPK</label>
                                          <input type="tel" defaultValue={helpers.formatMoney(nData.total_dpk || 0)} className="form-control w-100 mb-0" placeholder='eg Placeholder' onChange={
                                             (e) => {
                                                setNData(edata => ({ ...edata, total_dpk: e.target.value.trim() }))
                                                e.target.value = helpers.formatMoney(e.target.value.replace(/\,/g, '')) as any
                                             }} />
                                       </div>
                                    </div>
                                    <div className="col-lg-4 col-6">
                                       <div className="mb-2 d-block">
                                          <label className="form-label d-block mb-1">AGO</label>
                                          <input type="tel" defaultValue={helpers.formatMoney(nData.total_ago || 0)} className="form-control w-100 mb-0" placeholder='eg Placeholder' onChange={
                                             (e) => {
                                                setNData(edata => ({ ...edata, total_ago: e.target.value.trim() }))
                                                e.target.value = helpers.formatMoney(e.target.value.replace(/\,/g, '')) as any
                                             }} />
                                       </div>
                                    </div>
                                    <div className="col-lg-4 col-6">
                                       <div className="mb-2 d-block">
                                          <label className="form-label d-block mb-1">PMS</label>
                                          <input type="tel" defaultValue={helpers.formatMoney(nData.total_pms || 0)} className="form-control w-100 mb-0" placeholder='eg Placeholder' onChange={
                                             (e) => {
                                                setNData(edata => ({ ...edata, total_pms: e.target.value.trim() }))
                                                e.target.value = helpers.formatMoney(e.target.value.replace(/\,/g, '')) as any
                                             }} />
                                       </div>
                                    </div>
                                 </div>
                                 <div className="mb-2 d-block">
                                    <div className="d-flex align-items-center justify-content-between flex-wrap">
                                       <h3 className="modal-title fs-5 inviteColor">Discharged Volume</h3>
                                    </div>
                                 </div>
                                 <div className="row">
                                    <div className="col-lg-4 col-6">
                                       <div className="mb-2 d-block">
                                          <label className="form-label d-block mb-1">DPK</label>
                                          <input type="tel" className="form-control w-100 mb-0" placeholder='eg Placeholder' onChange={
                                             (e) => {
                                                setNData(edata => ({ ...edata, dis_dpk: e.target.value.trim() }))
                                                e.target.value = helpers.formatMoney(e.target.value.replace(/\,/g, '')) as any
                                             }} />
                                          <span className='spfont'>Vol Discharged: <span style={{ color: 'red' }}>{helpers.formatMoney(mDPK)}</span></span>
                                       </div>
                                    </div>
                                    <div className="col-lg-4 col-6">
                                       <div className="mb-2 d-block">
                                          <label className="form-label d-block mb-1">AGO</label>
                                          <input type="tel" className="form-control w-100 mb-0" placeholder='eg Placeholder' onChange={
                                             (e) => {
                                                setNData(edata => ({ ...edata, dis_ago: e.target.value.trim() }))
                                                e.target.value = helpers.formatMoney(e.target.value.replace(/\,/g, '')) as any
                                             }} />
                                          <span className='spfont'>Vol Discharged: <span style={{ color: 'red' }}>{helpers.formatMoney(mAGO)}</span></span>
                                       </div>
                                    </div>
                                    <div className="col-lg-4 col-6">
                                       <div className="mb-2 d-block">
                                          <label className="form-label d-block mb-1">PMS</label>
                                          <input type="tel" className="form-control w-100 mb-0" placeholder='eg Placeholder' onChange={
                                             (e) => {
                                                setNData(edata => ({ ...edata, dis_pms: e.target.value.trim() }))
                                                e.target.value = helpers.formatMoney(e.target.value.replace(/\,/g, '')) as any
                                             }} />
                                          <span className='spfont'>Vol Discharged: <span style={{ color: 'red' }}>{helpers.formatMoney(mPMS)}</span></span>
                                       </div>
                                    </div>
                                 </div>

                              </div>
                              <div className="modal-footer">
                                 <button type="button" className="btn btn-lg light primaryBG2 ww-50" onClick={() => props.onPress && props.onPress()}>Close</button>
                                 <button type="button" className="btn btn-lg primaryBG ww-50" onClick={(e) => {
                                    e.preventDefault()
                                    updateOilEntry(nData, newData?.entry_id, props.onPress)
                                 }}>Submit</button>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

      </div>
   )
}