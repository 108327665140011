import { useEffect, useState } from "react"
import AppLayout from "../../components/AppLayout"
import * as SVGIcon from '../../assets/svg'
import StackChart from "../../components/StackChart"
import Comodity from "../../components/Comodity"
import commodityFunction from "../../hooks/commodityFunction"
import { useStoreSelector } from "../../store/useStore"
import helpers from "../../utils/helpers"
import priceFunction from "../../hooks/priceFunction"
const diamond: string = require('../../assets/icons/diamond.svg').default

export default () => {
   const { getCommodity, getCommodity2 } = commodityFunction()
   const { getMarketerAgo, getMarketerDpk, getMarketerLpg, getMarketerPms } = priceFunction()
   const { commodityData, commodity2Data, isLoading, pmsData, lpgData, dpkData, agoData } = useStoreSelector(["commodityData", "isLoading", "pmsData", "lpgData", "dpkData", "agoData", "commodity2Data"])
   let counter: number = 1
   let counter1: number = 1
   let counter2: number = 1
   let counter3: number = 1
   let counter4: number = 1
   useEffect(() => {
      getCommodity()
      getMarketerAgo()
      getMarketerDpk()
      getMarketerLpg()
      getMarketerPms()
      getCommodity2()
   }, [])
   // console.log(commodity2Data)
   let goldPrice: number = 0
   let LithiumPrice: number = 0
   let TantalumPrice: number = 0
   let TantalitePrice: number = 0
   let TinPrice: number = 0
   let NiobiumPPrice: number = 0
   let NiobiumCPrice: number = 0
   let MonazitePrice: number = 0
   let RutilePrice: number = 0
   let LithiumHPrice: number = 0
   let zinclme: number = 0
   let leadlme: number = 0
   commodity2Data.filter((data: any) => {
      // if (data.CodeId === 18256) {
      //    console.log(data.Value)
      // }
      if (data.CodeId === 36384) {
         LithiumHPrice = data.Value
      }
      if (data.CodeId === 18256) {
         LithiumPrice = data.Value
      }
      if (data.CodeId === 17737) {
         TantalumPrice = data.Value
      }
      if (data.CodeId === 14357) {
         TantalitePrice = data.Value
      }
      if (data.CodeId === 16963) {
         TinPrice = data.Value
      }
      if (data.CodeId === 17735) {
         NiobiumPPrice = data.Value
      }
      if (data.CodeId === 23183) {
         NiobiumCPrice = data.Value
      }
      if (data.CodeId === 30388) {
         goldPrice = data.Value
      }
      if (data.CodeId === 40764) {
         MonazitePrice = data.Value
      }
      if (data.CodeId === 17731) {
         RutilePrice = data.Value
      }
      if (data.CodeId === 34286) {
         zinclme = data.Value
      }
      if (data.CodeId === 34274) {
         leadlme = data.Value
      }
   })

   const getName: any = (name: any) => {
      let newName = name
      if (name === 18256) {
         newName = "Lithium concentrate"
      }
      if (name === 17737) {
         newName = "Tantalum pentoxide"
      }
      if (name === 14357) {
         newName = "Tantalite basis"
      }
      if (name === 16963) {
         newName = "Tin LME"
      }
      if (name === 17735) {
         newName = "Niobium pentoxide"
      }
      if (name === 23183) {
         newName = "Niobium (columbite)"
      }
      if (name === 30388) {
         newName = "Gold COMEX"
      }
      if (name === 40764) {
         newName = "Monazite"
      }
      if (name === 17731) {
         newName = "Rutile Australian"
      }
      if (name === 34286) {
         newName = "Zinc LME"
      }
      if (name === 34274) {
         newName = "Lead LME"
      }
      return newName
   }

   return (
      <AppLayout active="comodity" title="Commodity Prices">
         <div className="container-fluid">
            <div className="card h-auto">
               <div className="card-header">
                  <h4 className="card-title">Commodities Index</h4>
               </div>
               <div className="row" style={{ padding: "20px" }}>
                  <div className="col-lg-4 col-md-6 col-12">
                     <div className="card h-auto">
                        <div className="row">
                           <div className="col-12">
                              <div className="card">
                                 <div className="card-header pBottom borderAround" style={{ background: "#DADADA52" }}>
                                    <h6 className="card-title cardH6">Commodities</h6>
                                 </div>
                                 <div className="card-body pAll">
                                    <div className="table-responsive">
                                       <table className="table table-bordered bgBrand" style={{ textAlign: "center" }}>
                                          <thead>
                                             <th>SN</th>
                                             <th>Commodity</th>
                                             <th>Unit</th>
                                             <th>UnitPrice</th>
                                          </thead>
                                          <tbody>
                                             <tr>
                                                <th>1</th>
                                                <th>Lithium concentrate (spodumene) 6% Li2O cif China</th>
                                                <td>USD/mt</td>
                                                <td>{helpers.formatMoney(LithiumPrice || 0)}</td>
                                             </tr>
                                             <tr>
                                                <th>2</th>
                                                <th>Lithium hydroxide min 56.5% ex-works China excl. VAT</th>
                                                <td>USD/mt</td>
                                                <td>{helpers.formatMoney(LithiumHPrice || 0)}</td>
                                             </tr>
                                             <tr>
                                                <th>3</th>
                                                <th>Tantalite(25% Ta2O5 cif main port) (Europe)</th>
                                                <td>USD/mt</td>
                                                <td>{helpers.formatMoney(TantalitePrice || 0)}</td>
                                             </tr>
                                             <tr>
                                                <th>4</th>
                                                <th>Tantalum pentoxide min 99.5% ex-works China</th>
                                                <td>USD/mt</td>
                                                <td>{helpers.formatMoney(TantalumPrice || 0)}</td>
                                             </tr>
                                             <tr>
                                                <th>5</th>
                                                <th>Tin (Tin LME 3M Official)</th>
                                                <td>USD/mt</td>
                                                <td>{helpers.formatMoney(TinPrice || 0)}</td>
                                             </tr>
                                             <tr>
                                                <th>6</th>
                                                <th>Niobium (columbite) concentrate min 50% Nb2O5 CIF main port (Europe)</th>
                                                <td>USD/mt</td>
                                                <td>{helpers.formatMoney(NiobiumCPrice || 0)}</td>
                                             </tr>
                                             <tr>
                                                <th>7</th>
                                                <th>Niobium pentoxide min 99.5% ex-works China</th>
                                                <td>USD/mt</td>
                                                <td>{helpers.formatMoney(NiobiumPPrice || 0)}</td>
                                             </tr>
                                             <tr>
                                                <th>8</th>
                                                <th>Gold </th>
                                                <td>USD/mt</td>
                                                <td>{helpers.formatMoney(goldPrice || 0)}</td>
                                             </tr>
                                             <tr>
                                                <th>9</th>
                                                <th>Rutile Australian min 95% TiO2 ex-works China</th>
                                                <td>USD/oz</td>
                                                <td>{helpers.formatMoney(RutilePrice || 0)}</td>
                                             </tr>
                                             <tr>
                                                <th>10</th>
                                                <th>Monazite REO+ThO min 60pc ex-works China</th>
                                                <td>USD/mt</td>
                                                <td>{helpers.formatMoney(MonazitePrice || 0)}</td>
                                             </tr>
                                             <tr>
                                                <th>11</th>
                                                <th>Zinc LME</th>
                                                <td>USD/mt</td>
                                                <td>{helpers.formatMoney(zinclme || 0)}</td>
                                             </tr>
                                             <tr>
                                                <th>12</th>
                                                <th>Lead LME</th>
                                                <td>USD/mt</td>
                                                <td>{helpers.formatMoney(leadlme || 0)}</td>
                                             </tr>
                                          </tbody>
                                       </table>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           {/* <div className="col-12">
                              <Comodity data={[]} headers={["SN", "Comodity", "Unit", "Price"]} tableTitle="Commodities">
                                 {(commodityData || []).map((item: any, i: any) => (
                                    <tr>
                                       <th>{counter++}</th>
                                       <th>{item?.name.toUpperCase()}</th>
                                       <td>{item?.unit}</td>
                                       <td style={{ color: item?.price_data?.last_amount === 0 ? '#353946' : item?.price_data?.last_amount > 0 && item?.price_data?.last_amount > item?.price_data.amount ? "#DC001A" : "#23B310" }}>{item?.price_data ? (item?.price_data.currency === "usd" ? "$" : item?.price_data.currency === "eur" ? "€" : "₦") + helpers.formatMoney(item?.price_data.amount) : "-"}</td>
                                    </tr>))}
                              </Comodity>
                           </div> */}
                           <div className="col-12">
                              <Comodity data={[]} headers={["SN", "Depot", "Unit", "Price"]} tableTitle="LPG Per 20MT (Local Demand)">
                                 {(lpgData || []).map((item: any, i: any) => (
                                    <tr>
                                       <th>{counter1++}</th>
                                       <th>{item?.name.toUpperCase()}</th>
                                       <td>Per/Ltr</td>
                                       <td style={{ color: item?.price_data?.last_amount === 0 ? '#353946' : item?.price_data?.last_amount > 0 && item?.price_data?.last_amount > item?.price_data.amount ? "#DC001A" : "#23B310" }}>{item?.price_data ? (item?.price_data.currency === "usd" ? "$" : item?.price_data.currency === "eur" ? "€" : "₦") + helpers.formatMoney(item?.price_data.amount) : "-"}</td>
                                    </tr>))}
                              </Comodity>
                           </div>
                           <div className="col-12">
                              <Comodity data={[]} headers={["SN", "Marketer", "Unit", "Price"]} tableTitle="ATK & DPK (Local Price)">
                                 {(dpkData || []).map((item: any, i: any) => (
                                    <tr>
                                       <th>{counter2++}</th>
                                       <th>{item?.name.toUpperCase()}</th>
                                       <td>Per/Ltr</td>
                                       <td style={{ color: item?.price_data?.last_amount === 0 ? '#353946' : item?.price_data?.last_amount > 0 && item?.price_data?.last_amount > item?.price_data.amount ? "#DC001A" : "#23B310" }}>{item?.price_data ? (item?.price_data.currency === "usd" ? "$" : item?.price_data.currency === "eur" ? "€" : "₦") + helpers.formatMoney(item?.price_data.amount) : "-"}</td>
                                    </tr>))}
                              </Comodity>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12">
                     <div className="card h-auto">
                        <Comodity data={[]} headers={["SN", "Marketer", "Unit", "Price"]} tableTitle="PMS - Price per Litre (Local)">
                           {(pmsData || []).map((item: any, i: any) => (
                              <tr>
                                 <th>{counter3++}</th>
                                 <th>{item?.name.toUpperCase()}</th>
                                 <td>Per/Ltr</td>
                                 <td style={{ color: item?.price_data?.last_amount === 0 ? '#353946' : item?.price_data?.last_amount > 0 && item?.price_data?.last_amount > item?.price_data.amount ? "#DC001A" : "#23B310" }}>{item?.price_data ? (item?.price_data.currency === "usd" ? "$" : item?.price_data.currency === "eur" ? "€" : "₦") + helpers.formatMoney(item?.price_data.amount) : "-"}</td>
                              </tr>))}
                        </Comodity>
                     </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12">
                     <div className="card h-auto">
                        <Comodity data={[]} headers={["SN", "Marketer", "Unit", "Price"]} tableTitle="AGO - Price per Litre (Local)">
                           {(agoData || []).map((item: any, i: any) => (
                              <tr>
                                 <th>{counter4++}</th>
                                 <th>{item?.name.toUpperCase()}</th>
                                 <td>Per/Ltr</td>
                                 <td style={{ color: item?.price_data?.last_amount === 0 ? '#353946' : item?.price_data?.last_amount > 0 && item?.price_data?.last_amount > item?.price_data.amount ? "#DC001A" : "#23B310" }}>{item?.price_data ? (item?.price_data.currency === "usd" ? "$" : item?.price_data.currency === "eur" ? "€" : "₦") + helpers.formatMoney(item?.price_data.amount) : "-"}</td>
                              </tr>))}
                        </Comodity>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </AppLayout>
   )
}