import { useEffect, useState } from "react";
import { ObjectPayload } from "../typings/general";
import helpers from "../utils/helpers";

const vberth: string = require('../assets/icons/vberth.svg').default
const disch: string = require('../assets/icons/disch.svg').default
export type DataState = {
   data?: ObjectPayload;
}
export default (props: DataState) => {
   let nData = props.data
   const [tpms, setTpms] = useState(false)
   const [tago, setTago] = useState(false)
   const [tdpk, setTdpk] = useState(false)
   // console.log(nData)
   //percentage volume
   let dpkVol = nData?.dpk_vol ? ((nData?.dpk_vol / 20000000) * 100).toFixed(2) : 0
   let agoVol = nData?.ago_vol ? ((nData?.ago_vol / 20000000) * 100).toFixed(2) : 0
   let pmsVol = nData?.pms_vol ? ((nData?.pms_vol / 20000000) * 100).toFixed(2) : 0
   //actual volume
   let dpkVolAmount = nData?.dpk_vol ? (nData?.dpk_vol).toFixed(2) : 0
   let agoVolAmount = nData?.ago_vol ? (nData?.ago_vol).toFixed(2) : 0
   let pmsVolAmount = nData?.pms_vol ? (nData?.pms_vol).toFixed(2) : 0

   let dpkLost = nData?.dpk_lost ? ((nData?.dpk_lost / 20000000) * 100).toFixed(2) : 0
   let agoLost = nData?.ago_lost ? ((nData?.ago_lost / 20000000) * 100).toFixed(2) : 0
   let pmsLost = nData?.pms_lost ? ((nData?.pms_lost / 20000000) * 100).toFixed(2) : 0

   let dpkLostAmount = nData?.dpk_lost ? (nData?.dpk_lost).toFixed(2) : 0
   let agoLostAmount = nData?.ago_lost ? (nData?.ago_lost).toFixed(2) : 0
   let pmsLostAmount = nData?.pms_lost ? (nData?.pms_lost).toFixed(2) : 0


   return (
      <div className="card h-auto pbottom302 newHeight1">
         <div className="card-header pb-0 border-0 flex-wrap bbBottom" style={{ alignItems: "flex-start" }}>
            <div>
               <div className="chart-title mb-3">
                  <h6 className="heading margin-0 font13">{nData?.title ? nData?.title : "MT Virgo"}</h6>
                  {/* <span>20,000,000.40</span> */}
               </div>
            </div>
            <div className="p-static">
               <div className="chart-title mb-3">
                  <h6 className="heading margin-0 font13">AT BERTH</h6>
                  <span>(Post  Discharge Sailing)</span>
               </div>
            </div>
         </div>
         <div className="card-body pt-2 custome-tooltip pb-3 height250">
            <div id="activity">
               <div className="progress-content mb-4">
                  <div className="d-flex justify-content-between">
                     <h6>PMS {helpers.formatMoney(pmsVolAmount)}</h6>
                     {tpms &&
                        <h6 style={{ color: 'red', fontSize: "10px" }}>Lost {helpers.formatMoney(pmsLostAmount)}</h6>}
                     {/* <h6>PMS 20,000,000</h6> */}
                  </div>
                  <div className="progress bgPMS2" onMouseEnter={() => setTpms(true)} onMouseLeave={() => setTpms(false)}>
                     <div className="progress-bar bgPMS1" style={{ width: `${pmsVol}%`, height: "100%" }} role="progressbar">
                        <span className="sr-only">{pmsVol}% Complete</span>
                     </div>
                  </div>
               </div>
               <div className="progress-content mb-4">
                  <div className="d-flex justify-content-between">
                     <h6>DPK {helpers.formatMoney(dpkVolAmount)}</h6>
                     {tdpk &&
                        <h6 style={{ color: 'red', fontSize: "10px" }}>Lost {helpers.formatMoney(dpkLostAmount)}</h6>}
                     {/* <h6>DPK 20,000,000</h6> */}
                  </div>
                  <div className="progress bgDPK2" onMouseEnter={() => setTdpk(true)} onMouseLeave={() => setTdpk(false)}>
                     <div className="progress-bar bgDPK1" style={{ width: `${dpkVol}%`, height: "100%" }} role="progressbar">
                        <span className="sr-only">{dpkVol}% Complete</span>
                     </div>
                  </div>
               </div>
               <div className="progress-content mb-2">
                  <div className="d-flex justify-content-between">
                     <h6>AGO {helpers.formatMoney(agoVolAmount)}</h6>
                     {tago &&
                        <h6 style={{ color: 'red', fontSize: "10px" }}>Lost {helpers.formatMoney(agoLostAmount)}</h6>}
                     {/* <h6>AGO 20,000,000</h6> */}
                  </div>
                  <div className="progress bgAGO2" onMouseEnter={() => setTago(true)} onMouseLeave={() => setTago(false)}>
                     <div className="progress-bar bgAGO1" style={{ width: `${agoVol}%`, height: "100%" }} role="progressbar">
                        <span className="sr-only">{agoVol}% Complete</span>
                     </div>
                  </div>
               </div>
               <div className="mt-4 d-flex">
                  {/* <img src={disch} style={{ width: "40%" }} /> */}
                  <div style={{ width: "60%", marginTop: "2px" }}>
                     <div className="d-flex">
                        <div className="pmsStock "></div><div className="dpkStock"></div><div className="agoStock"></div> <span className="lostSpan">Vol Discharged</span>
                     </div>
                  </div>
                  <div style={{ width: "40%", marginTop: "2px", marginLeft: "20%" }}>
                     <div className="d-flex">
                        <div className="lostStock"></div> <span className="lostSpan">Stock Lost</span>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}
