import { useEffect } from 'react';
import './App.css';
import RouteComponent from "./routeList/index"
import { useStoreSelector } from './store/useStore';
import { BrowserRouter, Route, Router, Routes, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GasIndex from './pages/gas/GasIndex';
import NewLogin from './pages/login/NewLogin';
import LoginIndex from './pages/login/LoginIndex';
import Loader from './components/Loader';
import helpers from './utils/helpers';
import APIendPoint from './utils/apiEndPoints';


export default () => {
   // const navigate = useNavigate();
   const { userData, isTokenLoader, setIsTokenLoader, setUserData } = useStoreSelector(["userData", "isTokenLoader", "setIsTokenLoader", "setUserData"])
   let token = localStorage.getItem("__mtoken");
   const getprofile = async () => {
      if (!token) {
         // navigate("/");
         return;
      }
      setIsTokenLoader(true)

      let sendRequest = await helpers
         .sendRequest({
            url: APIendPoint.admin.profile,
            method: "GET",
            token: token,
         })
         .catch((e) => e);
      // return
      setIsTokenLoader(false)

      if (sendRequest && sendRequest.status_code !== 200) {
         localStorage.removeItem("__mtoken");
         // navigate("/");
         return;
         // return helpers.showErrorMessage(sendRequest);
      }
      setUserData({ ...sendRequest.data.data, token: token });
   };

   useEffect(() => {
      if (token) {
         getprofile()
      }
   }, [])
   return (
      <div>
         {isTokenLoader ?
            <Loader style={{ marginTop: "25%" }} /> :
            userData?.auth_id ?
               <RouteComponent /> :
               <BrowserRouter>
                  <Routes><Route path="*" Component={() => <LoginIndex />} /></Routes>
                  <Routes><Route path="/login/:id" Component={() => <NewLogin />} /></Routes>
               </BrowserRouter>}

         <ToastContainer style={{ width: "auto" }} pauseOnHover newestOnTop />
      </div>
   )
}
