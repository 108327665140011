import { ObjectPayload } from "../../typings/general";
import { StoreStateCreator } from "../useStore";

export interface PriceSliceState {
   priceData?: ObjectPayload;
   setpriceData?: (value: ObjectPayload) => void;

   mineCom?: ObjectPayload;
   setMineCom?: (value: ObjectPayload) => void;

   licenseData?: ObjectPayload;
   setLicenseData?: (value: ObjectPayload) => void;

   pmsData?: ObjectPayload;
   setPmsData?: (value: ObjectPayload) => void;

   agoData?: ObjectPayload;
   setAgoData?: (value: ObjectPayload) => void;

   dpkData?: ObjectPayload;
   setDpkData?: (value: ObjectPayload) => void;

   lpgData?: ObjectPayload;
   setLpgData?: (value: ObjectPayload) => void;

   marketerData?: ObjectPayload;
   setMarketerData?: (value: ObjectPayload) => void;

   priceCount?: ObjectPayload;
   setPriceCount?: (value: ObjectPayload) => void;

}

export const priceStoreSlice: StoreStateCreator<PriceSliceState> = (
   set,
   get
) => ({
   priceData: [],
   setPriceData: (value: ObjectPayload) => set({ priceData: value }),

   licenseData: [],
   setLicenseData: (value: ObjectPayload) => set({ licenseData: value }),

   mineCom: [],
   setMineCom: (value: ObjectPayload) => set({ mineCom: value }),

   marketerData: [],
   setMarketerData: (value: ObjectPayload) => set({ marketerData: value }),

   pmsData: [],
   setPmsData: (value: ObjectPayload) => set({ pmsData: value }),

   agoData: [],
   setAgoData: (value: ObjectPayload) => set({ agoData: value }),

   lpgData: [],
   setLpgData: (value: ObjectPayload) => set({ lpgData: value }),

   dpkData: [],
   setDpkData: (value: ObjectPayload) => set({ dpkData: value }),

   priceCount: {},
   setPriceCount: (value: ObjectPayload) => set({ priceCount: value }),
});
