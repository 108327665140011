import React from "react";
const empty: string = require('../assets/icons/empty.svg').default
interface AppTableProps {
   tableTitle?: string;
   headers?: string[];
   data?: Array<any>;
   isLoading?: boolean;
   children?: React.ReactNode
}

export default (props: AppTableProps) => {
   return (

      <div className="card mt-4 mb-4">
         <div className="card-body pAll">
            <div className="table-responsive">
               <div className="emptyClass">
                  <img src={empty} style={{ width: '20%' }} />
                  <h6 className="mt-4">No activity has been recorded</h6>
                  <p>Input daily data with ease, get started by clicking the button</p>
               </div>
            </div>
         </div>
      </div>
   )
}