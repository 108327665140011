import { StateCreator, create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { ObjectPayload } from "../typings/general";
import {
   GasSliceState,
   gasStoreSlice,
} from "./storeslice/gas-slice";
import { AdminSliceState, adminStoreSlice } from "./storeslice/admin-slice";
import { AlertSliceState, alertStoreSlice } from "./storeslice/alert-slice";
import { HumanSliceState, humanStoreSlice } from "./storeslice/hr-slice";
import { TradeSliceState, tradeStoreSlice } from "./storeslice/trade-slice";
import { CommoditySliceState, commodityStoreSlice } from "./storeslice/comodity-slice";
import { PriceSliceState, priceStoreSlice } from "./storeslice/price-slice";

type StoreState = GasSliceState & AdminSliceState & AlertSliceState & HumanSliceState & TradeSliceState & CommoditySliceState & PriceSliceState
export type StoreStateCreator<MyState> = StateCreator<
   MyState,
   [["zustand/immer", never], never],
   [],
   MyState
>;

export const useBoundStore = create<StoreState>()(
   immer<StoreState>((...a) => ({
      ...adminStoreSlice(...a),
      ...alertStoreSlice(...a),
      ...gasStoreSlice(...a),
      ...humanStoreSlice(...a),
      ...tradeStoreSlice(...a),
      ...commodityStoreSlice(...a),
      ...priceStoreSlice(...a)
   }))
);

export const useStoreSelector = (selector: Array<keyof StoreState>) => {
   return useBoundStore((store) =>
      selector.reduce(
         (acc, el) => ({ ...acc, [el]: store[el] }),
         {} as ObjectPayload
      )
   );
};
