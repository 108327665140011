import React, { useEffect, useState } from 'react'
import { Properties as CSSProperties } from 'csstype'
import { ObjectPayload } from '../typings/general';
import commodityFunction from '../hooks/commodityFunction';
import { useStoreSelector } from '../store/useStore';
import helpers from '../utils/helpers';
import priceFunction from '../hooks/priceFunction';
import hrFunction from '../hooks/hrFunction';

export type DataState = {
   data?: ObjectPayload;
   onPress?: Function;
   containerStyle?: CSSProperties;
}
export default (props: DataState) => {
   let nData = props?.data
   let newDD = nData ? nData?.expiration_date.split('T')[0] : ''
   const [comState, setComState] = useState({ data: { location: nData?.location, commodity_id: nData?.commodity_id, company_id: nData?.company_id, expiration_date: newDD, type: nData?.type, number: nData?.number, cadastral: nData?.cadastral } })
   const { getLocation, getCommodity } = commodityFunction()
   const { updateLicense, getMineCompany } = priceFunction()
   const { getCompany } = hrFunction()
   const { locationData, commodityData, humanData, mineCom } = useStoreSelector(["locationData", "commodityData", "humanData", "mineCom"])
   useEffect(() => {
      getLocation()
      getCompany()
      getCommodity()
      getMineCompany()
   }, [])
   return (
      <div style={{
         position: 'fixed', left: 0, top: 0,
         display: 'flex',
         justifyContent: 'center',
         alignItems: 'center',
         backgroundColor: 'rgba(0, 0, 0, 0.3)',
         backgroundSize: "cover",
         width: '100%',
         height: '100vh',
         zIndex: 999,
      }}>
         <div style={{ borderRadius: '20px', padding: '20px', width: '500px', ...props.containerStyle }}>
            <div className='bg-white' style={{ borderRadius: "20px" }}>
               <div className="filterForm w-100">
                  <div className='popupClass1'>
                     <div className="modal-content">
                        <div className="modal-body modalBody" style={{ marginTop: "0" }}>
                           <div className="mb-3 d-block">
                              <div className="d-flex align-items-center justify-content-between flex-wrap">
                                 <h3 className="modal-title fs-5 inviteColor goFonte" id="exampleModalLabel">Licence Entry</h3>
                                 <i onClick={() => props.onPress && props.onPress()} style={{ fontSize: '20px', marginRight: '-5px' }} className="fa fa-times m-color-red showCursor" aria-hidden="true"></i>
                              </div>
                           </div>
                           <div className="mb-3 d-block">
                              <label className="form-label d-block mb-2">Asset Type</label>
                              <input type="text" defaultValue={comState?.data?.commodity_id} className="form-control w-100 mb-0" onChange={(e) => {
                                 setComState(state => ({ ...state, data: { ...state.data, commodity_id: e.target.value } }))
                              }} />
                           </div>
                           <div className="mb-3 d-block">
                              <label className="form-label d-block mb-2">Location</label>
                              <input type="text" defaultValue={comState?.data?.location} className="form-control w-100 mb-0" onChange={(e) => {
                                 setComState(state => ({ ...state, data: { ...state.data, location: e.target.value } }))
                              }} />
                           </div>
                           <div className="mb-3 d-block">
                              <label className="form-label d-block mb-2">company</label>
                              <select className="form-control w-100 mb-0 selectH" defaultValue={comState?.data?.company_id} aria-label="Default select example" onChange={(e) => {
                                 setComState(state => ({ ...state, data: { ...state.data, company_id: e.target.value } }))
                              }}>
                                 <option value="">Select</option>
                                 {mineCom ?
                                    (mineCom || []).map((item: any, i: any) => (
                                       <option selected={comState?.data?.company_id === item.company_id} value={item.company_id}>{item.name}</option>
                                    )) : "Loading"}
                              </select>
                           </div>
                           <div className="mb-3 d-block">
                              <label className="form-label d-block mb-2">Expiration date</label>
                              <input type="date" className="form-control w-100 mb-0" defaultValue={comState?.data?.expiration_date} onChange={(e) => {
                                 setComState(state => ({ ...state, data: { ...state.data, expiration_date: e.target.value } }))
                              }} />
                           </div>
                           <div className="mb-3 d-block">
                              <label className="form-label d-block mb-2">Type</label>
                              <input type="text" className="form-control w-100 mb-0" defaultValue={comState?.data?.type} onChange={(e) => {
                                 setComState(state => ({ ...state, data: { ...state.data, type: e.target.value } }))
                              }} placeholder='Mining, exporation etc' />
                           </div>
                           <div className="mb-3 d-block">
                              <label className="form-label d-block mb-2">Licence Number</label>
                              <input type="text" className="form-control w-100 mb-0" defaultValue={comState?.data?.number} onChange={(e) => {
                                 setComState(state => ({ ...state, data: { ...state.data, number: e.target.value } }))
                              }} placeholder='12345' />
                           </div>
                           <div className="mb-3 d-block">
                              <label className="form-label d-block mb-2">Area (km<sup>2</sup>)</label>
                              <input type="text" className="form-control w-100 mb-0" defaultValue={comState?.data?.cadastral} onChange={(e) => {
                                 setComState(state => ({ ...state, data: { ...state.data, cadastral: e.target.value } }))
                              }} placeholder='12345' />
                           </div>
                        </div>
                        <div className="modal-footer">
                           <button type="button" className="btn btn-lg light primaryBG2 ww-48" onClick={() => props.onPress && props.onPress()}>Close</button>
                           <button type="button" className="btn btn-lg primaryBG ww-48" onClick={() => updateLicense(comState.data, nData?.license_id, props.onPress)}>Submit</button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

      </div>
   )
}