import React, { useEffect, useState } from 'react'
import { Properties as CSSProperties } from 'csstype'
import { ObjectPayload } from '../typings/general';
import { AGODATA, DPKDATA, LPGDATA, PMSDATA } from '../constants/arrayData';
import commodityFunction from '../hooks/commodityFunction';
import { useStoreSelector } from '../store/useStore';
import helpers from '../utils/helpers';
import priceFunction from '../hooks/priceFunction';

export type DataState = {
   data?: ObjectPayload;
   onPress?: Function;
   containerStyle?: CSSProperties;
}
export default (props: DataState) => {
   let nData = props.data
   const [comState, setComState] = useState({ data: { amount: nData?.amount, type: nData?.type, type_id: nData?.type_id, currency: nData?.currency } })

   const { updatePrice } = priceFunction()

   return (
      <div style={{
         position: 'fixed', left: 0, top: 0,
         display: 'flex',
         justifyContent: 'center',
         alignItems: 'center',
         backgroundColor: 'rgba(0, 0, 0, 0.3)',
         backgroundSize: "cover",
         width: '100%',
         height: '100vh',
         paddingBottom: "20px",
         zIndex: 999,
      }}>
         <div style={{ borderRadius: '20px', padding: '20px', width: '700px', ...props.containerStyle }}>
            <div className='bg-white' style={{ borderRadius: "20px" }}>
               <div className="filterForm w-100">
                  <div className='popupClass1'>
                     <div className="modal-content">
                        <div className="mb-2 d-block" style={{ paddingTop: "20px", paddingLeft: "30px" }}>
                           <div className="d-flex align-items-center justify-content-between flex-wrap">
                              <div>
                                 <h3 className="modal-title fs-5 inviteColor goFonte">Commodity Pricing data</h3>
                                 <span>Enter the revelant data for current commodity prices</span>
                              </div>
                           </div>
                        </div>

                        {/* vessle entering */}
                        <div className="tab-content" id="myTabContent">
                           <div className="">
                              <div className="modal-body" style={{ marginTop: "0", paddingBottom: "0", paddingTop: "0" }}>
                                 <div className="row">
                                    <div className="col-12">
                                       <div className="mb-2 d-block">
                                          {/* <label className="form-label d-block mb-1">Select Marketer</label> */}
                                          <input type="text" disabled className="form-control w-100 mb-0" placeholder='eg Placeholder' value={comState.data.type_id} />

                                       </div>
                                    </div>
                                    <div className="col-12">
                                       <div className="mb-2 d-block">
                                          <label className="form-label d-block mb-1">Enter Price</label>
                                          <div className='row'>
                                             <div className='col-2' style={{ paddingRight: "0" }}>
                                                <select className="form-control w-100 mb-0 binput2 selectH" aria-label="Default select example" defaultValue={nData?.currency} style={{ borderRight: "none" }} onChange={(e) => {
                                                   setComState(state => ({ ...state, data: { ...state.data, currency: e.target.value } }))
                                                }}>
                                                   <option selected={nData?.currency === "ngn"} value="ngn">NGN</option>
                                                   <option selected={nData?.currency === "usd"} value="usd">USD</option>
                                                   <option selected={nData?.currency === "eur"} value="eur">EUR</option>
                                                </select>
                                             </div>
                                             <div className='col-10' style={{ paddingLeft: "0" }}>
                                                <input style={{ borderLeft: "none" }} type="tel" className="form-control w-100 mb-0 binput" defaultValue={helpers.formatMoney(nData?.amount)} placeholder='eg Placeholder' onChange={(e) => {
                                                   setComState(state => ({ ...state, data: { ...state.data, amount: e.target.value } }))
                                                   e.target.value = helpers.formatMoney(e.target.value.replace(/\,/g, '')) as any
                                                }} />
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div className="modal-footer">
                                 <button type="button" className="btn btn-lg light primaryBG2 ww-50" onClick={() => props.onPress && props.onPress()}>Close</button>
                                 <button type="button" className="btn btn-lg primaryBG ww-50" onClick={() => updatePrice(comState.data, nData?.entry_id, props.onPress)}>Submit</button>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

      </div>
   )
}