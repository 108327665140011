import { Routes, RouteObject } from "react-router-dom"
import GasIndex from "../pages/gas/GasIndex"


export const GasRoute: RouteObject = {
   path: "/gas",
   children: [
      {
         path: "/gas",
         element: <GasIndex />
      },
   ]
}