import React, { useState } from 'react'
import { Properties as CSSProperties } from 'csstype'
import { ObjectPayload } from '../typings/general';
import userAdminFunction from '../hooks/userAdminFunction';
import { useStoreSelector } from '../store/useStore';

export type DataState = {
   data?: ObjectPayload;
   onPress?: Function;
   containerStyle?: CSSProperties;
}
export default (props: DataState) => {
   const { createAdmin } = userAdminFunction()
   const { isLoading } = useStoreSelector(["isLoading"])
   const [nData, setNData] = useState({ fullname: '', email: '', department: '', role: '', password: '' })
   return (
      <div style={{
         position: 'fixed', left: 0, top: 0,
         display: 'flex',
         justifyContent: 'center',
         alignItems: 'center',
         backgroundColor: 'rgba(0, 0, 0, 0.3)',
         backgroundSize: "cover",
         width: '100%', height: '100vh', zIndex: 999,
      }}>
         <div style={{ borderRadius: '20px', padding: '20px', width: '500px', ...props.containerStyle }}>
            <div className='bg-white' style={{ borderRadius: "20px" }}>
               <div className="filterForm w-100">
                  <div className='popupClass1'>
                     <div className="modal-content">
                        <div className="modal-body" style={{ marginTop: "0" }}>
                           <div className="mb-3 d-block">
                              <div className="d-flex align-items-center justify-content-between flex-wrap">
                                 <h3 className="modal-title fs-5 inviteColor goFonte" id="exampleModalLabel">Invitation</h3>
                                 <i onClick={() => props.onPress && props.onPress()} style={{ fontSize: '20px', marginRight: '-5px' }} className="fa fa-times m-color-red showCursor" aria-hidden="true"></i>
                              </div>
                              <p>Send out invite links to team mates</p>
                           </div>
                           <div className="mb-2 d-block">
                              <label className="form-label d-block mb-2">Full name</label>
                              <input type="text" className="form-control w-100 mb-0" onChange={(e) => setNData(edata => ({ ...edata, fullname: e.target.value.trim() }))} />
                           </div>
                           <div className="mb-2 d-block">
                              <label className="form-label d-block mb-2">Email</label>
                              <input type="text" className="form-control w-100 mb-0" onChange={(e) => setNData(edata => ({ ...edata, email: e.target.value.trim() }))} />
                           </div>
                           <div className="mb-2 d-block">
                              <label className="form-label d-block mb-2">Designation/Role</label>
                              <select className="form-control w-100 mb-0 selectH pselect4" aria-label="Default select example" onChange={(e) => setNData(edata => ({ ...edata, role: e.target.value.trim() }))}>
                                 <option value="r">Select a role</option>
                                 <option value="guest">Guest</option>
                                 <option value="data-officer">Data Officer</option>
                                 <option value="admin">Admin</option>
                                 <option value="superadmin">Super Admin</option>
                              </select>
                           </div>
                           <div className="mb-2 d-block">
                              <label className="form-label d-block mb-2">Department</label>
                              <select className="form-control w-100 mb-0 selectH pselect4" aria-label="Default select example" onChange={(e) => setNData(edata => ({ ...edata, department: e.target.value.trim() }))}>
                                 <option value="">Select a department</option>
                                 <option value="oil & gas">Oil & Gas</option>
                                 <option value="mining">Mining</option>
                                 <option value="commodity pricing">Commodity Pricing</option>
                                 <option value="trading">Trading</option>
                                 <option value="human resources">Human Resources</option>
                              </select>
                           </div>
                           <div className="mb-2 d-block">
                              <label className="form-label d-block mb-2">Password</label>
                              <input type="password" className="form-control w-100 mb-0" onChange={(e) => setNData(edata => ({ ...edata, password: e.target.value.trim() }))} />
                           </div>
                        </div>
                        <div className="modal-footer">
                           <button type="button" className="btn btn-lg light primaryBG2 ww-48" onClick={() => props.onPress && props.onPress()}>Close</button>
                           <button type="button" disabled={isLoading || false} onClick={() => createAdmin(nData, props.onPress)} className="btn btn-lg primaryBG ww-48">Send Invite</button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

      </div>
   )
}