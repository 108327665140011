import { Routes, RouteObject } from "react-router-dom"
import AccountIndex from "../pages/account/AccountIndex"


export const AccountRoute: RouteObject = {
   path: "/Account",
   children: [
      {
         path: "",
         element: <AccountIndex />
      },
   ]
}