import { useEffect, useState } from "react"
import AppLayout from "../../components/AppLayout"
import * as SVGIcon from '../../assets/svg'
import StackChart from "../../components/StackChart"
import Comodity from "../../components/Comodity"
import HumanTable from "../../components/HumanTable"
import HumanComp from "../../components/HumanComp"
import hrFunction from "../../hooks/hrFunction"
import { useStoreSelector } from "../../store/useStore"
import EmptyTable from "../../components/EmptyTable"
import Loader from "../../components/Loader"
const diamond: string = require('../../assets/icons/diamond.svg').default
const onCourse: string = require('../../assets/icons/oncourse.svg').default
const behind: string = require('../../assets/icons/behind.svg').default

export default () => {
   const { getAllEntry } = hrFunction()
   const { humanEntryAllData, isLoading } = useStoreSelector(["humanEntryAllData", "isLoading"])
   useEffect(() => {
      getAllEntry()
   }, [])
   return (
      <AppLayout active="human" title="Human Resources">
         <div className="container-fluid">
            <div className="card h-auto pbottom30">
               <div className="card-header nheaderPad">
                  <h4 className="card-title ffh">Human Resources Perfomance Data <span className="mmleft mmleftFont"><img src={onCourse} /> On course</span><span className="mmleft mmleftFont"><img src={behind} /> Behind</span></h4>
               </div>
               <div className="row padHuman">
                  {isLoading && <Loader style={{ position: "relative" }} />}
                  {!isLoading && humanEntryAllData && humanEntryAllData.length > 0 ?
                     (humanEntryAllData || []).map((item: any, i: any) => (
                        <div className="col-12 mbb-55" key={i}>
                           <HumanComp tableTitle={item?.name} data={item?.entry_data} />
                        </div>
                     )) : !isLoading ? <EmptyTable /> : null}
               </div>
            </div>
         </div>
      </AppLayout>
   )
}