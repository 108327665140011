import { ObjectPayload } from "../../typings/general";
import { StoreStateCreator } from "../useStore";

export interface CommoditySliceState {
   commodityData?: ObjectPayload;
   setCommodityData?: (value: ObjectPayload) => void;

   commodity2Data?: ObjectPayload;
   setCommodity2Data?: (value: ObjectPayload) => void;

   commodityCount?: ObjectPayload;
   setCommodityCount?: (value: ObjectPayload) => void;

   commodityPriceCount?: ObjectPayload;
   setCommodityPriceCount?: (value: ObjectPayload) => void;

   locationData?: ObjectPayload;
   setLocationData?: (value: ObjectPayload) => void;

   miningEntryCount?: ObjectPayload;
   setMiningEntryCount?: (value: ObjectPayload) => void;

   miningEntryData?: ObjectPayload;
   setMiningEntryData?: (value: ObjectPayload) => void;

   miningListData?: ObjectPayload;
   setMiningListData?: (value: ObjectPayload) => void;

   miningGraphData?: ObjectPayload;
   setMiningGraphData?: (value: ObjectPayload) => void;

}

export const commodityStoreSlice: StoreStateCreator<CommoditySliceState> = (
   set,
   get
) => ({
   commodityData: [],
   setCommodityData: (value: ObjectPayload) => set({ commodityData: value }),

   commodity2Data: [],
   setCommodity2Data: (value: ObjectPayload) => set({ commodity2Data: value }),

   commodityCount: {},
   setCommodityCount: (value: ObjectPayload) => set({ commodityCount: value }),

   commodityPriceCount: {},
   setCommodityPriceCount: (value: ObjectPayload) => set({ commodityPriceCount: value }),

   locationData: [],
   setLocationData: (value: ObjectPayload) => set({ locationData: value }),

   miningEntryCount: {},
   setMiningEntryCount: (value: ObjectPayload) => set({ miningEntryCount: value }),

   miningEntryData: [],
   setMiningEntryData: (value: ObjectPayload) => set({ miningEntryData: value }),

   miningListData: [],
   setMiningListData: (value: ObjectPayload) => set({ miningListData: value }),

   miningGraphData: [],
   setMiningGraphData: (value: ObjectPayload) => set({ miningGraphData: value }),
});
