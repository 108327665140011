import { Routes, RouteObject } from "react-router-dom"
import GuideOne from "../pages/guide/GuideOne"
import GuideTwo from "../pages/guide/GuideTwo"
import GuideThree from "../pages/guide/GuideThree"


export const GuideRoute: RouteObject = {
   path: "/guide",
   children: [
      {
         path: "/guide",
         element: <GuideOne />
      },
      {
         path: "/guide/two",
         element: <GuideTwo />
      },
      {
         path: "/guide/final",
         element: <GuideThree />
      },
   ]
}