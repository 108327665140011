import React, { useEffect, useState } from 'react'
import { Properties as CSSProperties } from 'csstype'
import { ObjectPayload } from '../typings/general';
import helpers from '../utils/helpers';
import oilFunction from '../hooks/oilFunction';
import { useStoreSelector } from '../store/useStore';
const editBtn: string = require('../assets/icons/date.svg').default

export type DataState = {
   data?: ObjectPayload;
   onPress?: Function;
   containerStyle?: CSSProperties;
}
export default (props: DataState) => {
   const [vessel, setVessel] = useState(true)
   const [price, setPrice] = useState(false)
   const { createOilEntry, createPriceEntry, getpriceCountStatus } = oilFunction()
   const todayDate = () => {
      let now = new Date();
      let month: any = (now.getMonth() + 1);
      let day: any = now.getDate();
      if (month < 10)
         month = "0" + month;
      if (day < 10)
         day = "0" + day;
      return now.getFullYear() + '-' + month + '-' + day;
   }
   let oldDate = todayDate()
   const [nData, setNData] = useState({ title: "", throughput: "", ndate: oldDate, total_pms: "", total_ago: "", total_dpk: "", dis_pms: "", dis_ago: "", dis_dpk: "", lost: "", pre_sold: "", vessel_output: "" })

   const [nData1, setNData1] = useState({ ndate1: oldDate, unit_pms: "", unit_ago: "", unit_dpk: "", vol_pms: "", vol_ago: "", vol_dpk: "" })

   let mLost: any = 0
   // console.log(nData)
   const goForm = () => {
      if (nData.total_ago.length > 0) {
         mLost = parseFloat(nData.total_ago.replace(/\,/g, '')) - parseFloat(nData.dis_ago.replace(/\,/g, ''))
      }
      if (nData.total_pms.length > 0 && nData.dis_pms.length > 0) {
         let ptotal = nData.total_pms.length > 0 ? parseFloat(nData.total_pms.replace(/\,/g, '')) : 0
         let pdischarge = nData.dis_pms.length > 0 ? parseFloat(nData.dis_pms.replace(/\,/g, '')) : 0
         mLost = ptotal - pdischarge
         // setNData((e) => ({ ...e, lost: mLost }))
      }
      if (parseFloat(nData.total_dpk) > 0) {
         mLost = parseFloat(nData.total_dpk.replace(/\,/g, '')) - parseFloat(nData.dis_dpk.replace(/\,/g, ''))
      }
   }
   const [oPrice, setoPrice] = useState({ dpk: 0, pms: 0, ago: 0 })
   const getTotal = (ab: any, bc: any, type: string) => {
      if (ab && bc) {
         let nab = parseFloat(ab.replace(/\,/g, ''))
         let nab1 = parseFloat(bc.replace(/\,/g, ''))
         let newAb = nab === 0 ? 1 : nab
         let newBc = nab1 === 0 ? 1 : nab1
         let dc = newAb * newBc
         if (type === "dpk") {
            setoPrice((e) => ({ ...e, dpk: dc }))
         }
         if (type === "pms") {
            setoPrice((e) => ({ ...e, pms: dc }))
         }
         if (type === "ago") {
            setoPrice((e) => ({ ...e, ago: dc }))
         }
      } else {
         return 0.00
      }
   }
   const { getOilTankDay } = oilFunction()
   const { gasTankChart, } = useStoreSelector(["gasTankChart"])
   const { singlePriceData } = useStoreSelector(["singlePriceData"])
   useEffect(() => {
      getpriceCountStatus()
      getOilTankDay()
   }, [])
   let dpkPrice = singlePriceData?.dpk_price ? singlePriceData?.dpk_price : 0
   let dpkVol = singlePriceData?.dpk_vol ? singlePriceData?.dpk_vol : 0

   let agoPrice = singlePriceData?.ago_price ? singlePriceData?.ago_price : 0
   let agoVol = singlePriceData?.ago_vol ? singlePriceData?.ago_vol : 0

   let pmsPrice = singlePriceData?.pms_price ? singlePriceData?.pms_price : 0
   let pmsVol = singlePriceData?.pms_vol ? singlePriceData?.pms_vol : 0

   let arrayData: any = gasTankChart ? gasTankChart : []
   let dataVol = {
      AGO: 0,
      DPK: 0,
      PMS: 0
   }
   for (let i = 0; i < arrayData.length; i++) {
      if (arrayData[i].type === "pms") {
         let nob = arrayData[i].volume
         dataVol.PMS = nob as any
      }
      if (arrayData[i].type === "dpk") {
         let nob1 = arrayData[i].volume
         dataVol.DPK = nob1 as any
      }
      if (arrayData[i].type === "ago") {
         let nob2 = arrayData[i].volume
         dataVol.AGO = nob2 as any
      }
   }
   // .modal{
   //    display: block !important; /* I added this to see the modal, you don't need this */
   // }

   // /* Important part */
   // .modal-dialog{
   //    overflow-y: initial !important
   // }

   return (
      <div style={{
         position: 'fixed', left: 0, top: 0,
         display: 'flex',
         justifyContent: 'center',
         alignItems: 'center',
         backgroundColor: 'rgba(0, 0, 0, 0.3)',
         backgroundSize: "cover",
         width: '100%',
         height: '100vh',
         paddingBottom: "20px",
         zIndex: 999,
      }}>
         <div style={{ borderRadius: '20px', padding: '20px', width: '600px', ...props.containerStyle }}>
            <div className='bg-white' style={{ borderRadius: "20px" }}>
               <div className="filterForm w-100">
                  <div className='popupClass1'>
                     <div className="modal-content">
                        <div className="card-header flex-wrap border-0">
                           <ul className="nav nav-tabs dzm-tabs" id="myTab" role="tablist" style={{ backgroundColor: "none", width: "100%" }}>
                              <li className={`nav-item oilLi showCursor ${vessel ? "active2" : ""}`} role="presentation" onClick={() => {
                                 setVessel(true)
                                 setPrice(false)
                              }}>
                                 <a className="nav-link oilBtn" id="home-tab">Vessel Entry</a>
                              </li>
                              <li className={`nav-item oilLi showCursor ${price ? "active2" : ""}`} role="presentation" onClick={() => {
                                 setPrice(true)
                                 setVessel(false)
                              }}>
                                 <a className="nav-link oilBtn" id="profile-tab">Price Entry</a>
                              </li>
                           </ul>
                        </div>
                        {/* vessle entering */}
                        <div className="tab-content" id="myTabContent">
                           {vessel ?
                              <div className="">
                                 <div className="modal-body modalBody" style={{ marginTop: "0", paddingBottom: "0", paddingTop: "0" }}>
                                    <div className="mb-2 d-block" style={{ marginTop: "-30px" }}>
                                       <div className="row mb-2">
                                          <div className='col-8'></div>
                                          <div className='col-4'>
                                             <div className='container onContainer'>
                                                <div className='d-flex showCursor'>
                                                   <input type="date" defaultValue={nData.ndate} className="form-control w-100 mb-0 npinput" placeholder='eg Placeholder' onChange={(e) => setNData(edata => ({ ...edata, ndate: e.target.value.trim() }))} />
                                                   <img className='goMyLeft' src={editBtn} />
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="d-flex align-items-center justify-content-between flex-wrap">
                                          <div>
                                             <h3 className="modal-title fs-5 inviteColor goFonte">Vessel Entry</h3>
                                             <span>N.B: All volumes are measured in litres</span>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="row mb-2">
                                       <div className="col-lg-6 col-6">
                                          <div className="mb-2 d-block">
                                             <label className="form-label d-block mb-1">Vessel Name</label>
                                             <input type="text" className="form-control w-100 mb-0" placeholder='eg Placeholder' onChange={
                                                (e) => {
                                                   setNData(edata => ({ ...edata, title: e.target.value.trim() }))
                                                }} />
                                          </div>
                                       </div>
                                       <div className="col-lg-6 col-6">
                                          <div className="mb-2 d-block">
                                             <label className="form-label d-block mb-1">Vessel Output</label>
                                             {/* <label className="form-label d-block mb-1" style={{ color: "red" }}>Stock lost</label> */}
                                             {/* <input type="text" defaultValue={helpers.formatMoney(nData.lost)} className="form-control w-100 mb-0" placeholder='eg Placeholder' /> */}
                                             <input type="text" className="form-control w-100 mb-0" placeholder='eg Placeholder' onChange={
                                                (e) => {
                                                   setNData(edata => ({ ...edata, vessel_output: e.target.value.trim() }))
                                                   e.target.value = helpers.formatMoney(e.target.value.replace(/\,/g, '')) as any
                                                }} />
                                          </div>
                                       </div>
                                       <div className="col-lg-6 col-6">
                                          <div className="mb-2 d-block">
                                             <label className="form-label d-block mb-1">Throughput</label>
                                             <input type="text" className="form-control w-100 mb-0" placeholder='eg Placeholder' onChange={
                                                (e) => {
                                                   setNData(edata => ({ ...edata, throughput: e.target.value.trim() }))
                                                }} />
                                          </div>
                                       </div>
                                       <div className="col-lg-6 col-6">
                                          <div className="mb-2 d-block">
                                             <label className="form-label d-block mb-1">Pre-sold Volume</label>
                                             <input type="tel" className="form-control w-100 mb-0" placeholder='eg Placeholder' onChange={
                                                (e) => {
                                                   setNData(edata => ({ ...edata, pre_sold: e.target.value.trim() }))
                                                   e.target.value = helpers.formatMoney(e.target.value.replace(/\,/g, '')) as any
                                                }} />
                                          </div>
                                       </div>
                                    </div>
                                    <hr />
                                    <div className="mb-2 d-block mt-2">
                                       <div className="d-flex align-items-center justify-content-between flex-wrap">
                                          <h3 className="modal-title fs-5 inviteColor">Total Volume</h3>
                                       </div>
                                    </div>
                                    <div className="row">
                                       <div className="col-lg-4 col-6">
                                          <div className="mb-2 d-block">
                                             <label className="form-label d-block mb-1">DPK</label>
                                             <input type="tel" className="form-control w-100 mb-0" placeholder='eg Placeholder' onChange={
                                                (e) => {
                                                   setNData(edata => ({ ...edata, total_dpk: e.target.value.trim() }))
                                                   e.target.value = helpers.formatMoney(e.target.value.replace(/\,/g, '')) as any
                                                }} />
                                          </div>
                                       </div>
                                       <div className="col-lg-4 col-6">
                                          <div className="mb-2 d-block">
                                             <label className="form-label d-block mb-1">AGO</label>
                                             <input type="tel" className="form-control w-100 mb-0" placeholder='eg Placeholder' onChange={
                                                (e) => {
                                                   setNData(edata => ({ ...edata, total_ago: e.target.value.trim() }))
                                                   e.target.value = helpers.formatMoney(e.target.value.replace(/\,/g, '')) as any
                                                }} />
                                          </div>
                                       </div>
                                       <div className="col-lg-4 col-6">
                                          <div className="mb-2 d-block">
                                             <label className="form-label d-block mb-1">PMS</label>
                                             <input type="tel" className="form-control w-100 mb-0" placeholder='eg Placeholder' onChange={
                                                (e) => {
                                                   setNData(edata => ({ ...edata, total_pms: e.target.value.trim() }))
                                                   e.target.value = helpers.formatMoney(e.target.value.replace(/\,/g, '')) as any
                                                   goForm()
                                                }} />
                                          </div>
                                       </div>
                                    </div>
                                    <div className="mb-2 d-block">
                                       <div className="d-flex align-items-center justify-content-between flex-wrap">
                                          <h3 className="modal-title fs-5 inviteColor">Discharged Volume</h3>
                                       </div>
                                    </div>
                                    <div className="row">
                                       <div className="col-lg-4 col-6">
                                          <div className="mb-2 d-block">
                                             <label className="form-label d-block mb-1">DPK</label>
                                             <input type="tel" className="form-control w-100 mb-0" placeholder='eg Placeholder' onChange={
                                                (e) => {
                                                   setNData(edata => ({ ...edata, dis_dpk: e.target.value.trim() }))
                                                   e.target.value = helpers.formatMoney(e.target.value.replace(/\,/g, '')) as any
                                                }} />
                                          </div>
                                       </div>
                                       <div className="col-lg-4 col-6">
                                          <div className="mb-2 d-block">
                                             <label className="form-label d-block mb-1">AGO</label>
                                             <input type="tel" className="form-control w-100 mb-0" placeholder='eg Placeholder' onChange={
                                                (e) => {
                                                   setNData(edata => ({ ...edata, dis_ago: e.target.value.trim() }))
                                                   e.target.value = helpers.formatMoney(e.target.value.replace(/\,/g, '')) as any
                                                }} />
                                          </div>
                                       </div>
                                       <div className="col-lg-4 col-6">
                                          <div className="mb-2 d-block">
                                             <label className="form-label d-block mb-1">PMS</label>
                                             <input type="tel" className="form-control w-100 mb-0" placeholder='eg Placeholder' onChange={
                                                (e) => {
                                                   setNData(edata => ({ ...edata, dis_pms: e.target.value.trim() }))
                                                   e.target.value = helpers.formatMoney(e.target.value.replace(/\,/g, '')) as any
                                                   goForm()
                                                }} />
                                          </div>
                                       </div>
                                    </div>

                                 </div>
                                 <div className="modal-footer">
                                    <button type="button" className="btn btn-lg light primaryBG2 ww-50" onClick={() => props.onPress && props.onPress()}>Close</button>
                                    <button type="button" className="btn btn-lg primaryBG ww-50" onClick={(e) => {
                                       e.preventDefault()
                                       createOilEntry(nData, props.onPress)
                                    }}>Submit</button>
                                 </div>
                              </div> : ""}
                           {/* Price entering */}
                           {price ?
                              <div className="">
                                 <div className="modal-body modalBody" style={{ marginTop: "0", paddingBottom: "0", paddingTop: "0" }}>
                                    <div className="mb-2 d-block" style={{ marginTop: "-30px" }}>
                                       <div className="row mb-2">
                                          <div className='col-8'></div>
                                          <div className='col-4'>
                                             <div className='container onContainer'>
                                                <div className='d-flex showCursor'>
                                                   <input type="date" defaultValue={nData1.ndate1} className="form-control w-100 mb-0 npinput" placeholder='eg Placeholder' onChange={(e) => setNData1(edata => ({ ...edata, ndate1: e.target.value.trim() }))} />
                                                   <img className='goMyLeft' src={editBtn} />
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       {/* <div className="d-flex align-items-center justify-content-between flex-wrap">
                                          <div>
                                             <h2 className="modal-title fs-5 inviteColor">Price Entry</h2>
                                             <span>Update new data to replace the previous</span>
                                          </div>
                                       </div> */}
                                    </div>

                                    <div className="mb-2 d-block">
                                       <div className="d-flex align-items-center justify-content-between flex-wrap">
                                          <div>
                                             <h3 className="modal-title fs-5 inviteColor">Unit Price</h3>
                                             <span>N.B: All prices are measured in Naira(NGN)</span>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="row">
                                       <div className="col-lg-4 col-6">
                                          <div className="mb-2 d-block">
                                             <label className="form-label d-block mb-1">DPK</label>
                                             <input type="tel" value={helpers.formatMoney(nData1.unit_dpk.replace(/\,/g, '')) as any} className="form-control w-100 mb-0" placeholder='eg Placeholder' onChange={
                                                (e) => {
                                                   setNData1(edata => ({ ...edata, unit_dpk: e.target.value.trim() }))
                                                   getTotal(e.target.value, nData1.vol_dpk, "dpk")
                                                }} />
                                          </div>
                                       </div>
                                       <div className="col-lg-4 col-6">
                                          <div className="mb-2 d-block">
                                             <label className="form-label d-block mb-1">AGO</label>
                                             <input type="tel" value={helpers.formatMoney(nData1.unit_ago.replace(/\,/g, '')) as any} className="form-control w-100 mb-0" placeholder='eg Placeholder' onChange={
                                                (e) => {
                                                   setNData1(edata => ({ ...edata, unit_ago: e.target.value.trim() }))
                                                   getTotal(e.target.value, nData1.vol_ago, "ago")
                                                }} />
                                          </div>
                                       </div>
                                       <div className="col-lg-4 col-6">
                                          <div className="mb-2 d-block">
                                             <label className="form-label d-block mb-1">PMS</label>
                                             <input type="tel" value={helpers.formatMoney(nData1.unit_pms.replace(/\,/g, '')) as any} className="form-control w-100 mb-0" placeholder='eg Placeholder' onChange={
                                                (e) => {
                                                   setNData1(edata => ({ ...edata, unit_pms: e.target.value.trim() }))
                                                   getTotal(e.target.value, nData1.vol_pms, "pms")
                                                }} />
                                          </div>
                                       </div>
                                    </div>
                                    <div className="mb-2 d-block">
                                       <div className="d-flex align-items-center justify-content-between flex-wrap">
                                          <div>
                                             <h3 className="modal-title fs-5 inviteColor">Volume</h3>
                                             <span>N.B: All volumes are measured in Litres</span>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="row">
                                       <div className="col-lg-4 col-6">
                                          <div className="mb-2 d-block">
                                             <label className="form-label d-block mb-1">DPK</label>
                                             <input type="tel" value={helpers.formatMoney(nData1.vol_dpk.replace(/\,/g, '')) as any} className="form-control w-100 mb-0" placeholder='eg Placeholder' onChange={
                                                (e) => {
                                                   setNData1(edata => ({ ...edata, vol_dpk: e.target.value.trim() }))
                                                   getTotal(nData1.unit_dpk, e.target.value, "dpk")
                                                }} />
                                             <span className='spfont'>Avail Vol: <span style={{ color: 'red' }}>{helpers.formatMoney(dataVol.DPK.toFixed(2) || 0)}</span></span>
                                          </div>
                                       </div>
                                       <div className="col-lg-4 col-6">
                                          <div className="mb-2 d-block">
                                             <label className="form-label d-block mb-1">AGO</label>
                                             <input type="tel" value={helpers.formatMoney(nData1.vol_ago.replace(/\,/g, '')) as any} className="form-control w-100 mb-0" placeholder='eg Placeholder' onChange={
                                                (e) => {
                                                   setNData1(edata => ({ ...edata, vol_ago: e.target.value.trim() }))
                                                   getTotal(nData1.unit_ago, e.target.value, "ago")
                                                }} />
                                             <span className='spfont'>Avail Vol: <span style={{ color: 'red' }}>{helpers.formatMoney(dataVol.AGO.toFixed(2) || 0)}</span></span>
                                          </div>
                                       </div>
                                       <div className="col-lg-4 col-6">
                                          <div className="mb-2 d-block">
                                             <label className="form-label d-block mb-1">PMS</label>
                                             <input type="tel" value={helpers.formatMoney(nData1.vol_pms.replace(/\,/g, '')) as any} className="form-control w-100 mb-0" placeholder='eg Placeholder' onChange={
                                                (e) => {
                                                   setNData1(edata => ({ ...edata, vol_pms: e.target.value.trim() }))
                                                   getTotal(nData1.unit_pms, e.target.value, "pms")
                                                }} />
                                             <span className='spfont'>Avail Vol: <span style={{ color: 'red' }}>{helpers.formatMoney(dataVol.PMS.toFixed(2) || 0)}</span></span>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="mb-2 d-block">
                                       <div className="d-flex align-items-center justify-content-between flex-wrap">
                                          <div>
                                             <h3 className="modal-title fs-5 inviteColor">Total Price</h3>
                                             <span>N.B: All volumes are measured in Litres</span>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="row">
                                       <div className="col-lg-4 col-6">
                                          <div className="mb-2 d-block">
                                             <label className="form-label d-block mb-1">DPK</label>
                                             <input type="tel" value={helpers.formatMoney(oPrice.dpk.toFixed(2))} disabled className="form-control w-100 mb-0" placeholder='eg Placeholder' />
                                          </div>
                                       </div>
                                       <div className="col-lg-4 col-6">
                                          <div className="mb-2 d-block">
                                             <label className="form-label d-block mb-1">AGO</label>
                                             <input type="tel" value={helpers.formatMoney(oPrice.ago.toFixed(2))} disabled className="form-control w-100 mb-0" placeholder='eg Placeholder' />
                                          </div>
                                       </div>
                                       <div className="col-lg-4 col-6">
                                          <div className="mb-2 d-block">
                                             <label className="form-label d-block mb-1">PMS</label>
                                             <input type="tel" value={helpers.formatMoney(oPrice.pms.toFixed(2))} className="form-control w-100 mb-0" disabled placeholder='eg Placeholder' />
                                          </div>
                                       </div>
                                    </div>
                                    <div className="mb-2 d-block">
                                       <div className="d-flex align-items-center justify-content-between flex-wrap">
                                          <div>
                                             <h3 className="modal-title fs-5 inviteColor">Accumulative Daily Product Sales</h3>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="row">
                                       <div className="col-lg-4 col-6">
                                          <div className="mb-2 d-block">
                                             <label className="form-label d-block mb-1">DPK</label>
                                             <input type="text" value={helpers.formatMoney(dpkVol)} disabled className="form-control w-100 mb-0" placeholder='eg Placeholder' />
                                          </div>
                                       </div>
                                       <div className="col-lg-4 col-6">
                                          <div className="mb-2 d-block">
                                             <label className="form-label d-block mb-1">AGO</label>
                                             <input type="text" value={helpers.formatMoney(agoVol)} disabled className="form-control w-100 mb-0" placeholder='eg Placeholder' />
                                          </div>
                                       </div>
                                       <div className="col-lg-4 col-6">
                                          <div className="mb-2 d-block">
                                             <label className="form-label d-block mb-1">PMS</label>
                                             <input type="text" value={helpers.formatMoney(pmsVol)} disabled className="form-control w-100 mb-0" placeholder='eg Placeholder' />
                                          </div>
                                       </div>
                                    </div>
                                    <div className="mb-2 d-block">
                                       <div className="d-flex align-items-center justify-content-between flex-wrap">
                                          <div>
                                             <h3 className="modal-title fs-5 inviteColor">Accumulative Daily Revenue</h3>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="row">
                                       <div className="col-lg-4 col-6">
                                          <div className="mb-2 d-block">
                                             <label className="form-label d-block mb-1">DPK</label>
                                             <input type="text" value={helpers.formatMoney(dpkPrice)} disabled className="form-control w-100 mb-0" placeholder='eg Placeholder' />
                                          </div>
                                       </div>
                                       <div className="col-lg-4 col-6">
                                          <div className="mb-2 d-block">
                                             <label className="form-label d-block mb-1">AGO</label>
                                             <input type="text" value={helpers.formatMoney(agoPrice)} disabled className="form-control w-100 mb-0" placeholder='eg Placeholder' />
                                          </div>
                                       </div>
                                       <div className="col-lg-4 col-6">
                                          <div className="mb-2 d-block">
                                             <label className="form-label d-block mb-1">PMS</label>
                                             <input type="text" value={helpers.formatMoney(pmsPrice)} disabled className="form-control w-100 mb-0" placeholder='eg Placeholder' />
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="modal-footer">
                                    <button type="button" className="btn btn-lg light primaryBG2 ww-50" onClick={() => props.onPress && props.onPress()}>Close</button>
                                    <button type="button" className="btn btn-lg primaryBG ww-50" onClick={(e) => {
                                       e.preventDefault()
                                       createPriceEntry(nData1, props.onPress)
                                    }}>Submit</button>
                                 </div>
                              </div> : ""}
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

      </div>
   )
}