import React, { useEffect, useState } from 'react'
import { Properties as CSSProperties } from 'csstype'
import { ObjectPayload } from '../typings/general';
import { AGODATA, DPKDATA, LPGDATA, PMSDATA } from '../constants/arrayData';
import commodityFunction from '../hooks/commodityFunction';
import { useStoreSelector } from '../store/useStore';
import helpers from '../utils/helpers';
import priceFunction from '../hooks/priceFunction';

export type DataState = {
   data?: ObjectPayload;
   onPress?: Function;
   containerStyle?: CSSProperties;
}
export default (props: DataState) => {
   const [comState, setComState] = useState({ data: { amount: '', type: '', type_id: '', currency: 'ngn' } })
   const [COMMODITY, setCOMMODITY] = useState(true)
   const [PMS, setPMS] = useState(false)
   const [AGO, setAGO] = useState(false)
   const [LPG, setLPG] = useState(false)
   const [ATK, setATK] = useState(false)

   const { getCommodity } = commodityFunction()
   const { createPrice, getMarketers } = priceFunction()
   const { commodityData, marketerData } = useStoreSelector(["commodityData", "marketerData"])
   useEffect(() => {
      getCommodity()
      getMarketers('pms')
   }, [])
   return (
      <div style={{
         position: 'absolute', left: 0, top: 0,
         display: 'flex',
         justifyContent: 'center',
         alignItems: 'center',
         backgroundColor: 'rgba(0, 0, 0, 0.3)',
         backgroundSize: "cover",
         width: '100%',
         height: '100vh',
         paddingBottom: "20px",
         zIndex: 999,
      }}>
         <div style={{ borderRadius: '20px', padding: '20px', width: '700px', ...props.containerStyle }}>
            <div className='bg-white' style={{ borderRadius: "20px" }}>
               <div className="filterForm w-100">
                  <div className='popupClass1'>
                     <div className="modal-content">
                        <div className="mb-2 d-block" style={{ paddingTop: "20px", paddingLeft: "30px" }}>
                           <div className="d-flex align-items-center justify-content-between flex-wrap">
                              <div>
                                 <h3 className="modal-title fs-5 inviteColor">Commodity Pricing data</h3>
                                 <span>Enter the revelant data for current commodity prices</span>
                              </div>
                           </div>
                        </div>
                        <div className="card-header flex-wrap border-0">
                           <span>Select pricing sector</span>
                           <ul className="nav nav-tabs dzm-tabs" id="myTab" role="tablist" style={{ backgroundColor: "none", width: "100%" }}>
                              <li className={`nav-item oilLi showCursor ${COMMODITY ? "active2" : ""}`} role="presentation" onClick={() => {

                                 setCOMMODITY(true)
                                 setAGO(false)
                                 setPMS(false)
                                 setLPG(false)
                                 setATK(false)
                              }}>
                                 <a className="nav-link oilBtn" id="home-tab">Commodity</a>
                              </li>
                              <li className={`nav-item oilLi showCursor ${PMS ? "active2" : ""}`} role="presentation" onClick={() => {
                                 getMarketers('pms')
                                 setCOMMODITY(false)
                                 setAGO(false)
                                 setPMS(true)
                                 setLPG(false)
                                 setATK(false)
                              }}>
                                 <a className="nav-link oilBtn" id="profile-tab">PMS</a>
                              </li>
                              <li className={`nav-item oilLi showCursor ${LPG ? "active2" : ""}`} role="presentation" onClick={() => {
                                 getMarketers('lpg')
                                 setCOMMODITY(false)
                                 setAGO(false)
                                 setPMS(false)
                                 setLPG(true)
                                 setATK(false)
                              }}>
                                 <a className="nav-link oilBtn" id="profile-tab">LPG</a>
                              </li>
                              <li className={`nav-item oilLi showCursor ${AGO ? "active2" : ""}`} role="presentation" onClick={() => {
                                 getMarketers('ago')
                                 setCOMMODITY(false)
                                 setAGO(true)
                                 setPMS(false)
                                 setLPG(false)
                                 setATK(false)
                              }}>
                                 <a className="nav-link oilBtn" id="profile-tab">AGO</a>
                              </li>
                              <li className={`nav-item oilLi showCursor ${ATK ? "active2" : ""}`} role="presentation" onClick={() => {
                                 getMarketers('dpk')
                                 setCOMMODITY(false)
                                 setAGO(false)
                                 setPMS(false)
                                 setLPG(false)
                                 setATK(true)
                              }}>
                                 <a className="nav-link oilBtn" id="profile-tab">ATK & DPK</a>
                              </li>
                           </ul>
                        </div>
                        {/* vessle entering */}
                        <div className="tab-content" id="myTabContent">
                           {COMMODITY ?
                              <div className="">
                                 <div className="modal-body" style={{ marginTop: "0", paddingBottom: "0", paddingTop: "0" }}>

                                    <div className="row">
                                       <div className="col-12">
                                          <div className="mb-2 d-block">
                                             <label className="form-label d-block mb-1">Select Commodity</label>
                                             <select className="form-control w-100 mb-0 selectH pselect4" aria-label="Default select example" onChange={(e) => {
                                                setComState(state => ({ ...state, data: { ...state.data, type_id: e.target.value } }))
                                                setComState(state => ({ ...state, data: { ...state.data, type: "commodity" } }))
                                             }}>
                                                <option value="">Select</option>
                                                {commodityData ?
                                                   (commodityData || []).map((item: any, i: any) => (
                                                      <option value={item.entry_id}>{item.name}</option>
                                                   )) : "Loading"}
                                             </select>
                                          </div>
                                       </div>
                                       <div className="col-12">
                                          <div className="mb-2 d-block">
                                             <label className="form-label d-block mb-1">Enter Price</label>
                                             <div className='row'>
                                                <div className='col-2' style={{ paddingRight: "0" }}>
                                                   <select className="form-control w-100 mb-0 binput2 selectH" aria-label="Default select example" style={{ borderRight: "none" }} onChange={(e) => {
                                                      setComState(state => ({ ...state, data: { ...state.data, currency: e.target.value } }))
                                                   }}>
                                                      <option value="ngn">NGN</option>
                                                      <option value="usd">USD</option>
                                                      <option value="eur">EUR</option>
                                                   </select>
                                                </div>
                                                <div className='col-10' style={{ paddingLeft: "0" }}>
                                                   <input style={{ borderLeft: "none" }} type="tel" className="form-control w-100 mb-0 binput" placeholder='eg Placeholder' onChange={(e) => {
                                                      setComState(state => ({ ...state, data: { ...state.data, amount: e.target.value } }))
                                                      e.target.value = helpers.formatMoney(e.target.value.replace(/\,/g, '')) as any
                                                   }} />
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="modal-footer">
                                    <button type="button" className="btn btn-lg light primaryBG2 ww-50" onClick={() => props.onPress && props.onPress()}>Close</button>
                                    <button type="button" className="btn btn-lg primaryBG ww-50" onClick={() => createPrice(comState.data, props.onPress)}>Submit</button>
                                 </div>
                              </div> : ""}
                           {/* Price entering */}
                           {PMS ?
                              <div className="">
                                 <div className="modal-body" style={{ marginTop: "0", paddingBottom: "0", paddingTop: "0" }}>
                                    <div className="row">
                                       <div className="col-12">
                                          <div className="mb-2 d-block">
                                             <label className="form-label d-block mb-1">Select Depot</label>
                                             <select className="form-control w-100 mb-0 selectH" aria-label="Default select example" onChange={(e) => {
                                                setComState(state => ({ ...state, data: { ...state.data, type_id: e.target.value } }))
                                                setComState(state => ({ ...state, data: { ...state.data, type: "pms" } }))
                                             }}>
                                                <option value="">Select</option>

                                                {marketerData ?
                                                   (marketerData || []).map((item: any, i: any) => (
                                                      <option value={item?.marketer_id}>{item.name}</option>
                                                   )) : "Loading"}
                                             </select>
                                          </div>
                                       </div>
                                       <div className="col-12">
                                          <div className="mb-2 d-block">
                                             <label className="form-label d-block mb-1">Enter Price</label>
                                             <div className='row'>
                                                <div className='col-2' style={{ paddingRight: "0" }}>
                                                   <select className="form-control w-100 mb-0 binput2 selectH" aria-label="Default select example" style={{ borderRight: "none" }} onChange={(e) => {
                                                      setComState(state => ({ ...state, data: { ...state.data, currency: e.target.value } }))
                                                   }}>
                                                      <option value="ngn">NGN</option>
                                                      <option value="usd">USD</option>
                                                      <option value="eur">EUR</option>
                                                   </select>
                                                </div>
                                                <div className='col-10' style={{ paddingLeft: "0" }}>
                                                   <input style={{ borderLeft: "none" }} type="tel" className="form-control w-100 mb-0 binput" placeholder='eg Placeholder' onChange={(e) => {
                                                      setComState(state => ({ ...state, data: { ...state.data, amount: e.target.value } }))
                                                      e.target.value = helpers.formatMoney(e.target.value.replace(/\,/g, '')) as any
                                                   }} />
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="modal-footer">
                                    <button type="button" className="btn btn-lg light primaryBG2 ww-50" onClick={() => props.onPress && props.onPress()}>Close</button>
                                    <button type="button" className="btn btn-lg primaryBG ww-50" onClick={() => createPrice(comState.data, props.onPress)}>Submit</button>
                                 </div>
                              </div> : ""}
                           {LPG ?
                              <div className="">
                                 <div className="modal-body" style={{ marginTop: "0", paddingBottom: "0", paddingTop: "0" }}>
                                    <div className="row">
                                       <div className="col-12">
                                          <div className="mb-2 d-block">
                                             <label className="form-label d-block mb-1">Select Marketer</label>
                                             <select className="form-control w-100 mb-0 selectH" aria-label="Default select example" onChange={(e) => {
                                                setComState(state => ({ ...state, data: { ...state.data, type_id: e.target.value } }))
                                                setComState(state => ({ ...state, data: { ...state.data, type: "lpg" } }))
                                             }}>
                                                <option value="">Select</option>
                                                {marketerData ?
                                                   (marketerData || []).map((item: any, i: any) => (
                                                      <option value={item.marketer_id}>{item.name}</option>
                                                   )) : "Loading"}
                                             </select>
                                          </div>
                                       </div>
                                       <div className="col-12">
                                          <div className="mb-2 d-block">
                                             <label className="form-label d-block mb-1">Enter Price</label>
                                             <div className='row'>
                                                <div className='col-2' style={{ paddingRight: "0" }}>
                                                   <select className="form-control w-100 mb-0 binput2 selectH" aria-label="Default select example" style={{ borderRight: "none" }} onChange={(e) => {
                                                      setComState(state => ({ ...state, data: { ...state.data, currency: e.target.value } }))
                                                   }}>
                                                      <option value="ngn">NGN</option>
                                                      <option value="usd">USD</option>
                                                      <option value="eur">EUR</option>
                                                   </select>
                                                </div>
                                                <div className='col-10' style={{ paddingLeft: "0" }}>
                                                   <input style={{ borderLeft: "none" }} type="tel" className="form-control w-100 mb-0 binput" placeholder='eg Placeholder' onChange={(e) => {
                                                      setComState(state => ({ ...state, data: { ...state.data, amount: e.target.value } }))
                                                      e.target.value = helpers.formatMoney(e.target.value.replace(/\,/g, '')) as any
                                                   }} />
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="modal-footer">
                                    <button type="button" className="btn btn-lg light primaryBG2 ww-50" onClick={() => props.onPress && props.onPress()}>Close</button>
                                    <button type="button" className="btn btn-lg primaryBG ww-50" onClick={() => createPrice(comState.data, props.onPress)}>Submit</button>
                                 </div>
                              </div> : ""}
                           {AGO ?
                              <div className="">
                                 <div className="modal-body" style={{ marginTop: "0", paddingBottom: "0", paddingTop: "0" }}>
                                    <div className="row">
                                       <div className="col-12">
                                          <div className="mb-2 d-block">
                                             <label className="form-label d-block mb-1">Select Marketer</label>
                                             <select className="form-control w-100 mb-0 selectH" aria-label="Default select example" onChange={(e) => {
                                                setComState(state => ({ ...state, data: { ...state.data, type_id: e.target.value } }))
                                                setComState(state => ({ ...state, data: { ...state.data, type: "ago" } }))
                                             }}>
                                                <option value="r">Select</option>
                                                {marketerData ?
                                                   (marketerData || []).map((item: any, i: any) => (
                                                      <option value={item?.marketer_id}>{item.name}</option>
                                                   )) : "Loading"}
                                             </select>
                                          </div>
                                       </div>
                                       <div className="col-12">
                                          <div className="mb-2 d-block">
                                             <label className="form-label d-block mb-1">Enter Price</label>
                                             <div className='row'>
                                                <div className='col-2' style={{ paddingRight: "0" }}>
                                                   <select className="form-control w-100 mb-0 binput2 selectH" aria-label="Default select example" style={{ borderRight: "none" }} onChange={(e) => {
                                                      setComState(state => ({ ...state, data: { ...state.data, currency: e.target.value } }))
                                                   }}>
                                                      <option value="ngn">NGN</option>
                                                      <option value="usd">USD</option>
                                                      <option value="eur">EUR</option>
                                                   </select>
                                                </div>
                                                <div className='col-10' style={{ paddingLeft: "0" }}>
                                                   <input style={{ borderLeft: "none" }} type="tel" className="form-control w-100 mb-0 binput" placeholder='eg Placeholder' onChange={(e) => {
                                                      setComState(state => ({ ...state, data: { ...state.data, amount: e.target.value } }))
                                                      e.target.value = helpers.formatMoney(e.target.value.replace(/\,/g, '')) as any
                                                   }} />
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="modal-footer">
                                    <button type="button" className="btn btn-lg light primaryBG2 ww-50" onClick={() => props.onPress && props.onPress()}>Close</button>
                                    <button type="button" className="btn btn-lg primaryBG ww-50" onClick={() => createPrice(comState.data, props.onPress)}>Submit</button>
                                 </div>
                              </div> : ""}
                           {ATK ?
                              <div className="">
                                 <div className="modal-body" style={{ marginTop: "0", paddingBottom: "0", paddingTop: "0" }}>
                                    <div className="row">
                                       <div className="col-12">
                                          <div className="mb-2 d-block">
                                             <label className="form-label d-block mb-1">Select Marketer</label>
                                             <select className="form-control w-100 mb-0 selectH" aria-label="Default select example" onChange={(e) => {
                                                setComState(state => ({ ...state, data: { ...state.data, type_id: e.target.value } }))
                                                setComState(state => ({ ...state, data: { ...state.data, type: "dpk" } }))
                                             }}>
                                                <option value="">Select</option>
                                                {marketerData ?
                                                   (marketerData || []).map((item: any, i: any) => (
                                                      <option value={item?.marketer_id}>{item.name}</option>
                                                   )) : "Loading"}
                                             </select>
                                          </div>
                                       </div>
                                       <div className="col-12">
                                          <div className="mb-2 d-block">
                                             <label className="form-label d-block mb-1">Enter Price</label>
                                             <div className='row'>
                                                <div className='col-2' style={{ paddingRight: "0" }}>
                                                   <select className="form-control w-100 mb-0 binput2 selectH" aria-label="Default select example" style={{ borderRight: "none" }} onChange={(e) => {
                                                      setComState(state => ({ ...state, data: { ...state.data, currency: e.target.value } }))
                                                   }}>
                                                      <option value="ngn">NGN</option>
                                                      <option value="usd">USD</option>
                                                      <option value="eur">EUR</option>
                                                   </select>
                                                </div>
                                                <div className='col-10' style={{ paddingLeft: "0" }}>
                                                   <input style={{ borderLeft: "none" }} type="tel" className="form-control w-100 mb-0 binput" placeholder='eg Placeholder' onChange={(e) => {
                                                      setComState(state => ({ ...state, data: { ...state.data, amount: e.target.value } }))
                                                      e.target.value = helpers.formatMoney(e.target.value.replace(/\,/g, '')) as any
                                                   }} />
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="modal-footer">
                                    <button type="button" className="btn btn-lg light primaryBG2 ww-50" onClick={() => props.onPress && props.onPress()}>Close</button>
                                    <button type="button" className="btn btn-lg primaryBG ww-50" onClick={() => createPrice(comState.data, props.onPress)}>Submit</button>
                                 </div>
                              </div> : ""}
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

      </div>
   )
}