import { BrowserRouter, createBrowserRouter, RouterProvider } from "react-router-dom"
import { GasRoute } from "./gas-route"
import { MineRoute } from "./mine-route"
import { ComodityRoute } from "./comodity-route"
import { HumanRoute } from "./human-route"
import { TradeRoute } from "./trade-route"
import { InputRoute } from "./input-route"
import { InviteRoute } from "./invite-route"
import { AccountRoute } from "./account-route"
import { LoginRoute } from "./login-route"
import { GuideRoute } from "./guard-route"


const ComponentRouter = [
   GasRoute,
   MineRoute,
   ComodityRoute,
   HumanRoute,
   TradeRoute,
   InputRoute,
   InviteRoute,
   AccountRoute,
   GuideRoute,
   LoginRoute
]


export default () => {

   const appRouter = createBrowserRouter(ComponentRouter)
   return (
      <RouterProvider router={appRouter} fallbackElement={<div>Not found</div>} />
   )
}