import react, { useState } from 'react'
import { useStoreSelector } from '../../store/useStore'
import userAdminFunction from '../../hooks/userAdminFunction'
import { NavLink } from 'react-router-dom'
const logo: string = require('../../assets/icons/logo.svg').default
export default () => {
   const [isLoading, setIsLoading] = useState(false)
   const { firstLogin } = userAdminFunction()
   const { userData } = useStoreSelector(["userData"])
   const [loginData, setLoginData] = useState({ fullname: userData?.fullname, auth_id: userData?.auth_id, password: '' })

   return (
      <div className="container-fluid h-100 nbglogin">
         <div>
            <nav className="navbar navbar-expand-sm fixed-top navbar-light logMenuBg">
               <div className="container">
                  <div className='row newstyle'>
                     <div className='col-lg-4 d-flex'>
                        <a className="navbar-brand text-white" href="#">www.aipecgroup.com</a>
                        <a className="navbar-brand" href="#"><span className="text-white">●</span></a>
                        <a className="navbar-brand text-white" href="#">Aipec Data Centre</a>
                     </div>
                  </div>
               </div>
            </nav>
         </div>
         <div className="row h-100 align-items-center justify-contain-center">
            <div className="col-xl-12">
               <div className="card main-width">
                  <div className="card-body  p-0">
                     <div className="newWidth">
                        <div className="card mNewCard">
                           <div className="card-body">
                              <div className="d-flex align-items-center justify-content-center mb-4">
                                 <a href="#">
                                    <img src={logo} />
                                 </a>
                              </div>
                              <h2 className='nppColor'>Welcome</h2>
                              <span>Sign in to your dashboard</span>
                              <form className="mt-4">
                                 <div className="form-group mb-2">
                                    <label>Full name</label>
                                    <input type="email" onChange={(e) => setLoginData(edata => ({ ...edata, fullname: e.target.value.trim() }))} defaultValue={userData?.fullname} className="form-control" id="exampleInputEmail1" placeholder="Eyituoyo" />
                                 </div>
                                 <div className="form-group mb-2">
                                    <label>Designated role</label>
                                    <input type="text" disabled defaultValue={userData?.user_type === "guest" ? "Guest" : "Administrator"} className="form-control" id="exampleInputEmail1" placeholder="Administrator" />
                                 </div>
                                 <div className="form-group mb-2">
                                    <label>Email address</label>
                                    <input type="email" disabled defaultValue={userData?.email} className="form-control" id="exampleInputEmail1" placeholder="Enter Email" />
                                 </div>
                                 <div className="form-group mb-4">
                                    <label>Password</label>
                                    <input type="password" onChange={(e) => setLoginData(edata => ({ ...edata, password: e.target.value.trim() }))} className="form-control" id="exampleInputPassword1" placeholder="Password" />
                                 </div>
                              </form>
                              <div className="d-flex align-items-center justify-content-between flex-wrap">
                                 <p style={{ marginTop: "-12px" }}><NavLink to="/" className="text-primary textGreen font-w500 showCursor">Guest Login</NavLink></p>
                                 <button className="btn btn-primary mb-4 btnGreen" disabled={isLoading || false} onClick={() => firstLogin(loginData.auth_id, loginData.password, loginData.fullname, setIsLoading)}>Get Started</button>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}