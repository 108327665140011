import { useEffect, useState } from "react"
import oilFunction from "../hooks/oilFunction"
import { useStoreSelector } from "../store/useStore"
import LineChart from "./LineChart"


export default () => {
   const { getpriceLineStatus } = oilFunction()
   const { isLoading, linePriceData } = useStoreSelector(["isLoading", "linePriceData"])
   const [ntype, setNtype] = useState("month")

   if (ntype === "month") {
      let element = document.querySelector(".monthM")
      let element2 = document.querySelector(".yearM")
      element?.classList.add("salesLinkActive")
      element2?.classList.remove("salesLinkActive")
   }
   if (ntype === "year") {
      let element = document.querySelector(".monthM")
      let element2 = document.querySelector(".yearM")
      element2?.classList.add("salesLinkActive")
      element?.classList.remove("salesLinkActive")
   }
   useEffect(() => {
      getpriceLineStatus()
   }, [])

   return (
      <div className="card h-auto">
         <div className="card-header pb-0 border-0 flex-wrap chartBB" style={{ alignItems: "flex-start" }}>
            <div>
               <div className="chart-title mb-3">
                  <h6 className="heading margin-0 font16">Daily Sales Revenue</h6>
               </div>
            </div>
            <div className="p-statics">
               <div className="d-flex align-items-center mb-3">
                  <p className="salesLink pselect2 showCursor monthM" onClick={(e => {
                     getpriceLineStatus("one-month")
                     setNtype("month")
                  })}>This Month</p>
                  <p className="salesLink pselect2 showCursor yearM" onClick={(e => {
                     getpriceLineStatus("year")
                     setNtype("year")
                  })}>This Year</p>
                  {/* <p className="salesLink pselect2 showCursor">Last 6 Month</p> */}
               </div>
            </div>
         </div>
         <div className="card-body pt-2 custome-tooltip pb-3">
            <div id="activitys">
               <LineChart title="volume" data={linePriceData} type={ntype} />
            </div>
         </div>
      </div>
   )
}
