import { useEffect, useState } from "react";
import tradeFunction from "../hooks/tradeFunction";
import { useStoreSelector } from "../store/useStore";
import helpers from "../utils/helpers";

interface AppTableProps {
   tableTitle?: string;
   page?: Number;
   quarter?: string;
   headers?: string[];
   data: Array<any>;
   isLoading?: boolean;
   children?: React.ReactNode
}

export default (props: AppTableProps) => {
   let newData = props.data
   // const { getAllTradeEntry } = tradeFunction()
   const [activePage, setActivePage] = useState(1)
   const { tradeEntryData } = useStoreSelector(["tradeEntryData"])
   useEffect(() => {
      // getAllTradeEntry()
   }, [])

   let deal1 = newData[0] || {}
   let deal2 = newData[1] || {}
   let deal3 = newData[2] || {}
   let deal4 = newData[3] || {}
   let deal5 = newData[4] || {}
   return (
      <div className="card mb-0">
         <div className="card-body pAll">
            <div className="table-responsive bbrr">
               <table className="table table-bordered tradeTable mb-0" style={{ borderColor: "#DADADA" }}>
                  <thead>
                     <tr>
                        <th className="dealtop stages" style={{ width: "120px" }}></th>
                        {deal1?.deal &&
                           <th className="dealtop thfont" style={{ width: "120px" }}>{deal1?.deal ? deal1?.deal : "-"}</th>
                        }
                        {deal2?.deal &&
                           <th className="dealtop thfont" style={{ width: "120px" }}>{deal2?.deal ? deal2?.deal : "-"}</th>
                        }
                        {deal3?.deal &&
                           <th className="dealtop thfont" style={{ width: "120px" }}>{deal3?.deal ? deal3?.deal : "-"}</th>
                        }
                        {deal4?.deal &&
                           <th className="dealtop thfont" style={{ width: "120px" }}>{deal4?.deal ? deal4?.deal : "-"}</th>
                        }
                        {deal5?.deal &&
                           <th className="dealtop thfont" style={{ width: "120px" }}>{deal5?.deal ? deal5?.deal : "-"}</th>
                        }
                     </tr>
                  </thead>
                  <tbody>
                     <tr>
                        <td className="stages">ACHAT QTY IN JOS</td>
                        {deal1?.deal &&
                           <td>{deal1?.achat_qty ? helpers.formatMoney(deal1?.achat_qty.toFixed(2)) : "-"}</td>
                        }
                        {deal2?.deal &&
                           <td>{deal2?.achat_qty ? helpers.formatMoney(deal2?.achat_qty.toFixed(2)) : "-"}</td>
                        }
                        {deal3?.deal &&
                           <td>{deal3?.achat_qty ? helpers.formatMoney(deal3?.achat_qty.toFixed(2)) : "-"}</td>
                        }
                        {deal4?.deal &&
                           <td>{deal4?.achat_qty ? helpers.formatMoney(deal4?.achat_qty.toFixed(2)) : "-"}</td>
                        }
                        {deal5?.deal &&
                           <td>{deal5?.achat_qty ? helpers.formatMoney(deal5?.achat_qty.toFixed(2)) : "-"}</td>
                        }
                     </tr>
                     <tr>
                        <td className="stages">NXP NUMBER</td>
                        {deal1?.deal &&
                           <td>{deal1?.nxp_no ? deal1?.nxp_no.toUpperCase() : "-"}</td>
                        }
                        {deal2?.deal &&
                           <td>{deal2?.nxp_no ? deal2?.nxp_no.toUpperCase() : "-"}</td>
                        }
                        {deal3?.deal &&
                           <td>{deal3?.nxp_no ? deal3?.nxp_no.toUpperCase() : "-"}</td>
                        }
                        {deal4?.deal &&
                           <td>{deal4?.nxp_no ? deal4?.nxp_no.toUpperCase() : "-"}</td>
                        }
                        {deal5?.deal &&
                           <td>{deal5?.nxp_no ? deal5?.nxp_no.toUpperCase() : "-"}</td>
                        }
                     </tr>
                     <tr>
                        <td className="stages">APP NUMBER</td>
                        {deal1?.deal &&
                           <td>{deal1?.app_no ? deal1?.app_no.toUpperCase() : "-"}</td>
                        }
                        {deal2?.deal &&
                           <td>{deal2?.app_no ? deal2?.app_no.toUpperCase() : "-"}</td>
                        }
                        {deal3?.deal &&
                           <td>{deal3?.app_no ? deal3?.app_no.toUpperCase() : "-"}</td>
                        }
                        {deal4?.deal &&
                           <td>{deal4?.app_no ? deal4?.app_no.toUpperCase() : "-"}</td>
                        }
                        {deal5?.deal &&
                           <td>{deal5?.app_no ? deal5?.app_no.toUpperCase() : "-"}</td>
                        }
                     </tr>
                     <tr>
                        <td className="stages">INSPECTION DATE VERITEK</td>
                        {deal1?.deal &&
                           <td>{deal1?.insp_date_verit ? deal1?.insp_date_verit : "-"}</td>
                        }
                        {deal2?.deal &&
                           <td>{deal2?.insp_date_verit ? deal2?.insp_date_verit : "-"}</td>
                        }
                        {deal3?.deal &&
                           <td>{deal3?.insp_date_verit ? deal3?.insp_date_verit : "-"}</td>
                        }
                        {deal4?.deal &&
                           <td>{deal4?.insp_date_verit ? deal4?.insp_date_verit : "-"}</td>
                        }
                        {deal5?.deal &&
                           <td>{deal5?.insp_date_verit ? deal5?.insp_date_verit : "-"}</td>
                        }
                     </tr>
                     <tr>
                        <td className="stages">CONTAINER NUMBER</td>
                        {deal1?.deal &&
                           <td>{deal1?.container_no ? deal1?.container_no.toUpperCase() : "-"}</td>
                        }
                        {deal2?.deal &&
                           <td>{deal2?.container_no ? deal2?.container_no.toUpperCase() : "-"}</td>
                        }
                        {deal3?.deal &&
                           <td>{deal3?.container_no ? deal3?.container_no.toUpperCase() : "-"}</td>
                        }
                        {deal4?.deal &&
                           <td>{deal4?.container_no ? deal4?.container_no.toUpperCase() : "-"}</td>
                        }
                        {deal5?.deal &&
                           <td>{deal5?.container_no ? deal5?.container_no.toUpperCase() : "-"}</td>
                        }
                     </tr>
                     <tr>
                        <td className="stages">VERITEK GROSS WEIGHT</td>
                        {deal1?.deal &&
                           <td>{deal1?.veritek_gross_weight ? helpers.formatMoney(deal1?.veritek_gross_weight.toFixed(2)) : "-"}</td>}
                        {deal2?.deal &&
                           <td>{deal2?.veritek_gross_weight ? helpers.formatMoney(deal2?.veritek_gross_weight.toFixed(2)) : "-"}</td>}
                        {deal3?.deal &&
                           <td>{deal3?.veritek_gross_weight ? helpers.formatMoney(deal3?.veritek_gross_weight.toFixed(2)) : "-"}</td>}
                        {deal4?.deal &&
                           <td>{deal4?.veritek_gross_weight ? helpers.formatMoney(deal4?.veritek_gross_weight.toFixed(2)) : "-"}</td>}
                        {deal5?.deal &&
                           <td>{deal5?.veritek_gross_weight ? helpers.formatMoney(deal5?.veritek_gross_weight.toFixed(2)) : "-"}</td>
                        }
                     </tr>
                     <tr>
                        <td className="stages">VERITEK DRY NET WEIGHT</td>
                        {deal1?.deal &&
                           <td>{deal1?.veritek_dry_weight ? helpers.formatMoney(deal1?.veritek_dry_weight.toFixed(2)) : "-"}</td>}
                        {deal2?.deal &&
                           <td>{deal2?.veritek_dry_weight ? helpers.formatMoney(deal2?.veritek_dry_weight.toFixed(2)) : "-"}</td>}
                        {deal3?.deal &&
                           <td>{deal3?.veritek_dry_weight ? helpers.formatMoney(deal3?.veritek_dry_weight.toFixed(2)) : "-"}</td>}
                        {deal4?.deal &&
                           <td>{deal4?.veritek_dry_weight ? helpers.formatMoney(deal4?.veritek_dry_weight.toFixed(2)) : "-"}</td>}
                        {deal5?.deal &&
                           <td>{deal5?.veritek_dry_weight ? helpers.formatMoney(deal5?.veritek_dry_weight.toFixed(2)) : "-"}</td>
                        }
                     </tr>
                     <tr>
                        <td className="stages">1st 80% PAYMENT</td>
                        {deal1?.deal &&
                           <td>{deal1?.first_payment ? deal1?.first_payment.toUpperCase() : "-"}</td>}
                        {deal2?.deal &&
                           <td>{deal2?.first_payment ? deal2?.first_payment.toUpperCase() : "-"}</td>}
                        {deal3?.deal &&
                           <td>{deal3?.first_payment ? deal3?.first_payment.toUpperCase() : "-"}</td>}
                        {deal4?.deal &&
                           <td>{deal4?.first_payment ? deal4?.first_payment.toUpperCase() : "-"}</td>}
                        {deal5?.deal &&
                           <td>{deal5?.first_payment ? deal5?.first_payment.toUpperCase() : "-"}</td>
                        }
                     </tr>
                     <tr>
                        <td className="stages">RCI SN CONTENT</td>
                        {deal1?.deal &&
                           <td>{deal1?.rci_sn_content ? helpers.formatMoney(deal1?.rci_sn_content) + "%" : "-"}</td>}
                        {deal2?.deal &&
                           <td>{deal2?.rci_sn_content ? helpers.formatMoney(deal2?.rci_sn_content) + "%" : "-"}</td>}
                        {deal3?.deal &&
                           <td>{deal3?.rci_sn_content ? helpers.formatMoney(deal3?.rci_sn_content) + "%" : "-"}</td>}
                        {deal4?.deal &&
                           <td>{deal4?.rci_sn_content ? helpers.formatMoney(deal4?.rci_sn_content) + "%" : "-"}</td>}
                        {deal5?.deal &&
                           <td>{deal5?.rci_sn_content ? helpers.formatMoney(deal5?.rci_sn_content) + "%" : "-"}</td>
                        }
                     </tr>
                     <tr>
                        <td className="stages">SHIPPED ON BOARD</td>
                        {deal1?.deal &&
                           <td>{deal1?.shipped_abroad ? deal1?.shipped_abroad : "-"}</td>}
                        {deal2?.deal &&
                           <td>{deal2?.shipped_abroad ? deal2?.shipped_abroad : "-"}</td>}
                        {deal3?.deal &&
                           <td>{deal3?.shipped_abroad ? deal3?.shipped_abroad : "-"}</td>}
                        {deal4?.deal &&
                           <td>{deal4?.shipped_abroad ? deal4?.shipped_abroad : "-"}</td>}
                        {deal5?.deal &&
                           <td>{deal5?.shipped_abroad ? deal5?.shipped_abroad : "-"}</td>
                        }
                     </tr>
                     <tr>
                        <td className="stages">OBL SENT</td>
                        {deal1?.deal &&
                           <td>{deal1?.obl_sent ? deal1?.obl_sent.toUpperCase() : "-"}</td>}
                        {deal2?.deal &&
                           <td>{deal2?.obl_sent ? deal2?.obl_sent.toUpperCase() : "-"}</td>}
                        {deal3?.deal &&
                           <td>{deal3?.obl_sent ? deal3?.obl_sent.toUpperCase() : "-"}</td>}
                        {deal4?.deal &&
                           <td>{deal4?.obl_sent ? deal4?.obl_sent.toUpperCase() : "-"}</td>}
                        {deal5?.deal &&
                           <td>{deal5?.obl_sent ? deal5?.obl_sent.toUpperCase() : "-"}</td>
                        }
                     </tr>
                     <tr>
                        <td className="stages">2ND 95% PAYMENT</td>
                        {deal1?.deal &&
                           <td>{deal1?.second_payment ? deal1?.second_payment.toUpperCase() : "-"}</td>}
                        {deal2?.deal &&
                           <td>{deal2?.second_payment ? deal2?.second_payment.toUpperCase() : "-"}</td>}
                        {deal3?.deal &&
                           <td>{deal3?.second_payment ? deal3?.second_payment.toUpperCase() : "-"}</td>}
                        {deal4?.deal &&
                           <td>{deal4?.second_payment ? deal4?.second_payment.toUpperCase() : "-"}</td>}
                        {deal5?.deal &&
                           <td>{deal5?.second_payment ? deal5?.second_payment.toUpperCase() : "-"}</td>
                        }
                     </tr>
                     <tr>
                        <td className="stages">ESTIMATED DELIVERY</td>
                        {deal1?.deal &&
                           <td>{deal1?.estimated_delivery ? deal1?.estimated_delivery.toUpperCase() : "-"}</td>}
                        {deal2?.deal &&
                           <td>{deal2?.estimated_delivery ? deal2?.estimated_delivery.toUpperCase() : "-"}</td>}
                        {deal3?.deal &&
                           <td>{deal3?.estimated_delivery ? deal3?.estimated_delivery.toUpperCase() : "-"}</td>}
                        {deal4?.deal &&
                           <td>{deal4?.estimated_delivery ? deal4?.estimated_delivery.toUpperCase() : "-"}</td>}
                        {deal5?.deal &&
                           <td>{deal5?.estimated_delivery ? deal5?.estimated_delivery.toUpperCase() : "-"}</td>
                        }
                     </tr>
                     <tr>
                        <td className="stages">AHK DRY NET WEIGHT</td>
                        {deal1?.deal &&
                           <td>{deal1?.ahk_dry_net ? helpers.formatMoney(deal1?.ahk_dry_net.toFixed(2)) : "-"}</td>}
                        {deal2?.deal &&
                           <td>{deal2?.ahk_dry_net ? helpers.formatMoney(deal2?.ahk_dry_net.toFixed(2)) : "-"}</td>}
                        {deal3?.deal &&
                           <td>{deal3?.ahk_dry_net ? helpers.formatMoney(deal3?.ahk_dry_net.toFixed(2)) : "-"}</td>}
                        {deal4?.deal &&
                           <td>{deal4?.ahk_dry_net ? helpers.formatMoney(deal4?.ahk_dry_net.toFixed(2)) : "-"}</td>}
                        {deal5?.deal &&
                           <td>{deal5?.ahk_dry_net ? helpers.formatMoney(deal5?.ahk_dry_net.toFixed(2)) : "-"}</td>
                        }
                     </tr>
                     <tr>
                        <td className="stages">AHK SAMPLE ARRIVAL DATE</td>
                        {deal1?.deal &&
                           <td>{deal1?.ahk_sample ? deal1?.ahk_sample : "-"}</td>}
                        {deal2?.deal &&
                           <td>{deal2?.ahk_sample ? deal2?.ahk_sample : "-"}</td>}
                        {deal3?.deal &&
                           <td>{deal3?.ahk_sample ? deal3?.ahk_sample : "-"}</td>}
                        {deal4?.deal &&
                           <td>{deal4?.ahk_sample ? deal4?.ahk_sample : "-"}</td>}
                        {deal5?.deal &&
                           <td>{deal5?.ahk_sample ? deal5?.ahk_sample : "-"}</td>
                        }
                     </tr>
                     <tr>
                        <td className="stages">FINAL ASI RESULT</td>
                        {deal1?.deal &&
                           <td>{deal1?.asi_final_result ? helpers.formatMoney(deal1?.asi_final_result) + "%" : "-"}</td>
                        }
                        {deal2?.deal &&
                           <td>{deal2?.asi_final_result ? helpers.formatMoney(deal2?.asi_final_result) + "%" : "-"}</td>
                        }
                        {deal3?.deal &&
                           <td>{deal3?.asi_final_result ? helpers.formatMoney(deal3?.asi_final_result) + "%" : "-"}</td>
                        }
                        {deal4?.deal &&
                           <td>{deal4?.asi_final_result ? helpers.formatMoney(deal4?.asi_final_result) + "%" : "-"}</td>
                        }
                        {deal5?.deal &&
                           <td>{deal5?.asi_final_result ? helpers.formatMoney(deal5?.asi_final_result) + "%" : "-"}</td>
                        }
                     </tr>
                     <tr>
                        <td className="stages">3RD PAYMENT - Final Payment</td>
                        {deal1?.deal &&
                           <td className={deal1?.last_payment && deal1?.last_payment == 1 ? "paid" : ""}>{deal1?.last_payment && deal1?.last_payment == 1 ? "Paid" : "-"}</td>}
                        {deal2?.deal &&
                           <td className={deal2?.last_payment && deal2?.last_payment == 1 ? "paid" : ""}>{deal2?.last_payment && deal2?.last_payment == 1 ? "Paid" : "-"}</td>}
                        {deal3?.deal &&
                           <td className={deal3?.last_payment && deal3?.last_payment == 1 ? "paid" : ""}>{deal3?.last_payment && deal3?.last_payment == 1 ? "Paid" : "-"}</td>}
                        {deal4?.deal &&
                           <td className={deal4?.last_payment && deal4?.last_payment == 1 ? "paid" : ""}>{deal4?.last_payment && deal4?.last_payment == 1 ? "Paid" : "-"}</td>
                        }
                        {deal5?.deal &&
                           <td className={deal5?.last_payment && deal5?.last_payment == 1 ? "paid" : ""}>{deal5?.last_payment && deal5?.last_payment == 1 ? "Paid" : "-"}</td>
                        }

                     </tr>
                  </tbody>
               </table>
            </div>
         </div>
      </div>
   )
}