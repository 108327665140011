import * as React from 'react';
import Chart from "react-apexcharts";
import { ObjectPayload } from '../typings/general';

export type DataState = {
   data?: ObjectPayload;
}
export default (props: DataState) => {

   let arrayData: any = props.data ? props.data : []
   let Gold = []
   let Tin = []
   let Monazite = []
   let Niobium = []
   let Lithium = []
   let Tantalite = []
   let Zircon = []
   let Germstones = []
   let Locations = []
   for (let i = 0; i < arrayData.length; i++) {
      Gold.push(arrayData[i].gold)
      Tin.push(arrayData[i].tin)
      Monazite.push(arrayData[i].monazitesands)
      Niobium.push(arrayData[i].niobium)
      Tantalite.push(arrayData[i].tantalite)
      Zircon.push(arrayData[i].zircon)
      Germstones.push(arrayData[i].gemstones)
      Lithium.push(arrayData[i].lithium)
      Locations.push(arrayData[i].location_data.name)
   }

   const series = [
      {
         name: "Gold",

         data: Gold,
         // data: [1.3, 1.3, 1.2, 1.3, 1.4, 2],

      },
      {
         name: "Tin",

         data: Tin,
         // data: [1.1, 1.2, 1.1, 1.2, 1.6, 1.3],
      },
      {
         name: "Monazite sands",

         data: Monazite,
         // data: [1.3, 1.1, 1, 1.3, 1.4, 1.2],
      },
      {
         name: "Niobium",

         data: Niobium
         // data: [1.1, 1.2, 1.1, 1.2, 1.3, 1.1]
      },
      {
         name: "Lithium",

         data: Lithium
         // data: [1.3, 1.4, 1.2, 1.3, 1.4, 1.2]
      },
      {
         name: "Tantalite",

         data: Tantalite
         // data: [1.1, 1.2, 1.1, 1.2, 1.4, 1.1]
      },
      {
         name: "Zircon",

         data: Zircon
         // data: [1.3, 1.2, 1.1, 1.3, 1.4, 1.2]
      },
      {
         name: "Germstones",

         data: Germstones
         // data: [1.1, 1.2, 1.3, 1.1, 1.2, 1.4]
      },
   ];

   const options = {

      chart: {
         height: 350,
         overflowX: "auto",
      },

      colors: ['#F0A042', '#84B0E8', '#34B49D', '#4579C8', '#B083F9', '#D56FA6', '#781349', '#B0374D'],

      plotOptions: {
         bar: {
            horizontal: false,
            columnWidth: "80%",
            borderRadius: 2,
            barHeight: '50%'
         },

      },
      legend: {
         position: "bottom" as const,
         horizontalAlign: "right" as const,
         offsetY: -40
      },

      dataLabels: {
         enabled: false
      },
      // scales: {
      //    y: {
      //       beginAtZero: true,
      //       grace: '15%',
      //    }
      // },

      stroke: {
         show: true,
         width: 6,
         colors: ["transparent"]
      },
      grid: {
         show: true,
         xaxis: {
            lines: {
               show: true
            }
         },
         yaxis: {
            lines: {
               show: true
            }
         },
         padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
         },
      },

      xaxis: {
         categories: Locations,
         // categories: ["Osun", "Nassarawa", "Location 3", "Location 4", "Location 5", "Location 6"],

         labels: {
            style: {
               fontSize: "11px",
               cssClass: ".apexcharts-margin"
            },
            hideOverlappingLabels: false,
            show: true,
            rotate: 0,
            rotateAlways: false,
            minHeight: 100,
            maxHeight: 2000,

         },
         lines: {
            show: false  //or just here to disable only x axis grids
         },
         axisBorder: {
            show: false
         },
         axisTicks: {
            show: false
         },
      },

      yaxis: {
         title: {
            text: 'Weight (g)',
            style: {
               fontWeight: 500
            }
         },

      },
      fill: {
         opacity: 1
      },

   };

   return (
      <div>
         <Chart options={options} series={series} type="bar" height={350} />
      </div>
   );
}
