
export interface paginationContent {
   activePage?: number;
   itemPerPage?: number;
   totalPage?: number;
   onPress?: (e: number) => void;
}

export default ({ activePage = 1, itemPerPage = 50, totalPage = 0, onPress }: paginationContent) => {
   let pageCount = Math.ceil((totalPage / itemPerPage) || 0)
   return (
      <div className="card-body pt-4">
         {totalPage === 0 ? '' :
            // <ul className="pagination pagination-gutter pagination-primary no-bg pg00">
            <ul className="pagination pagination-gutter pagination-primary no-bg">
               <li className="showCursor page-item page-indicator pselect2" style={{ marginRight: '20px' }} onClick={() => onPress && onPress(1)}>Previous</li>
               {Array(pageCount).fill(0).map((item: any, i: any) => (

                  <li onClick={() => onPress && onPress(i + 1)} style={{ marginRight: "30px" }} className={activePage === (i + 1) ? `activeNav showCursor` : "activeNav2 showCursor"}>{i + 1}</li>
               ))}
               {pageCount > 1 &&
                  <li style={{ marginLeft: '20px', }} className="showCursor page-item page-indicator pselect2" onClick={() => onPress && onPress(pageCount)}>Last Page <i className="fas fa-arrow-right" style={{ marginLeft: "7px" }}></i></li>}
            </ul>}

      </div>
   )
}