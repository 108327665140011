import moment from 'moment';
import * as React from 'react';
import Chart from "react-apexcharts";
import { Zoom } from 'react-toastify';
import helpers from '../utils/helpers';

interface BarProps {
   title?: string,
   data: Array<any>,
   type?: string
}
export default (props: BarProps) => {
   let dData = props.data ? props.data : []
   let ntype = props.type ? props.type : "month"
   let cat = [];
   // let dailyCat = [
   //    { '01': 0 }, { '02': 0 }, { '03': 0 }, { '04': 0 }, { '05': 0 }, { '06': 0 }, { '07': 0 }, { '08': 0 },
   //    { '09': 0 }, { '10': 0 }, { '11': 0 }, { '12': 0 }, { '13': 0 }, { '14': 0 }, { '15': 0 }, { '16': 0 },
   //    { '17': 0 }, { '18': 0 }, { '19': 0 }, { '20': 0 }, { '21': 0 }, { '22': 0 }, { '23': 0 }, { '24': 0 },
   //    { '25': 0 }, { '26': 0 }, { '27': 0 }, { '28': 0 }, { '29': 0 }, { '30': 0 },
   // ];
   let monthCat = {
      "01": 0, "02": 0, "03": 0, "04": 0, "05": 0, "06": 0, "07": 0, "08": 0,
      "09": 0, "10": 0, "11": 0, "12": 0
   }

   let pmsData = []
   let dpkData = []
   let agoData = []
   let newDate = new Date()
   let lastDate = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 0)

   if (ntype === "month") {

      // let objKey = Object.keys(dailyCat)
      for (let arr = 0; arr < lastDate.getDate(); arr++) {
         let sumer = dData.findIndex(e => e?._id.replace(/^\d{4}-\d{2}-/, '') === String(arr).padStart(2, "0"))
         cat.push(arr + 1)
         if (sumer > -1) {
            let pp: any = parseFloat(dData[sumer]?.pms_price) > 0 ? (parseFloat(dData[sumer]?.pms_price) / 1000000).toFixed(2) : 0
            let aa: any = parseFloat(dData[sumer]?.ago_price) > 0 ? (parseFloat(dData[sumer]?.ago_price) / 1000000).toFixed(2) : 0
            let dd: any = parseFloat(dData[sumer]?.dpk_price) > 0 ? (parseFloat(dData[sumer]?.dpk_price) / 1000000).toFixed(2) : 0
            pmsData.push(pp)
            agoData.push(aa)
            dpkData.push(dd)
         } else {
            pmsData.push(0)
            agoData.push(0)
            dpkData.push(0)
         }
      }
   } else {
      // let arr = dData[i]._id.split("-")[1]
      let objKey = Object.keys(monthCat).sort()
      for (let arr of objKey) {
         let sumer = dData.findIndex(e => e?._id.replace(/^\d{4}-/, '') === String(arr).padStart(2, "0"))
         cat.push(moment('2024-' + arr).format(" MMM"))
         if (sumer > -1) {
            let pp: any = parseFloat(dData[sumer]?.pms_price) > 0 ? (parseFloat(dData[sumer]?.pms_price) / 1000000).toFixed(2) : 0
            let aa: any = parseFloat(dData[sumer]?.ago_price) > 0 ? (parseFloat(dData[sumer]?.ago_price) / 1000000).toFixed(2) : 0
            let dd: any = parseFloat(dData[sumer]?.dpk_price) > 0 ? (parseFloat(dData[sumer]?.dpk_price) / 1000000).toFixed(2) : 0
            pmsData.push(pp)
            agoData.push(aa)
            dpkData.push(dd)
         } else {
            pmsData.push(0)
            agoData.push(0)
            dpkData.push(0)
         }
      }
   }

   // for (let i = 0; i < dData.length; i++) {
   //    if (ntype === "month") {
   //       let arr = dData[i]._id.split("-")[2]

   //       cat.push(arr)
   //    } else {
   //       let arr = dData[i]._id.split("-")[1]
   //       let objKey = Object.keys(monthCat)
   //       if (arr in monthCat) {

   //          cat.push(moment(dData[i]._id).format("MMM YYYY"))
   //       }
   //    }
   //    pmsData.push(parseFloat(dData[i].pms_price) > 0 ? (parseFloat(dData[i].pms_price) / 1000000) : 0)
   //    agoData.push(parseFloat(dData[i].ago_price) > 0 ? (parseFloat(dData[i].ago_price) / 1000000) : 0)
   //    dpkData.push(parseFloat(dData[i].dpk_price) > 0 ? (parseFloat(dData[i].dpk_price) / 1000000) : 0)
   // }

   const options = {
      xaxis: {
         title: {
            text: ntype === "month" ? 'Last 30 Days' : "Last 1 Year",
            style: {
               fontWeight: 500
            }
         },
         categories: cat,
         axisBorder: {
            show: false
         },
         axisTicks: {
            show: false
         },
      },
      plotOptions: {
         bar: {
            columnWidth: '45%',
            distributed: true,

         },

      },
      stroke: {
         width: 1
      },
      legend: {
         show: true,
         position: "bottom" as const,
         horizontalAlign: "left" as const
      },
      Chart: {
         toolbar: {
            show: false
         },
         Zoom: {
            enabled: false
         },
         animations: {
            enabled: true,
            easing: 'linear',
            dynamicAnimation: {
               speed: 1000
            }
         },
      },
      dataLabels: {
         enabled: false
      },
      style: {
         colors: "#eee",
         fontSize: '12px'
      },
      colors: ['#2E7FC1', '#D47743', '#4BB95E'],

      yaxis: {
         title: {
            text: 'MILLION (NGN)',
            style: {
               fontWeight: 500
            }
         },
      },
      tooltip: {
         y: {
            formatter: function (val: any) {
               return "NGN " + helpers.formatMoney(val) + " million"
            }
         }
      },
      grid: {
         show: true,
         xaxis: {
            lines: {
               show: true
            }
         },
         yaxis: {
            lines: {
               show: true
            }
         },
         padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
         },
      },

   };
   const series = [
      {
         name: "PMS",
         data: pmsData
      },
      {
         name: "DPK",
         data: dpkData
      },
      {
         name: "AGO",
         data: agoData
      },
   ];

   return (
      <div>
         <Chart options={options} series={series} type="line" height={350} />
      </div>
   );
}
