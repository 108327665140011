import React, { useEffect, useState } from 'react'
import { Properties as CSSProperties } from 'csstype'
import { ObjectPayload } from '../typings/general';
import hrFunction from '../hooks/hrFunction';
import { useStoreSelector } from '../store/useStore';
import InputTable from './InputTable';
import helpers from '../utils/helpers';
import EmptyTable from './EmptyTable';
import DeleteModal from './DeleteModal';
import priceFunction from '../hooks/priceFunction';
import LicenseEdit from './LicenseEdit';
const deleteBtn: string = require('../assets/icons/delete.svg').default
const editBtn: string = require('../assets/icons/edit.svg').default

export type DataState = {
   data?: ObjectPayload;
   onPress?: Function;
   onSubmit?: Function;
   containerStyle?: CSSProperties;
}
export default (props: DataState) => {
   const { getLicense, deleteLicense } = priceFunction()
   const { licenseData } = useStoreSelector(["licenseData"])
   const [editData2, setEditData2] = useState({ data: {}, status: false })
   const [deleteModal2, setDeleteModal2] = useState({ data: {}, status: false })
   useEffect(() => {
      getLicense()
   }, [])
   return (
      <div style={{
         position: 'fixed', left: 0, top: 0,
         display: 'flex',
         justifyContent: 'center',
         alignItems: 'center',
         backgroundColor: 'rgba(0, 0, 0, 0.3)',
         backgroundSize: "cover",
         width: '100%',
         height: '100vh',
         zIndex: 999,
      }}>
         <div style={{ borderRadius: '20px', padding: '20px', width: '900px', ...props.containerStyle }}>
            <div className='bg-white' style={{ borderRadius: "20px" }}>
               <div className="filterForm w-100">
                  <div className='popupClass1'>
                     <div className="modal-content">
                        <div className="modal-body modalBody" style={{ marginTop: "0" }}>
                           <div className="mb-4 d-block">
                              <div className="d-flex align-items-center justify-content-between flex-wrap">
                                 <div>
                                    {/* <h3 className="modal-title fs-5 inviteColor" id="exampleModalLabel">Company</h3> */}
                                 </div>
                                 <i onClick={() => props.onPress && props.onPress()} style={{ fontSize: '20px', marginRight: '35px' }} className="fa fa-times m-color-red showCursor" aria-hidden="true"></i>
                              </div>
                           </div>
                           <div className="mb-3 d-block">
                              <div className="col-12 mbb-40" style={{ maxHeight: "600px" }}>
                                 {licenseData && licenseData.length > 0 ?
                                    <InputTable data={licenseData || []} headers={["Asset Type", "Company", "Location", "Expiration Date", "Action"]} tableTitle="">
                                       {(licenseData || []).map((item: any, i: any) => (
                                          <tr>
                                             <th>{item?.commodity_id}</th>
                                             <th>{item?.company_data?.name}</th>
                                             <th>{item?.location}</th>
                                             <td>{new Date(item.expiration_date).toDateString()}</td>
                                             <td>
                                                <div className="d-flex jspace">
                                                   <a href="#" onClick={() => setEditData2({ status: true, data: item })} className="btn btn-xs sharp editText"><img src={editBtn} /></a>
                                                   <a href="#" onClick={() => setDeleteModal2({ status: true, data: { auth_id: item.license_id } })} className="btn btn-xs sharp"><img className='delete-img' src={deleteBtn} /></a>
                                                </div>
                                             </td>
                                          </tr>))}
                                    </InputTable>
                                    : <EmptyTable />}
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         {editData2.status &&
            <LicenseEdit data={editData2.data} onPress={() => setEditData2({ status: false, data: {} })} />
         }
         {deleteModal2.status &&
            <DeleteModal data={deleteModal2.data} onSubmit={deleteLicense} onPress={() => setDeleteModal2({ status: false, data: {} })} />
         }
      </div>
   )
}