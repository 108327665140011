import React, { useState } from 'react'
import { Properties as CSSProperties } from 'csstype'
import { ObjectPayload } from '../typings/general';
import userAdminFunction from '../hooks/userAdminFunction';
import { useStoreSelector } from '../store/useStore';

export type DataState = {
   data?: ObjectPayload;
   onPress?: Function;
   containerStyle?: CSSProperties;
}
export default (props: DataState) => {
   const { updateAccountPassword } = userAdminFunction()
   const [loginData, setLoginData] = useState({ old_password: "", new_password: "", confirm_password: "" })
   const [passwordShown, setPasswordShown] = useState(false);
   const togglePasswordVisiblity = () => {
      setPasswordShown(passwordShown ? false : true);
   };
   // console.log(loginData)
   return (
      <div style={{
         position: 'fixed', left: 0, top: 0,
         display: 'flex',
         justifyContent: 'center',
         alignItems: 'center',
         backgroundSize: "cover",
         backgroundColor: 'rgba(0, 0, 0, 0.3)',
         width: '100%', height: '100vh', zIndex: 999,
      }}>
         <div style={{ borderRadius: '20px', padding: '20px', width: '500px', ...props.containerStyle }}>
            <div className='bg-white' style={{ borderRadius: "20px" }}>
               <div className="filterForm w-100">
                  <div className='popupClass1'>
                     <div className="modal-content">
                        <div className="modal-body" style={{ marginTop: "0" }}>
                           <div className="mb-3 d-block">
                              <div className="d-flex align-items-center justify-content-between flex-wrap">
                                 <h3 className="modal-title fs-5 inviteColor" id="exampleModalLabel">Change Password</h3>
                                 <i onClick={() => props.onPress && props.onPress()} style={{ fontSize: '20px', marginRight: '-5px' }} className="fa fa-times m-color-red showCursor" aria-hidden="true"></i>
                              </div>
                           </div>
                           <div className="mb-3 d-block">
                              <label className="form-label d-block mb-2">Old Password</label>
                              <input onChange={(e) => setLoginData(edata => ({ ...edata, old_password: e.target.value.trim() }))} type={passwordShown ? "text" : "password"} className="form-control w-100 mb-0" />
                           </div>
                           <div className="mb-3 d-block">
                              <label className="form-label d-block mb-2">New Password</label>
                              <input onChange={(e) => setLoginData(edata => ({ ...edata, new_password: e.target.value.trim() }))} type={passwordShown ? "text" : "password"} className="form-control w-100 mb-0" />
                           </div>
                           <div className="mb-3 d-block">
                              <label className="form-label d-block mb-2">Confirm Password</label>
                              <input onChange={(e) => setLoginData(edata => ({ ...edata, confirm_password: e.target.value.trim() }))} type={passwordShown ? "text" : "password"} className="form-control w-100 mb-0" />
                           </div>
                           <div className="mb-3 d-block">
                              <p className="form-label d-block mb-2 pptext showCursor" onClick={togglePasswordVisiblity}>Show password</p>
                           </div>
                        </div>
                        <div className="modal-footer">
                           <button type="button" className="btn btn-lg light primaryBG2 ww-48" onClick={() => props.onPress && props.onPress()}>Cancel</button>
                           <button type="button" className="btn btn-lg primaryBG ww-48" onClick={() => updateAccountPassword(loginData.old_password, loginData.new_password, loginData.confirm_password, props.onPress)}>Update</button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

      </div>
   )
}