import HumanTable from "./HumanTable"
interface AppTableProps {
   tableTitle?: string;
   quarter?: string;
   headers?: string[];
   data?: Array<any>;
   isLoading?: boolean;
   children?: React.ReactNode
}
export default (props: AppTableProps) => {
   let q1Data: any
   let q2Data: any
   let q3Data: any
   let q4Data: any
   let data1 = props?.data ? props?.data : []
   if (data1 && data1.length > 0) {
      for (let i = 0; i < data1.length; i++) {
         if (data1[i].quater == "Q1") {
            q1Data = data1[i]
         }
         if (data1[i].quater == "Q2") {
            q2Data = data1[i]
         }
         if (data1[i].quater == "Q3") {
            q3Data = data1[i]
         }
         if (data1[i].quater == "Q4") {
            q4Data = data1[i]
         }
      }
   }
   return (
      <div className="card h-auto">
         <div className="row">
            <div className="col-lg-3 plr0">
               <HumanTable tableTitle={props.tableTitle} quarter="Q1" data={q1Data} />
               {/* <HumanTable tableTitle={props.tableTitle} quarter="Q1" addClass="btopleftradius" data={q1Data} /> */}
            </div>
            <div className="col-lg-3 plr0">
               <HumanTable quarter="Q2" data={q2Data} />
            </div>
            <div className="col-lg-3 plr0">
               <HumanTable quarter="Q3" data={q3Data} />
            </div>
            <div className="col-lg-3 plr0">
               <HumanTable quarter="Q4" data={q4Data} />
               {/* <HumanTable quarter="Q4" addClass="btoprighttradius" data={q4Data} /> */}
            </div>
         </div>
      </div>
   )
}