import { useNavigate } from "react-router-dom";
import { AppAlertData } from "../store/storeslice/alert-slice";
import { useStoreSelector } from "../store/useStore";
import APIendPoint from "../utils/apiEndPoints";
import helpers from "../utils/helpers";

export default () => {
   const navigate = useNavigate();
   const { setAlert, setIsLoading, setPriceData, setMineCom, setMarketerData, setPmsData, setAgoData, setDpkData, setLpgData, setLicenseData, setCommodityPriceCount } = useStoreSelector([
      "setAlert",
      "setIsLoading",
      "setPriceData",
      "setMarketerData",
      "setPmsData",
      "setAgoData",
      "setDpkData",
      "setLpgData",
      "setLicenseData",
      "setCommodityPriceCount",
      "setMineCom"
   ]);
   const createPrice = async (
      data: {
         type_id: string;
         type: string;
         amount?: string;
         currency?: string;
      },
      onPress?: Function
   ) => {

      if (!data?.type_id) {
         return helpers.showToast("error", "Type is required");
      }
      helpers.showToast("loading", "Loading");
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/commodity`,
            method: "POST",
            json: {
               type_id: data.type_id,
               type: data.type,
               currency: data?.currency,
               amount: data?.amount,
            },
         })
         .catch((e) => e);
      //  setIsLoading(false);
      if (sendRequest && sendRequest.status_code !== 200) {
         return helpers.showToast("error", sendRequest.data.message);
      }
      getPrice()
      helpers.showToast("success", "Entry was created successful");
      onPress && onPress();
   };
   const getPrice = async (
      type = "",
      startDate = "",
      endDate = "",
      q = "",
      page = 1,
      perPage = 50
   ) => {
      setIsLoading(true)
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/commodity?page=${page}&start_date=${startDate}&end_date=${endDate}&q=${q}&type=${type}&item_per_page=${perPage}`,
            method: "GET",
         })
         .catch((e) => e);
      setIsLoading(false)
      if (sendRequest && sendRequest.status_code !== 200) {
         if (sendRequest) {
            return helpers.showToast("error", sendRequest.data?.message);
         } else {
            return helpers.showToast("error", "Please check your network and try again");
         }
      }
      // setIsLoading(false)
      setPriceData(sendRequest.data.data);
   };
   const getCountPrice = async (
      type = "",
      startDate = "",
      endDate = "",
      q = "",
      page = 1,
      perPage = 50
   ) => {
      setIsLoading(true)
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/commodity?page=${page}&start_date=${startDate}&end_date=${endDate}&q=${q}&type=${type}&item_per_page=${perPage}&component=count`,
            method: "GET",
         })
         .catch((e) => e);
      setIsLoading(false)
      if (sendRequest && sendRequest.status_code !== 200) {
         if (sendRequest) {
            return helpers.showToast("error", sendRequest.data?.message);
         } else {
            return helpers.showToast("error", "Please check your network and try again");
         }
      }
      // setIsLoading(false)
      setCommodityPriceCount(sendRequest.data.data);
   };
   const updatePrice = async (
      data: {
         type_id: string;
         type: string;
         amount?: string;
         currency?: string;
      },
      authID: string,
      onPress?: Function
   ) => {

      helpers.showToast("loading", "Loading");
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/commodity/${authID}`,
            method: "PUT",
            json: {
               type_id: data.type_id,
               type: data.type,
               currency: data?.currency,
               amount: data?.amount,
            },
         })
         .catch((e) => e);
      //  setIsLoading(false);
      if (sendRequest && sendRequest.status_code !== 200) {
         return helpers.showErrorMessage(sendRequest);
      }
      getPrice()
      helpers.showToast("success", "Entry was updated successful");
      onPress && onPress();
   };
   const deletePrice = async (id: string, onPress?: Function) => {
      helpers.showToast("loading", "Loading");
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/commodity/${id}`,
            method: "DELETE",
         })
         .catch((e) => e);
      if (sendRequest && sendRequest.status_code !== 200) {
         return helpers.showErrorMessage(sendRequest);
      }
      getPrice();
      helpers.showToast("success", "Entry was deleted successful");
      onPress && onPress();
   };


   const createMarketer = async (
      name: string,
      type: string,
      onPress?: Function
   ) => {

      if (!name) {
         return helpers.showToast("error", "Name is required");
      }
      if (!type) {
         return helpers.showToast("error", "Type is required");
      }
      helpers.showToast("loading", "Loading");
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/commodity/marketers`,
            method: "POST",
            json: {
               type: type,
               name: name,
            },
         })
         .catch((e) => e);
      //  setIsLoading(false);
      if (sendRequest && sendRequest.status_code !== 200) {
         return helpers.showToast("error", sendRequest.data.message);
      }
      getMarketers()
      helpers.showToast("success", "Entry was created successful");
      onPress && onPress();
   };
   const getMarketers = async (
      type = "",
      q = "",
      page = 1,
   ) => {
      // setIsLoading(true)
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/commodity/marketers?page=${page}&q=${q}&type=${type}`,
            method: "GET",
         })
         .catch((e) => e);
      // setIsLoading(false)
      if (sendRequest && sendRequest.status_code !== 200) {
         if (sendRequest) {
            return helpers.showToast("error", sendRequest.data?.message);
         } else {
            return helpers.showToast("error", "Please check your network and try again");
         }
      }
      // setIsLoading(false)
      setMarketerData(sendRequest.data.data);
   };
   const updateMarketer = async (
      name: string,
      type: string,
      authID: string,
      onPress?: Function
   ) => {

      helpers.showToast("loading", "Loading");
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/commodity/marketers/${authID}`,
            method: "PUT",
            json: {
               type: type,
               name: name,
            },
         })
         .catch((e) => e);
      //  setIsLoading(false);
      if (sendRequest && sendRequest.status_code !== 200) {
         return helpers.showErrorMessage(sendRequest);
      }
      getMarketers()
      helpers.showToast("success", "Entry was updated successful");
      onPress && onPress();
   };
   const deleteMarketer = async (id: string, onPress?: Function) => {
      helpers.showToast("loading", "Loading");
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/commodity/marketers/${id}`,
            method: "DELETE",
         })
         .catch((e) => e);
      if (sendRequest && sendRequest.status_code !== 200) {
         return helpers.showErrorMessage(sendRequest);
      }
      getMarketers();
      helpers.showToast("success", "Entry was deleted successful");
      onPress && onPress();
   };

   const getMarketerPms = async (
      type = "pms",
      q = "",
      page = 1,
   ) => {
      // setIsLoading(true)
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/commodity/marketers?page=${page}&q=${q}&type=${type}`,
            method: "GET",
         })
         .catch((e) => e);
      // setIsLoading(false)
      if (sendRequest && sendRequest.status_code !== 200) {
         if (sendRequest) {
            return helpers.showToast("error", sendRequest.data?.message);
         } else {
            return helpers.showToast("error", "Please check your network and try again");
         }
      }
      // setIsLoading(false)
      setPmsData(sendRequest.data.data);
   };
   const getMarketerAgo = async (
      type = "ago",
      q = "",
      page = 1,
   ) => {
      // setIsLoading(true)
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/commodity/marketers?page=${page}&q=${q}&type=${type}`,
            method: "GET",
         })
         .catch((e) => e);
      // setIsLoading(false)
      if (sendRequest && sendRequest.status_code !== 200) {
         if (sendRequest) {
            return helpers.showToast("error", sendRequest.data?.message);
         } else {
            return helpers.showToast("error", "Please check your network and try again");
         }
      }
      // setIsLoading(false)
      setAgoData(sendRequest.data.data);
   };
   const getMarketerLpg = async (
      type = "lpg",
      q = "",
      page = 1,
   ) => {
      // setIsLoading(true)
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/commodity/marketers?page=${page}&q=${q}&type=${type}`,
            method: "GET",
         })
         .catch((e) => e);
      // setIsLoading(false)
      if (sendRequest && sendRequest.status_code !== 200) {
         if (sendRequest) {
            return helpers.showToast("error", sendRequest.data?.message);
         } else {
            return helpers.showToast("error", "Please check your network and try again");
         }
      }
      // setIsLoading(false)
      setLpgData(sendRequest.data.data);
   };
   const getMarketerDpk = async (
      type = "dpk",
      q = "",
      page = 1,
   ) => {
      // setIsLoading(true)
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/commodity/marketers?page=${page}&q=${q}&type=${type}`,
            method: "GET",
         })
         .catch((e) => e);
      // setIsLoading(false)
      if (sendRequest && sendRequest.status_code !== 200) {
         if (sendRequest) {
            return helpers.showToast("error", sendRequest.data?.message);
         } else {
            return helpers.showToast("error", "Please check your network and try again");
         }
      }
      // setIsLoading(false)
      setDpkData(sendRequest.data.data);
   };


   const createLicense = async (
      data: {
         location?: string,
         type?: string,
         number?: string,
         cadastral?: string,
         company_id?: string,
         commodity_id?: string,
         expiration_date?: string,
      },
      onPress?: Function
   ) => {

      if (!data?.location) {
         return helpers.showToast("error", "location is required");
      }
      if (!data?.type) {
         return helpers.showToast("error", "Type is required");
      }
      helpers.showToast("loading", "Loading");
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/mining/license`,
            method: "POST",
            json: {
               type: data?.type,
               number: data?.number,
               cadastral: data?.cadastral,
               expiration_date: data?.expiration_date,
               company_id: data?.company_id,
               commodity_id: data?.commodity_id,
               location: data?.location,
            },
         })
         .catch((e) => e);
      //  setIsLoading(false);
      if (sendRequest && sendRequest.status_code !== 200) {
         return helpers.showToast("error", sendRequest.data.message);
      }
      getLicense()
      helpers.showToast("success", "Entry was created successful");
      onPress && onPress();
   };
   const getLicense = async (
      type = "",
      q = "",
      page = 1,
   ) => {
      // setIsLoading(true)
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/mining/license?page=${page}&q=${q}&type=${type}`,
            method: "GET",
         })
         .catch((e) => e);
      // setIsLoading(false)
      if (sendRequest && sendRequest.status_code !== 200) {
         if (sendRequest) {
            return helpers.showToast("error", sendRequest.data?.message);
         } else {
            return helpers.showToast("error", "Please check your network and try again");
         }
      }
      // setIsLoading(false)
      setLicenseData(sendRequest.data.data);
   };
   const updateLicense = async (
      data: {
         location?: string,
         type?: string,
         number?: string,
         cadastral?: string,
         company_id?: string,
         commodity_id?: string,
         expiration_date?: string,
      },
      authID: string,
      onPress?: Function
   ) => {

      helpers.showToast("loading", "Loading");
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/mining/license/${authID}`,
            method: "PUT",
            json: {
               type: data?.type,
               number: data?.number,
               cadastral: data?.cadastral,
               expiration_date: data?.expiration_date,
               company_id: data?.company_id,
               commodity_id: data?.commodity_id,
               location: data?.location,
            },
         })
         .catch((e) => e);
      //  setIsLoading(false);
      if (sendRequest && sendRequest.status_code !== 200) {
         return helpers.showErrorMessage(sendRequest);
      }
      getLicense()
      helpers.showToast("success", "Entry was updated successful");
      onPress && onPress();
   };
   const deleteLicense = async (id: string, onPress?: Function) => {
      helpers.showToast("loading", "Loading");
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/mining/license/${id}`,
            method: "DELETE",
         })
         .catch((e) => e);
      if (sendRequest && sendRequest.status_code !== 200) {
         return helpers.showErrorMessage(sendRequest);
      }
      getLicense();
      helpers.showToast("success", "Entry was deleted successful");
      onPress && onPress();
   };

   const getMineCompany = async () => {
      //  helpers.showToast("loading", "Loading");
      // setIsLoading(true)
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/mining/company`,
            method: "GET",
         })
         .catch((e) => e);
      if (sendRequest && sendRequest.status_code !== 200) {
         // return helpers.showErrorMessage(sendRequest);
         if (sendRequest) {
            return helpers.showToast("error", sendRequest.data?.message);
         } else {
            return helpers.showToast("error", "Please check your network and try again");
         }
      }
      // setIsLoading(false)
      setMineCom(sendRequest.data.data);
   };


   const newMineCompany = async (title: string, onPress?: Function) => {
      //  setIsLoading(true);

      if (!title) {
         return helpers.showToast("error", "Company name is required");
      }
      helpers.showToast("loading", "Loading");
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/mining/company`,
            method: "POST",
            json: { name: title },
         })
         .catch((e) => e);
      //  setIsLoading(false);
      if (sendRequest && sendRequest.status_code !== 200) {
         // return helpers.showErrorMessage(sendRequest);
         if (sendRequest) {
            return helpers.showToast("error", sendRequest.data?.message);
         } else {
            return helpers.showToast("error", "Please check your network and try again");
         }
      }
      helpers.showToast("success", "Company was created successful");
      onPress && onPress();
   };

   const updateMineCompany = async (
      title: string,
      id: string,
      onPress?: Function
   ) => {
      if (!title) {
         return helpers.showToast("error", "Company name is required");
      }
      helpers.showToast("loading", "Loading");
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/mining/company/${id}`,
            method: "PUT",
            json: { name: title },
         })
         .catch((e) => e);
      //  setIsLoading(false);
      if (sendRequest && sendRequest.status_code !== 200) {
         if (sendRequest) {
            return helpers.showToast("error", sendRequest.data?.message);
         } else {
            return helpers.showToast("error", "Please check your network and try again");
         }
      }
      getMineCompany();
      helpers.showToast("success", "Company was update successful");
      onPress && onPress();
   };
   const deleteMineCompany = async (id: string, onPress?: Function) => {
      helpers.showToast("loading", "Loading");
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/mining/company/${id}`,
            method: "DELETE",
         })
         .catch((e) => e);
      if (sendRequest && sendRequest.status_code !== 200) {
         // return helpers.showErrorMessage(sendRequest);
         if (sendRequest) {
            return helpers.showToast("error", sendRequest.data?.message);
         } else {
            return helpers.showToast("error", "Please check your network and try again");
         }
      }
      getMineCompany();
      helpers.showToast("success", "Entry was deleted successful");
      onPress && onPress();
   };

   return {
      createPrice,
      getPrice,
      updatePrice,
      deletePrice,
      createMarketer,
      getMarketers,
      updateMarketer,
      deleteMarketer,
      getMarketerPms,
      getMarketerAgo,
      getMarketerLpg,
      getMarketerDpk,
      createLicense,
      getLicense,
      updateLicense,
      deleteLicense,
      getCountPrice,
      getMineCompany,
      newMineCompany,
      updateMineCompany,
      deleteMineCompany
   };
};
