import react, { useState } from 'react'
import { useStoreSelector } from '../../store/useStore'
import userAdminFunction from '../../hooks/userAdminFunction'
import { NavLink } from 'react-router-dom'
const logo: string = require('../../assets/icons/logo.svg').default
export default () => {
   const [isLoading, setIsLoading] = useState(false)
   const { firstLogin } = userAdminFunction()
   const { userData } = useStoreSelector(["userData"])
   const [loginData, setLoginData] = useState({ fullname: userData?.fullname, auth_id: userData?.auth_id, password: '' })
   console.log(userData)
   return (
      <div className="container-fluid h-100 nbglogin">
         <div>
            <nav className="navbar navbar-expand-sm fixed-top navbar-light logMenuBg">
               <div className="container">
                  <div className='row newstyle'>
                     <div className='col-lg-4 d-flex'>
                        <a className="navbar-brand text-white" href="#">www.aipecgroup.com</a>
                        <a className="navbar-brand" href="#"><span className="text-white">●</span></a>
                        <a className="navbar-brand text-white" href="#">Aipec Data Centre</a>
                     </div>
                  </div>
               </div>
            </nav>
         </div>
         <div className="row h-100 align-items-center justify-contain-center">
            <div className="col-xl-12">
               <div className="card main-width">
                  <div className="card-body  p-0">
                     <div className="newWidth">
                        <div className="card mNewCard">
                           <div className="card-body">
                              <h2 className='nppColor mb-4'>Quick guide</h2>
                              <div className="d-flex">
                                 <h3 className='nppColor mt-4 mb-4'><span className="colorGreen">●</span> </h3>
                                 <div style={{ marginLeft: "20px" }}>
                                    <h3 className='nppColor mt-4 mb-4'>Step 1</h3>
                                    <p style={{ fontSize: "16px" }}>As super admin, you handle the end to end operation of the AIPEC Data centre, this involves inviting data entry officers, managing and updating new commodities etc.</p>
                                 </div>
                              </div>
                              <div className="d-flex align-items-center justify-content-between flex-wrap" style={{ marginTop: "70px" }}>
                                 <p style={{ marginTop: "-12px" }}><NavLink to="/gas" className="text-primary textGreen font-w500 showCursor">Skip</NavLink></p>
                                 <NavLink className="btn btn-primary mb-4 btnGreen bgbtn" to="/guide/two">Next</NavLink>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}