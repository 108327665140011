import { useEffect, useRef, useState } from "react"
import AppLayout from "../../components/AppLayout"
import InputTable from "../../components/InputTable"
import { NavLink } from "react-router-dom"
import TradeModal from "../../components/TradeModal"
import tradeFunction from "../../hooks/tradeFunction"
import { useStoreSelector } from "../../store/useStore"
import EmptyTable from "../../components/EmptyTable"
import helpers from "../../utils/helpers"
import DeleteModal from "../../components/DeleteModal"
import TradeModalEdit from "../../components/TradeModalEdit"
import Loader from "../../components/Loader"
import { useDownloadExcel } from "react-export-table-to-excel"
import Pagination from "../../components/Pagination"
const deleteBtn: string = require('../../assets/icons/delete.svg').default

export default () => {
   const [activePage, setActivePage] = useState(1)
   const [newDataAdd, setNewDataAdd] = useState(false)
   const [deleteModal, setDeleteModal] = useState({ data: {}, status: false })
   const [editData, setEditData] = useState({ data: {}, status: false })
   const { getTradeEntry, deleteTradeEntry, getAllTradeEntryCount } = tradeFunction()
   const { tradeData, userData, isLoading, tradeEntryCount } = useStoreSelector(["tradeData", "userData", "isLoading", "tradeEntryCount"])

   const tableRef = useRef(null);
   const [exportBtn, setExportBtn] = useState(false)
   const [newDate, setNewDate] = useState({ start: "", end: "", type: '' })

   const { onDownload } = useDownloadExcel({
      currentTableRef: tableRef.current,
      filename: 'Trading table',
      sheet: 'Trading'
   })
   useEffect(() => {
      getTradeEntry()
      getAllTradeEntryCount()
   }, [])
   let userType = userData?.user_type
   let dept = userData?.department
   return (
      <AppLayout active="input" title="Data Inputs">
         <div className="container-fluid">
            <div className="card h-auto">
               <div className="row">
                  <div className="col-xl-12">
                     <div className="page-titles myheaderNew">
                        <div className="custom-tab-1">
                           {userType === "superadmin" || userType === "admin" ?
                              <ul className="nav nav-tabs liPad">
                                 <li className="nav-item"><NavLink to="/input/gas" className="nav-link">Oil & Gas</NavLink>
                                 </li>
                                 <li className="nav-item"><NavLink to="/input/mining" className="nav-link">Mining</NavLink>
                                 </li>
                                 <li className="nav-item"><NavLink to="/input/comodity" className="nav-link">Commodity Prices</NavLink>
                                 </li>
                                 <li className="nav-item"><NavLink to="/input/trading" className="nav-link active">Trading</NavLink>
                                 </li>
                                 <li className="nav-item"><NavLink to="/input/human" className="nav-link">Human Resources</NavLink>
                                 </li>
                              </ul>
                              : ""}
                           {userType === "data-officer" || dept === "trading" ?
                              <ul className="nav nav-tabs">
                                 <li className="nav-item"><NavLink to="/input/trading" className="nav-link active">Trading</NavLink>
                                 </li>
                              </ul>
                              : ""}
                        </div>
                        <div className="d-flex flex-wrap my-0 my-sm-0 py-sm-2 py-xl-0">
                           <div className="invoice-btn ">
                              <div className="btn-group mb-1">
                                 <button type="button" className="btn btn-primary brandGreen" onClick={() => { setNewDataAdd(true) }}>Add New</button>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-6">
                     <div className="container mttop">
                        <div className="d-flex align-items-center ml-2">
                           <select className="image-select default-select wide dashboard-select pselect3" id="box" aria-label="Default" onChange={(e) => {
                              getTradeEntry("", "", e.target.value)
                              setNewDate(edata => ({ ...edata, type: e.target.value.trim() }))
                           }
                           }>
                              <option value="">All Deals</option>
                              <option value="1">Paid Deals</option>
                              <option value="0">Unpaid Deals</option>
                           </select>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-6">
                     <div className="row">
                        <div className="col-xl-8 col-lg-8 col-8">
                           <div className=" mttop">
                              {/* <label className="form-label">Start - End</label> */}
                              <div className="input-group">
                                 <input className="form-control newInput" required onChange={(e) => setNewDate(edata => ({ ...edata, start: e.target.value.trim() }))} type="date" placeholder="Now" />
                                 <input className="form-control newInput" required onChange={(e) => setNewDate(edata => ({ ...edata, end: e.target.value.trim() }))} type="date" placeholder="Now" />
                                 <button type="button" id="check-minutes" className="btn btn-ft primaryBG3 newInput"
                                    onClick={(e) => {
                                       e.preventDefault()
                                       if (newDate.start === '') return helpers.showToast("error", "Start date is required")
                                       if (newDate.end === '') return helpers.showToast("error", "End date is required")
                                       getTradeEntry(newDate.start, newDate.end, newDate.type, 1, 500)
                                       setExportBtn(true)
                                    }}>Filter</button>
                              </div>
                           </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-6">
                           <div className="btn-group mll20">
                              <button type="button" disabled={!exportBtn} className={`btn btn-primary ${exportBtn ? "brandGreen" : "brandGray"}`} onClick={onDownload}>Export Excel</button>

                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               {isLoading && <Loader style={{ position: "relative" }} />}
               {!isLoading && tradeData && tradeData.length > 0 ?
                  <div className="row" style={{ padding: "20px" }}>
                     <div className="col-12 mbb-40">
                        <InputTable refData={tableRef} isLoading={isLoading} data={tradeData || []} headers={["Deal Details", "Data officer", "Date", "Time", "Action"]} tableTitle="">
                           {(tradeData || []).map((item: any, i: any) => (
                              <tr>
                                 <th>{item?.deal.toUpperCase()}</th>
                                 <th>{item?.admin_data?.fullname}</th>
                                 <td>{new Date(item.createdAt).toDateString()}</td>
                                 <td>{helpers.get24HourTo12Hours((item.createdAt))}</td>
                                 <td>
                                    <div className="d-flex jspace2">
                                       <a href="#" onClick={() => setEditData({ status: true, data: item })} className="btn btn-xs sharp editText">Update</a>
                                       <a href="#" onClick={() => setDeleteModal({ status: true, data: { auth_id: item.entry_id } })} className="btn btn-xs sharp"><img className='delete-img' src={deleteBtn} /></a>
                                    </div>
                                 </td>
                              </tr>))}

                        </InputTable>
                     </div>
                  </div>
                  : !isLoading ? <EmptyTable /> : null}
               <div className="">
                  {tradeEntryCount && tradeEntryCount?.total > 5 ?
                     <Pagination activePage={activePage} itemPerPage={50} totalPage={tradeEntryCount?.total} onPress={(nextPage) => {
                        getTradeEntry(newDate.start, newDate.end, newDate.type, nextPage)
                        setActivePage(nextPage)
                     }} /> : ""}
               </div>
            </div>
            {newDataAdd &&
               <TradeModal onPress={() => setNewDataAdd(false)} />
            }
            {deleteModal.status &&
               <DeleteModal data={deleteModal.data} onSubmit={deleteTradeEntry} onPress={() => setDeleteModal({ status: false, data: {} })} />
            }
            {editData.status &&
               <TradeModalEdit data={editData.data} onPress={() => setEditData({ status: false, data: {} })} />
            }
         </div>
      </AppLayout>
   )
}