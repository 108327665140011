import { ObjectPayload } from "../../typings/general";
import { StoreStateCreator } from "../useStore";

export interface AdminSliceState {
   userData?: ObjectPayload;
   setUserData?: (value: ObjectPayload) => void;

   adminData?: ObjectPayload;
   setAdminData?: (value: ObjectPayload) => void;

   adminPageCount?: ObjectPayload;
   setAdminPageCount?: (value: ObjectPayload) => void;
}

export const adminStoreSlice: StoreStateCreator<AdminSliceState> = (
   set,
   get
) => ({
   userData: {},
   setUserData: (value: ObjectPayload) => set({ userData: value }),

   adminData: [],
   setAdminData: (value: ObjectPayload) => set({ adminData: value }),

   adminPageCount: {},
   setAdminPageCount: (value: ObjectPayload) => set({ adminPageCount: value }),
});
