import { useNavigate } from "react-router-dom";
import { AppAlertData } from "../store/storeslice/alert-slice";
import { useStoreSelector } from "../store/useStore";
import APIendPoint from "../utils/apiEndPoints";
import helpers from "../utils/helpers";

export default () => {
   const navigate = useNavigate();
   const { setAlert, setIsLoading, setLocationData, setCommodity2Data, setCommodityData, setMiningEntryData, setMiningListData, setMiningGraphData, setMiningEntryCount } = useStoreSelector([
      "setAlert",
      "setIsLoading",
      "setLocationData",
      "setCommodityData",
      "setMiningEntryData",
      "setMiningListData",
      "setMiningGraphData",
      "setMiningEntryCount",
      "setCommodity2Data"
   ]);
   const getLocation = async () => {
      // setIsLoading(true)
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/mining/location`,
            method: "GET",
         })
         .catch((e) => e);
      if (sendRequest && sendRequest.status_code !== 200) {
         // return helpers.showErrorMessage(sendRequest);
         if (sendRequest) {
            return helpers.showToast("error", sendRequest.data?.message);
         } else {
            return helpers.showToast("error", "Please check your network and try again");
         }
      }
      // setIsLoading(false)
      setLocationData(sendRequest.data.data);
   };


   const addLocation = async (title: string, onPress?: Function) => {
      //  setIsLoading(true);

      if (!title) {
         return helpers.showToast("error", "Location name is required");
      }
      helpers.showToast("loading", "Loading");
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/mining/location`,
            method: "POST",
            json: { name: title },
         })
         .catch((e) => e);
      //  setIsLoading(false);
      if (sendRequest && sendRequest.status_code !== 200) {
         // return helpers.showErrorMessage(sendRequest);
         if (sendRequest) {
            return helpers.showToast("error", sendRequest.data?.message);
         } else {
            return helpers.showToast("error", "Please check your network and try again");
         }
      }
      helpers.showToast("success", "Location was created successful");
      onPress && onPress();
   };

   const updateLocation = async (
      title: string,
      id: string,
      onPress?: Function
   ) => {
      if (!title) {
         return helpers.showToast("error", "Location name is required");
      }
      helpers.showToast("loading", "Loading");
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/mining/location/${id}`,
            method: "PUT",
            json: { name: title },
         })
         .catch((e) => e);
      //  setIsLoading(false);
      if (sendRequest && sendRequest.status_code !== 200) {
         // return helpers.showErrorMessage(sendRequest);
         if (sendRequest) {
            return helpers.showToast("error", sendRequest.data?.message);
         } else {
            return helpers.showToast("error", "Please check your network and try again");
         }
      }
      getLocation();
      helpers.showToast("success", "Location was update successful");
      onPress && onPress();
   };

   const deleteLocation = async (id: string, onPress?: Function) => {
      helpers.showToast("loading", "Loading");
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/mining/location/${id}`,
            method: "DELETE",
         })
         .catch((e) => e);
      if (sendRequest && sendRequest.status_code !== 200) {
         // return helpers.showErrorMessage(sendRequest);
         if (sendRequest) {
            return helpers.showToast("error", sendRequest.data?.message);
         } else {
            return helpers.showToast("error", "Please check your network and try again");
         }
      }
      getLocation();
      helpers.showToast("success", "Entry was deleted successful");
      onPress && onPress();
   };


   const createCommodity = async (
      data: {
         name: string;
         color: string;
         unit?: string;
      },
      onPress?: Function
   ) => {

      if (!data?.name) {
         return helpers.showToast("error", "name is required");
      }
      helpers.showToast("loading", "Loading");
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/mining/commodity`,
            method: "POST",
            json: {
               color: data.color,
               unit: data?.unit,
               name: data?.name,
            },
         })
         .catch((e) => e);
      //  setIsLoading(false);
      if (sendRequest && sendRequest.status_code !== 200) {
         return helpers.showToast("error", sendRequest.data.message);
      }
      helpers.showToast("success", "Commodity was created successful");
      onPress && onPress();
   };
   const getCommodity2 = async (
      startDate = "",
      endDate = "",
      q = "",
      page = 1,
      perPage = 50
   ) => {
      // setIsLoading(true)
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/mining/commodity2?page=${page}&start_date=${startDate}&end_date=${endDate}&q=${q}&item_per_page=${perPage}`,
            method: "GET",
         })
         .catch((e) => e);
      // setIsLoading(false)
      if (sendRequest && sendRequest.status_code !== 200) {
         // return helpers.showErrorMessage(sendRequest);
         if (sendRequest) {
            return helpers.showToast("error", sendRequest.data?.message);
         } else {
            return helpers.showToast("error", "Please check your network and try again");
         }
      }
      // setIsLoading(false)
      setCommodity2Data(sendRequest.data.data);
   };
   const getCommodity = async (
      startDate = "",
      endDate = "",
      q = "",
      page = 1,
      perPage = 50
   ) => {
      // setIsLoading(true)
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/mining/commodity?page=${page}&start_date=${startDate}&end_date=${endDate}&q=${q}&item_per_page=${perPage}`,
            method: "GET",
         })
         .catch((e) => e);
      // setIsLoading(false)
      if (sendRequest && sendRequest.status_code !== 200) {
         // return helpers.showErrorMessage(sendRequest);
         if (sendRequest) {
            return helpers.showToast("error", sendRequest.data?.message);
         } else {
            return helpers.showToast("error", "Please check your network and try again");
         }
      }
      // setIsLoading(false)
      setCommodityData(sendRequest.data.data);
   };
   const updateCommodity = async (
      data: {
         name: string;
         color: string;
         unit?: string;
      },
      authID: string,
      onPress?: Function
   ) => {

      helpers.showToast("loading", "Loading");
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/mining/commodity/${authID}`,
            method: "PUT",
            json: {
               color: data.color,
               unit: data?.unit,
               name: data?.name,
            },
         })
         .catch((e) => e);
      //  setIsLoading(false);
      if (sendRequest && sendRequest.status_code !== 200) {
         // return helpers.showErrorMessage(sendRequest);
         if (sendRequest) {
            return helpers.showToast("error", sendRequest.data?.message);
         } else {
            return helpers.showToast("error", "Please check your network and try again");
         }
      }
      getCommodity()
      helpers.showToast("success", "Entry was updated successful");
      onPress && onPress();
   };
   const deleteCommodity = async (id: string, onPress?: Function) => {
      helpers.showToast("loading", "Loading");
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/mining/commodity/${id}`,
            method: "DELETE",
         })
         .catch((e) => e);
      if (sendRequest && sendRequest.status_code !== 200) {
         // return helpers.showErrorMessage(sendRequest);
         if (sendRequest) {
            return helpers.showToast("error", sendRequest.data?.message);
         } else {
            return helpers.showToast("error", "Please check your network and try again");
         }
      }
      getCommodity();
      helpers.showToast("success", "Commodity was deleted successful");
      onPress && onPress();
   };

   const createMining = async (
      data: {
         commodity_id: string;
         location_id: string;
         amount?: string;
         weight_type?: string;
      },
      onPress?: Function
   ) => {

      if (!data?.commodity_id) {
         return helpers.showToast("error", "Commodity is required");
      }
      helpers.showToast("loading", "Loading");
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/mining`,
            method: "POST",
            json: {
               commodity_id: data.commodity_id,
               location_id: data?.location_id,
               amount: data?.amount,
               weight_type: data?.weight_type,
            },
         })
         .catch((e) => e);
      //  setIsLoading(false);
      if (sendRequest && sendRequest.status_code !== 200) {
         return helpers.showToast("error", sendRequest.data.message);
      }
      getMining()
      helpers.showToast("success", "Entry was created successful");
      onPress && onPress();
   };
   const getMining = async (
      id = "",
      startDate = "",
      endDate = "",
      q = "",
      page = 1,
      type = "",
      perPage = 50
   ) => {
      setIsLoading(true)
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/mining?page=${page}&start_date=${startDate}&end_date=${endDate}&q=${q}&look_id=${id}&type=${type}&item_per_page=${perPage}`,
            method: "GET",
         })
         .catch((e) => e);
      setIsLoading(false)
      if (sendRequest && sendRequest.status_code !== 200) {
         // return helpers.showErrorMessage(sendRequest);
         if (sendRequest) {
            return helpers.showToast("error", sendRequest.data?.message);
         } else {
            return helpers.showToast("error", "Please check your network and try again");
         }
      }
      // setIsLoading(false)
      setMiningEntryData(sendRequest.data.data);
   };
   const getCountMining = async (
      id = "",
      startDate = "",
      endDate = "",
      q = "",
      page = 1,
      type = "",
      perPage = 50
   ) => {
      setIsLoading(true)
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/mining?page=${page}&start_date=${startDate}&end_date=${endDate}&q=${q}&look_id=${id}&type=${type}&item_per_page=${perPage}&component=count`,
            method: "GET",
         })
         .catch((e) => e);
      setIsLoading(false)
      if (sendRequest && sendRequest.status_code !== 200) {
         // return helpers.showErrorMessage(sendRequest);
         if (sendRequest) {
            return helpers.showToast("error", sendRequest.data?.message);
         } else {
            return helpers.showToast("error", "Please check your network and try again");
         }
      }
      // setIsLoading(false)
      setMiningEntryCount(sendRequest.data.data);
   };
   const updateMining = async (
      data: {
         commodity_id: string;
         location_id: string;
         amount?: string;
         weight_type?: string;
      },
      authID: string,
      onPress?: Function
   ) => {

      helpers.showToast("loading", "Loading");
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/mining/${authID}`,
            method: "PUT",
            json: {
               commodity_id: data.commodity_id,
               location_id: data?.location_id,
               amount: data?.amount,
               weight_type: data?.weight_type,
            },
         })
         .catch((e) => e);
      //  setIsLoading(false);
      if (sendRequest && sendRequest.status_code !== 200) {
         // return helpers.showErrorMessage(sendRequest);
         if (sendRequest) {
            return helpers.showToast("error", sendRequest.data?.message);
         } else {
            return helpers.showToast("error", "Please check your network and try again");
         }
      }
      getMining()
      helpers.showToast("success", "Entry was updated successful");
      onPress && onPress();
   };
   const deleteMining = async (id: string, onPress?: Function) => {
      helpers.showToast("loading", "Loading");
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/mining/${id}`,
            method: "DELETE",
         })
         .catch((e) => e);
      if (sendRequest && sendRequest.status_code !== 200) {
         // return helpers.showErrorMessage(sendRequest);
         if (sendRequest) {
            return helpers.showToast("error", sendRequest.data?.message);
         } else {
            return helpers.showToast("error", "Please check your network and try again");
         }
      }
      getMining();
      helpers.showToast("success", "Mining was deleted successful");
      onPress && onPress();
   };

   const getMiningCount = async (
      id = "",
      type = "g"
   ) => {
      setIsLoading(true)
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/mining/counter?look_id=${id}&type=${type}`,
            method: "GET",
         })
         .catch((e) => e);
      setIsLoading(false)
      if (sendRequest && sendRequest.status_code !== 200) {
         // return helpers.showErrorMessage(sendRequest);
         if (sendRequest) {
            return helpers.showToast("error", sendRequest.data?.message);
         } else {
            return helpers.showToast("error", "Please check your network and try again");
         }
      }
      // setIsLoading(false)
      setMiningListData(sendRequest.data.data);
   };
   const getMiningGraph = async (
      id = "",
      type = ""
   ) => {
      setIsLoading(true)
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/mining?look_id=${id}&type=${type}&component=count-status`,
            method: "GET",
         })
         .catch((e) => e);
      setIsLoading(false)
      if (sendRequest && sendRequest.status_code !== 200) {
         // return helpers.showErrorMessage(sendRequest);
         if (sendRequest) {
            return helpers.showToast("error", sendRequest.data?.message);
         } else {
            return helpers.showToast("error", "Please check your network and try again");
         }
      }
      // setIsLoading(false)
      setMiningGraphData(sendRequest.data.data);
   };

   return {
      getLocation,
      addLocation,
      updateLocation,
      deleteLocation,
      createCommodity,
      getCommodity,
      updateCommodity,
      deleteCommodity,
      createMining,
      getMining,
      updateMining,
      deleteMining,
      getMiningCount,
      getMiningGraph,
      getCountMining,
      getCommodity2
   };
};
