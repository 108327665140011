import { useEffect, useRef, useState } from "react"
import AppLayout from "../../components/AppLayout"
import InputTable from "../../components/InputTable"
import { NavLink } from "react-router-dom"
import MiningModal from "../../components/MiningModal"
import DeleteModal from "../../components/DeleteModal"
import ComodityModal from "../../components/ComodityModal"
import { useStoreSelector } from "../../store/useStore"
import ComodityList from "../../components/ComodityList"
import AddLocation from "../../components/AddLocation"
import commodityFunction from "../../hooks/commodityFunction"
import Loader from "../../components/Loader"
import EmptyTable from "../../components/EmptyTable"
import helpers from "../../utils/helpers"
import MiningModalEdit from "../../components/MiningModalEdit"
import LicenseAdd from "../../components/LicenseAdd"
import LicenseList from "../../components/LicenseList"
import { useDownloadExcel } from "react-export-table-to-excel"
import Pagination from "../../components/Pagination"
import MineCompany from "../../components/MineCompany"
import MineCompanyNew from "../../components/MineCompanyNew"
const deleteBtn: string = require('../../assets/icons/delete.svg').default

export default () => {
   const [activePage, setActivePage] = useState(1)
   const [newDataAdd, setNewDataAdd] = useState(false)
   const [newCommAdd, setNewCommAdd] = useState(false)
   const [newLicenseAdd, setNewLicenseAdd] = useState(false)
   const [newLicenseList, setNewLicenseList] = useState(false)
   const [newLocationAdd, setNewLocationAdd] = useState(false)
   const [newCommList, setNewCommList] = useState(false)
   const [newDataComp, setNewDataComp] = useState(false)
   const [cmpList, setCmpList] = useState(false)
   const [deleteModal, setDeleteModal] = useState({ data: {}, status: false })
   const [editData, setEditData] = useState({ data: {}, status: false })
   const { getCommodity, getMining, deleteMining, getCountMining } = commodityFunction()
   const { userData, miningEntryData, commodityData, isLoading, miningEntryCount } = useStoreSelector(["userData", "miningEntryData", "commodityData", "isLoading", "miningEntryCount"])

   const tableRef = useRef(null);
   const [exportBtn, setExportBtn] = useState(false)
   const [newDate, setNewDate] = useState({ start: "", end: "", type: '' })

   const { onDownload } = useDownloadExcel({
      currentTableRef: tableRef.current,
      filename: 'Mining table',
      sheet: 'Mining'
   })
   useEffect(() => {
      getCommodity()
      getMining()
      getCountMining()
   }, [])
   let userType = userData?.user_type
   let dept = userData?.department
   return (
      <AppLayout active="input" title="Data Inputs">
         <div className="container-fluid">
            <div className="card h-auto">
               <div className="row">
                  <div className="col-xl-12">
                     <div className="page-titles myheaderNew">
                        <div className="custom-tab-1">
                           {userType === "superadmin" || userType === "admin" ?
                              <ul className="nav nav-tabs liPad">
                                 <li className="nav-item"><NavLink to="/input/gas" className="nav-link">Oil & Gas</NavLink>
                                 </li>
                                 <li className="nav-item"><NavLink to="/input/mining" className="nav-link active">Mining</NavLink>
                                 </li>
                                 <li className="nav-item"><NavLink to="/input/comodity" className="nav-link">Commodity Prices</NavLink>
                                 </li>
                                 <li className="nav-item"><NavLink to="/input/trading" className="nav-link">Trading</NavLink>
                                 </li>
                                 <li className="nav-item"><NavLink to="/input/human" className="nav-link">Human Resources</NavLink>
                                 </li>
                              </ul>
                              : ""}
                           {userType === "data-officer" || dept === "mining" ?
                              <ul className="nav nav-tabs">
                                 <li className="nav-item"><NavLink to="/input/mining" className="nav-link active">Mining</NavLink>
                                 </li>
                              </ul>
                              : ""}
                        </div>
                        <div className="d-flex flex-wrap my-0 my-sm-0 py-sm-2 py-xl-0">
                           <div className="invoice-btn ">
                              <div className="btn-group mb-1">
                                 <button type="button" className="btn btn-primary brandGreen" onClick={() => { setNewDataAdd(true) }}>Add New</button>
                                 <button type="button" className="btn btn-primary brandGreen dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown">
                                 </button>
                                 <div className="dropdown-menu">
                                    <a className="dropdown-item" href="javascript:void(0);" onClick={() => { setNewDataAdd(true) }}>Add daily entry</a>
                                    <a className="dropdown-item" href="javascript:void(0);" onClick={() => { setNewCommAdd(true) }}>Add new commodity</a>
                                    <a className="dropdown-item" href="javascript:void(0);" onClick={() => { setNewLocationAdd(true) }}>Add new Location</a>
                                    <a className="dropdown-item" href="javascript:void(0);" onClick={() => { setNewLicenseAdd(true) }}>Add License</a>
                                    <a className="dropdown-item" href="javascript:void(0);" onClick={() => { setNewLicenseList(true) }}>View License</a>
                                    <a className="dropdown-item" href="javascript:void(0);" onClick={() => { setNewDataComp(true) }}>Add company</a>
                                    <a className="dropdown-item" href="javascript:void(0);" onClick={() => { setCmpList(true) }}>View company</a>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="col-12">
                     <div className="row">
                        <div className="col-xl-3 col-lg-4 col-6">
                           <div className="container mttop">
                              <div className="d-flex align-items-center ml-2">
                                 <select className="image-select default-select wide dashboard-select pselect3" id="box" aria-label="Default" onChange={(e) => {
                                    getMining(e.target.value)
                                    setNewDate(edata => ({ ...edata, type: e.target.value.trim() }))
                                 }
                                 }>
                                    <option value="">Select Entry</option>
                                    {commodityData ?
                                       (commodityData || []).map((item: any, i: any) => (
                                          <option value={item.entry_id}>{item.name}</option>
                                       )) : "Loading"}
                                 </select>
                              </div>
                           </div>
                        </div>
                        <div className="col-xl-6 col-lg-5 col-6">
                           <div className="row">
                              <div className="col-xl-8 col-lg-8 col-8">
                                 <div className=" mttop">
                                    {/* <label className="form-label">Start - End</label> */}
                                    <div className="input-group">
                                       <input className="form-control newInput" required onChange={(e) => setNewDate(edata => ({ ...edata, start: e.target.value.trim() }))} type="date" placeholder="Now" />
                                       <input className="form-control newInput" required onChange={(e) => setNewDate(edata => ({ ...edata, end: e.target.value.trim() }))} type="date" placeholder="Now" />
                                       <button type="button" id="check-minutes" className="btn btn-ft primaryBG3 newInput"
                                          onClick={(e) => {
                                             e.preventDefault()
                                             if (newDate.start === '') return helpers.showToast("error", "Start date is required")
                                             if (newDate.end === '') return helpers.showToast("error", "End date is required")
                                             getMining(newDate.type, newDate.start, newDate.end, '', 1, '', 500)
                                             setExportBtn(true)
                                          }}>Filter</button>
                                    </div>
                                 </div>
                              </div>
                              <div className="col-xl-4 col-lg-4 col-6">
                                 <div className="btn-group mll20">
                                    <button type="button" disabled={!exportBtn} className={`btn btn-primary ${exportBtn ? "brandGreen" : "brandGray"}`} onClick={onDownload}>Export Excel</button>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-6">
                           <p className="goToDright" style={{ marginTop: "10px" }} onClick={() => { setNewCommList(true) }}><a className="text-primary textGreen font-w500 showCursor">Saved Commodities <span style={{ marginLeft: "10px" }}><i className="fa fa-angle-right m-color-red showCursor" style={{ fontSize: "16px" }} aria-hidden="true"></i></span></a></p>

                        </div>
                     </div>
                  </div>
               </div>
               {isLoading && <Loader style={{ position: "relative" }} />}
               {!isLoading && miningEntryData && miningEntryData.length > 0 ?
                  <div className="row" style={{ padding: "20px" }}>
                     <div className="col-12 mbb-40">
                        <InputTable refData={tableRef} data={miningEntryData || []} headers={["Input Type", "Location", "Data officer", "Date", "Time", "Action"]} tableTitle="">
                           {(miningEntryData || []).map((item: any, i: any) => (
                              <tr>
                                 <th>{item?.commodity_data?.name}</th>
                                 <th>{item?.location_data?.name}</th>
                                 <th>{item?.admin_data?.fullname}</th>
                                 <td>{new Date(item.createdAt).toDateString()}</td>
                                 <td>{helpers.get24HourTo12Hours((item.createdAt))}</td>
                                 <td>
                                    <div className="d-flex jspace2">
                                       <a href="#" onClick={() => setEditData({ status: true, data: item })} className="btn btn-xs sharp editText">Update</a>
                                       <a href="#" onClick={() => setDeleteModal({ status: true, data: { auth_id: item.entry_id } })} className="btn btn-xs sharp"><img className='delete-img' src={deleteBtn} /></a>
                                    </div>
                                 </td>
                              </tr>))}
                        </InputTable>
                     </div>
                  </div>
                  : !isLoading ? <EmptyTable /> : null}
               <div className="">
                  {miningEntryCount && miningEntryCount?.total > 5 ?
                     <Pagination activePage={activePage} itemPerPage={50} totalPage={miningEntryCount?.total} onPress={(nextPage) => {
                        getMining(newDate.type, newDate.start, newDate.end, '', nextPage)
                        setActivePage(nextPage)
                     }} /> : ""}
               </div>
            </div>
            {newDataAdd &&
               <MiningModal onPress={() => setNewDataAdd(false)} />
            }

            {newCommAdd &&
               <ComodityModal onPress={() => setNewCommAdd(false)} />
            }
            {newCommList &&
               <ComodityList onPress={() => setNewCommList(false)} />
            }
            {newLicenseList &&
               <LicenseList onPress={() => setNewLicenseList(false)} />
            }
            {newLocationAdd &&
               <AddLocation onPress={() => setNewLocationAdd(false)} />
            }
            {newLicenseAdd &&
               <LicenseAdd onPress={() => setNewLicenseAdd(false)} />
            }

            {deleteModal.status &&
               <DeleteModal data={deleteModal.data} onSubmit={deleteMining} onPress={() => setDeleteModal({ status: false, data: {} })} />
            }
            {editData.status &&
               <MiningModalEdit data={editData.data} onPress={() => setEditData({ status: false, data: {} })} />
            }

            {cmpList &&
               <MineCompany onPress={() => setCmpList(false)} />
            }
            {newDataComp &&
               <MineCompanyNew onPress={() => setNewDataComp(false)} />
            }
         </div>
      </AppLayout>
   )
}