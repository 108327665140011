// let baseUrl = "http://localhost:4000/admin";
// let baseUrl = "https://aipec-user-api.onrender.com/admin";
let baseUrl = "https://api.aipecdashboard.com/admin";

const APIendPoint = {
   admin: {
      login: baseUrl + "/auths/login",
      pwdReset: baseUrl + "/auths/forgot-password",
      profile: baseUrl + "/accounts/profile",
      getAdmin: baseUrl,
   },

};

export default APIendPoint;
