import React from "react";

interface AppTableProps {
   tableTitle?: string;
   headers?: string[];
   data?: Array<any>;
   isLoading?: boolean;
   children: React.ReactNode
}

export default (props: AppTableProps) => {
   return (

      <div className="card">
         <div className="card-header pBottom borderAround" style={{ background: "#DADADA52" }}>
            <h6 className="card-title cardH6">{props.tableTitle ? props.tableTitle : ""}</h6>
         </div>
         <div className="card-body pAll">
            <div className="table-responsive">
               <table className="table table-bordered bgBrand" style={{ textAlign: "center" }}>
                  <thead>
                     {props.headers && props.headers.map((item, i) => <th key={i}>{item}</th>)}
                  </thead>
                  <tbody>
                     {props.children}
                  </tbody>
               </table>
            </div>
         </div>
      </div>
   )
}