import { ObjectPayload } from "../typings/general";
import BarChart from "./BarChart"


export type DataState = {
   data?: ObjectPayload;
}
export default (props: DataState) => {
   let arrayData: any = props.data ? props.data : []
   let data = {
      AGO: 0,
      DPK: 0,
      PMS: 0
   }
   for (let i = 0; i < arrayData.length; i++) {
      if (arrayData[i].type === "pms") {
         let nob = (arrayData[i].volume / 1000000).toFixed(0)
         data.PMS = nob as any
      }
      if (arrayData[i].type === "dpk") {
         let nob1 = (arrayData[i].volume / 1000000).toFixed(0)
         data.DPK = nob1 as any
      }
      if (arrayData[i].type === "ago") {
         let nob2 = (arrayData[i].volume / 1000000).toFixed(0)
         data.AGO = nob2 as any
      }
   }
   return (
      <div className="card h-auto pbottom303 newHeight1">
         <div className="card-header pb-0 border-0 flex-wrap bbBottom" style={{ alignItems: "flex-start" }}>
            <div>
               <div className="chart-title mb-3">
                  <h6 className="heading margin-0 font13">Current Product in Tank</h6>
               </div>
            </div>
            <div className="p-static">
               <div className="d-flex align-items-center mb-3 ">
                  <p className="toGreen"><span className="colorGreen">●</span> Today</p>
               </div>
            </div>
         </div>
         <div className="card-body pt-2 custome-tooltip pb-3 height250">
            <div id="activity">
               <BarChart title="volume" data={data} />
            </div>
         </div>
      </div>
   )
}
