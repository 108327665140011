import React, { useEffect, useState } from 'react'
import { Properties as CSSProperties } from 'csstype'
import { ObjectPayload } from '../typings/general';
import commodityFunction from '../hooks/commodityFunction';
import { useStoreSelector } from '../store/useStore';

export type DataState = {
   data?: ObjectPayload;
   onPress?: Function;
   containerStyle?: CSSProperties;
}
export default (props: DataState) => {
   const [comState, setComState] = useState({ data: { name: '', unit: '', color: '' } })
   const { getLocation, createCommodity } = commodityFunction()
   const { locationData } = useStoreSelector(["locationData"])
   useEffect(() => {
      getLocation()
   }, [])
   return (
      <div style={{
         position: 'fixed', left: 0, top: 0,
         display: 'flex',
         justifyContent: 'center',
         alignItems: 'center',
         backgroundColor: 'rgba(0, 0, 0, 0.3)',
         backgroundSize: "cover",
         width: '100%',
         height: '100vh',
         zIndex: 999,
      }}>
         <div style={{ borderRadius: '20px', padding: '20px', width: '500px', ...props.containerStyle }}>
            <div className='bg-white' style={{ borderRadius: "20px" }}>
               <div className="filterForm w-100">
                  <div className='popupClass1'>
                     <div className="modal-content">
                        <div className="modal-body" style={{ marginTop: "0" }}>
                           <div className="mb-3 d-block">
                              <div className="d-flex align-items-center justify-content-between flex-wrap">
                                 <div>
                                    <h3 className="modal-title fs-5 inviteColor" id="exampleModalLabel">Add new commodity</h3>
                                 </div>
                                 <i onClick={() => props.onPress && props.onPress()} style={{ fontSize: '20px', marginRight: '-5px' }} className="fa fa-times m-color-red showCursor" aria-hidden="true"></i>
                              </div>
                           </div>
                           <div className="mb-3 d-block">
                              <label className="form-label d-block mb-2">Commodity name</label>
                              <input type="text" className="form-control w-100 mb-0" onChange={(e) => {
                                 setComState(state => ({ ...state, data: { ...state.data, name: e.target.value } }))
                              }} />
                           </div>
                           <div className="mb-3 d-block">
                              <label className="form-label d-block mb-1">Unit</label>
                              <select className="form-control w-100 mb-0 selectH pselect4" aria-label="Default select example" onChange={(e) => {
                                 setComState(state => ({ ...state, data: { ...state.data, unit: e.target.value } }))
                              }}>
                                 <option value="">Select</option>
                                 <option value="Barrel">Barrel</option>
                                 <option value="MT">MT</option>
                                 <option value="OZ">OZ</option>
                              </select>
                           </div>
                           <div className="mb-3 d-block">
                              <label className="form-label d-block mb-2">Color</label>
                              <input type="color" className="form-control w-100 mb-0 mcolor" defaultValue="#B1E3BA" style={{ height: "60px", padding: "0", border: "none" }} onChange={(e) => {
                                 setComState(state => ({ ...state, data: { ...state.data, color: e.target.value } }))
                              }} />
                           </div>
                           <div className='row'>
                              <div className='col-lg-3 col-6'>
                                 <label className="form-label d-block mb-2"></label>
                                 <div className='' style={{ backgroundColor: comState?.data?.color ? comState?.data?.color : "#B1E3BA", height: "60px" }}>

                                 </div>
                              </div>
                              <div className='col-lg-9 col-6'>
                                 <div className="mb-3 d-block">
                                    <label className="form-label d-block mb-2">Assign color code</label>
                                    <input type="text" className="form-control w-100 mb-0 mcolor2" defaultValue={comState.data.color} />
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="modal-footer">
                           <button type="button" className="btn btn-lg light primaryBG2 ww-48" onClick={() => props.onPress && props.onPress()}>Close</button>
                           <button type="button" className="btn btn-lg primaryBG ww-48" onClick={() => createCommodity(comState.data, props.onPress)}>Submit</button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

      </div>
   )
}