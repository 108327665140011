import React, { MouseEventHandler, useEffect } from "react"
import HeaderIndex from "./HeaderIndex";
import SidebarIndex from "./SidebarIndex";

interface AppLayoutProps {
   children: React.ReactNode;
   active?: string;
   title?: string;
   onPress?: MouseEventHandler<HTMLDivElement> | undefined;
}
const AppLayout = (props: AppLayoutProps) => {
   return (
      <div id="main-wrapper">
         {/* logo */}
         {/* <HeaderIndex title={props.title} /> */}

         {/* sidebar */}
         <SidebarIndex activeClass={props.active} title={props.title} />
         <div className="content-body">
            {props?.children}
         </div>
      </div>
   )
}
export default AppLayout