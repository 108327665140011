import react, { useEffect, useState } from 'react'
import userAdminFunction from '../../hooks/userAdminFunction'
import { useParams } from 'react-router-dom'
const logo: string = require('../../assets/icons/logo.svg').default
export default () => {
   const { login, getprofile } = userAdminFunction()
   const [isLoading, setIsLoading] = useState(false)
   const [loginData, setLoginData] = useState({ email: '', password: '' })
   const urlPage = Object.values(useParams())[0]?.split('/')
   useEffect(() => {
      if (urlPage && urlPage.length) {
         getprofile()
      }
   }, [])
   return (
      <div className="container-fluid h-100 nbglogin">
         <div>
            <nav className="navbar navbar-expand-sm fixed-top navbar-light logMenuBg">
               <div className="container">
                  <div className='row newstyle'>
                     <div className='col-lg-4 d-flex'>
                        <a className="navbar-brand text-white" href="#">www.aipecgroup.com</a>
                        <a className="navbar-brand" href="#"><span className="text-white">●</span></a>
                        <a className="navbar-brand text-white" href="#">Aipec Data Centre</a>
                     </div>
                  </div>
               </div>
            </nav>
         </div>
         <div className="row h-100 align-items-center justify-contain-center">
            <div className="col-xl-12">
               <div className="card main-width">
                  <div className="card-body  p-0">
                     <div className="newWidth">
                        <div className="card mNewCard">
                           <div className="card-body">
                              <div className="d-flex align-items-center justify-content-center mb-4">
                                 <a href="#">
                                    <img src={logo} />
                                 </a>
                              </div>
                              <h2 className='nppColor text-center'>Welcome Onboard</h2>
                              <span className='text-center'>Input the required details to access your dashboard</span>
                              <form className="mt-4">
                                 <div className="form-group mb-4">
                                    <label>Email address</label>
                                    <input type="email" onChange={(e) => setLoginData(edata => ({ ...edata, email: e.target.value.trim() }))} className="form-control" id="exampleInputEmail1" placeholder="Enter Email" />
                                 </div>
                                 <div className="form-group mb-4">
                                    <label>Password</label>
                                    <input type="password" onChange={(e) => setLoginData(edata => ({ ...edata, password: e.target.value.trim() }))} className="form-control" id="exampleInputPassword1" placeholder="Password" />
                                 </div>
                              </form>
                              <button type='button' disabled={isLoading || false} onClick={() => login(loginData.email, loginData.password, setIsLoading)} className="btn btn-primary mb-4 btnGreen nnbtn">Get Started</button>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}