import React from "react";
const onCourse: string = require('../assets/icons/oncourse.svg').default
const behind: string = require('../assets/icons/behind.svg').default

interface AppTableProps {
   tableTitle?: string;
   quarter?: string;
   headers?: string[];
   data?: { kra_one_status: any, kra_two_status: any, kra_three_status: any, kra_four_status: any };
   isLoading?: boolean;
   children?: React.ReactNode,
   addClass?: string
}

export default (props: AppTableProps) => {
   const checkStatus = (status: Number) => {
      let newStatus: any = status ? status : 0
      if (newStatus) {
         if (parseInt(newStatus) === 2) {
            return <img src={onCourse} />;
            // return <span className="colorGreen">●</span>;
         } else if (parseInt(newStatus) === 1) {
            return <img src={behind} />;
            // return <span className="colorRed">●</span>;
         } else {
            return `-`
         }
      }
   }
   return (

      <div className="card">
         <div className="card-body pAll">
            <div className={`table-responsive b-bottom-none ${props.addClass}`}>
               <table className="table table-bordered collapseT">
                  <thead>
                     <tr>
                        <th className="highlight">{props.tableTitle ? props.tableTitle : ""}</th>
                        <th>-</th>
                        <th>Position</th>
                     </tr>
                  </thead>
                  <tbody>
                     <tr>
                        <td rowSpan={5} className="category">{props.quarter ? props.quarter : ""}</td>
                        <td>KRA 1</td>
                        <td> {props?.data && props?.data?.kra_one_status !== 0 ? checkStatus(props?.data?.kra_one_status) : '-'}</td>
                     </tr>
                     <tr>
                        <td>KRA 2</td>
                        <td>{props?.data && props?.data?.kra_two_status !== 0 ? checkStatus(props?.data?.kra_two_status) : "-"}</td>
                     </tr>
                     <tr>
                        <td>KRA 3</td>
                        <td>{props?.data && props?.data?.kra_three_status !== 0 ? checkStatus(props?.data?.kra_three_status) : "-"}</td>
                     </tr>
                     <tr>
                        <td>KRA 4</td>
                        <td>{props?.data && props?.data?.kra_four_status !== 0 ? checkStatus(props?.data?.kra_four_status) : "-"}</td>
                     </tr>
                  </tbody>
               </table>
            </div>
         </div>
      </div>
   )
}