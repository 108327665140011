import { Routes, RouteObject } from "react-router-dom"
import InviteIndex from "../pages/invite/InviteIndex"


export const InviteRoute: RouteObject = {
   path: "/invite",
   children: [
      {
         path: "",
         element: <InviteIndex />
      },
   ]
}