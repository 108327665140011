import React, { useEffect, useState } from 'react'
import { Properties as CSSProperties } from 'csstype'
import { ObjectPayload } from '../typings/general';
import helpers from '../utils/helpers';
import oilFunction from '../hooks/oilFunction';
import { useStoreSelector } from '../store/useStore';
const editBtn: string = require('../assets/icons/date.svg').default

export type DataState = {
   data?: ObjectPayload;
   onPress?: Function;
   containerStyle?: CSSProperties;
}
export default (props: DataState) => {
   let newData = props?.data
   const { updatePriceEntry, getOilTankDay, getpriceCountStatus } = oilFunction()
   const todayDate = () => {
      let now = new Date();
      let month: any = (now.getMonth() + 1);
      let day: any = now.getDate();
      if (month < 10)
         month = "0" + month;
      if (day < 10)
         day = "0" + day;
      return now.getFullYear() + '-' + month + '-' + day;
   }
   let oldDate = todayDate()
   let dbDate = newData?.created ? newData?.created.split('T')[0] : oldDate
   const [nData1, setNData1] = useState({ ndate1: dbDate, unit_pms: newData?.type === "pms" ? newData?.unit_price : '', unit_ago: newData?.type === "ago" ? newData?.unit_price : "", unit_dpk: newData?.type === "dpk" ? newData?.unit_price : '', vol_pms: newData?.type === "pms" ? newData?.sold_volume : '', vol_ago: newData?.type === "ago" ? newData?.sold_volume : '', vol_dpk: newData?.type === "dpk" ? newData?.sold_volume : '' })

   const [oPrice, setoPrice] = useState({ dpk: 0, pms: 0, ago: 0 })
   const getTotal = (ab: any, bc: any, type: string) => {
      if (ab && bc) {
         let nab = !/^[0-9]+[.]{0,1}[0-9]*$/.test(ab) ? parseFloat(ab.replace(/\,/g, '')) : ab
         let nab1 = !/^[0-9]+[.]{0,1}[0-9]*$/.test(bc) ? parseFloat(bc.replace(/\,/g, '')) : bc
         let newAb = nab === 0 ? 1 : nab
         let newBc = nab1 === 0 ? 1 : nab1
         let dc = newAb * newBc
         if (type === "dpk") {
            setoPrice((e) => ({ ...e, dpk: dc }))
         }
         if (type === "pms") {
            setoPrice((e) => ({ ...e, pms: dc }))
         }
         if (type === "ago") {
            setoPrice((e) => ({ ...e, ago: dc }))
         }
      } else {
         return 0.00
      }
   }

   const { gasTankChart, } = useStoreSelector(["gasTankChart"])
   const { singlePriceData } = useStoreSelector(["singlePriceData"])
   useEffect(() => {
      getpriceCountStatus()
      getOilTankDay()
   }, [])
   let dpkPrice = singlePriceData?.dpk_price ? singlePriceData?.dpk_price : 0
   let dpkVol = singlePriceData?.dpk_vol ? singlePriceData?.dpk_vol : 0

   let agoPrice = singlePriceData?.ago_price ? singlePriceData?.ago_price : 0
   let agoVol = singlePriceData?.ago_vol ? singlePriceData?.ago_vol : 0

   let pmsPrice = singlePriceData?.pms_price ? singlePriceData?.pms_price : 0
   let pmsVol = singlePriceData?.pms_vol ? singlePriceData?.pms_vol : 0

   let arrayData: any = gasTankChart ? gasTankChart : []
   let dataVol = {
      AGO: 0,
      DPK: 0,
      PMS: 0
   }
   for (let i = 0; i < arrayData.length; i++) {
      if (arrayData[i].type === "pms") {
         let nob = arrayData[i].volume
         dataVol.PMS = nob as any
      }
      if (arrayData[i].type === "dpk") {
         let nob1 = arrayData[i].volume
         dataVol.DPK = nob1 as any
      }
      if (arrayData[i].type === "ago") {
         let nob2 = arrayData[i].volume
         dataVol.AGO = nob2 as any
      }
   }
   return (
      <div style={{
         position: 'fixed', left: 0, top: 0,
         display: 'flex',
         justifyContent: 'center',
         alignItems: 'center',
         backgroundColor: 'rgba(0, 0, 0, 0.3)',
         backgroundSize: "cover",
         width: '100%',
         height: '100vh',
         paddingBottom: "20px",
         zIndex: 999,
      }}>
         <div style={{ borderRadius: '20px', padding: '20px', width: '600px', ...props.containerStyle }}>
            <div className='bg-white' style={{ borderRadius: "20px" }}>
               <div className="filterForm w-100">
                  <div className='popupClass1'>
                     <div className="modal-content">
                        <div className="tab-content" id="myTabContent">
                           {/* Price entering */}
                           <div className="">
                              <div className="modal-body modalBody" style={{ marginTop: "0", paddingBottom: "0", paddingTop: "0" }}>
                                 <div className="mb-2 d-block" style={{ marginTop: "-10px" }}>
                                    <div className="row mb-2">
                                       <div className='col-8'></div>
                                       <div className='col-4'>
                                          <div className='container onContainer'>
                                             <div className='d-flex showCursor'>
                                                <input type="date" defaultValue={nData1.ndate1} className="form-control w-100 mb-0 npinput" placeholder='eg Placeholder' />
                                                <img className='goMyLeft' src={editBtn} />
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    {/* <div className="d-flex align-items-center justify-content-between flex-wrap">
                                          <div>
                                             <h2 className="modal-title fs-5 inviteColor">Price Entry</h2>
                                             <span>Update new data to replace the previous</span>
                                          </div>
                                       </div> */}
                                 </div>

                                 <div className="mb-2 d-block">
                                    <div className="d-flex align-items-center justify-content-between flex-wrap">
                                       <div>
                                          <h3 className="modal-title fs-5 inviteColor">Unit Price</h3>
                                          <span>N.B: All prices are measured in Naira(NGN)</span>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="row">
                                    <div className="col-lg-4 col-6">
                                       <div className="mb-2 d-block">
                                          <label className="form-label d-block mb-1">DPK</label>
                                          <input type="tel" value={helpers.formatMoney(nData1.unit_dpk) as any} className="form-control w-100 mb-0" placeholder='eg Placeholder' onChange={
                                             (e) => {
                                                setNData1(edata => ({ ...edata, unit_dpk: e.target.value.trim() }))
                                                e.target.value = helpers.formatMoney(e.target.value.replace(/\,/g, '')) as any
                                                getTotal(e.target.value, nData1.vol_dpk, "dpk")
                                             }} />
                                       </div>
                                    </div>
                                    <div className="col-lg-4 col-6">
                                       <div className="mb-2 d-block">
                                          <label className="form-label d-block mb-1">AGO</label>
                                          <input type="tel" value={helpers.formatMoney(nData1.unit_ago) as any} className="form-control w-100 mb-0" placeholder='eg Placeholder' onChange={
                                             (e) => {
                                                setNData1(edata => ({ ...edata, unit_ago: e.target.value.trim() }))
                                                e.target.value = helpers.formatMoney(e.target.value.replace(/\,/g, '')) as any
                                                getTotal(e.target.value, nData1.vol_ago, "ago")
                                             }} />
                                       </div>
                                    </div>
                                    <div className="col-lg-4 col-6">
                                       <div className="mb-2 d-block">
                                          <label className="form-label d-block mb-1">PMS</label>
                                          <input type="tel" value={helpers.formatMoney(nData1.unit_pms) as any} className="form-control w-100 mb-0" placeholder='eg Placeholder' onChange={
                                             (e) => {
                                                setNData1(edata => ({ ...edata, unit_pms: e.target.value.trim() }))
                                                e.target.value = helpers.formatMoney(e.target.value.replace(/\,/g, '')) as any
                                                getTotal(e.target.value, nData1.vol_pms, "pms")
                                             }} />
                                       </div>
                                    </div>
                                 </div>
                                 <div className="mb-2 d-block">
                                    <div className="d-flex align-items-center justify-content-between flex-wrap">
                                       <div>
                                          <h3 className="modal-title fs-5 inviteColor goFonte">Volume</h3>
                                          <span>N.B: All volumes are measured in Litres</span>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="row">
                                    <div className="col-lg-4 col-6">
                                       <div className="mb-2 d-block">
                                          <label className="form-label d-block mb-1">DPK</label>
                                          <input type="tel" value={helpers.formatMoney(nData1.vol_dpk) as any} className="form-control w-100 mb-0" placeholder='eg Placeholder' onChange={
                                             (e) => {
                                                setNData1(edata => ({ ...edata, vol_dpk: e.target.value.trim() }))
                                                e.target.value = helpers.formatMoney(e.target.value.replace(/\,/g, '')) as any
                                                getTotal(nData1.unit_dpk, e.target.value, "dpk")
                                             }} />
                                          <span className='spfont'>Avail Vol: <span style={{ color: 'red' }}>{helpers.formatMoney(dataVol.DPK.toFixed(2) || 0)}</span></span>
                                       </div>
                                    </div>
                                    <div className="col-lg-4 col-6">
                                       <div className="mb-2 d-block">
                                          <label className="form-label d-block mb-1">AGO</label>
                                          <input type="tel" value={helpers.formatMoney(nData1.vol_ago) as any} className="form-control w-100 mb-0" placeholder='eg Placeholder' onChange={
                                             (e) => {
                                                setNData1(edata => ({ ...edata, vol_ago: e.target.value.trim() }))
                                                e.target.value = helpers.formatMoney(e.target.value.replace(/\,/g, '')) as any
                                                getTotal(nData1.unit_ago, e.target.value, "ago")
                                             }} />
                                          <span className='spfont'>Avail Vol: <span style={{ color: 'red' }}>{helpers.formatMoney(dataVol.AGO.toFixed(2) || 0)}</span></span>
                                       </div>
                                    </div>
                                    <div className="col-lg-4 col-6">
                                       <div className="mb-2 d-block">
                                          <label className="form-label d-block mb-1">PMS</label>
                                          <input type="tel" value={helpers.formatMoney(nData1.vol_pms) as any} className="form-control w-100 mb-0" placeholder='eg Placeholder' onChange={
                                             (e) => {
                                                setNData1(edata => ({ ...edata, vol_pms: e.target.value.trim() }))
                                                e.target.value = helpers.formatMoney(e.target.value.replace(/\,/g, '')) as any
                                                getTotal(nData1.unit_pms, e.target.value, "pms")
                                             }} />
                                          <span className='spfont'>Avail Vol: <span style={{ color: 'red' }}>{helpers.formatMoney(dataVol.PMS.toFixed(2) || 0)}</span></span>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="mb-2 d-block">
                                    <div className="d-flex align-items-center justify-content-between flex-wrap">
                                       <div>
                                          <h3 className="modal-title fs-5 inviteColor">Total Price</h3>
                                          <span>N.B: All volumes are measured in Litres</span>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="row">
                                    <div className="col-lg-4 col-6">
                                       <div className="mb-2 d-block">
                                          <label className="form-label d-block mb-1">DPK</label>
                                          <input type="tel" value={helpers.formatMoney(oPrice.dpk.toFixed(2))} disabled className="form-control w-100 mb-0" placeholder='eg Placeholder' />
                                       </div>
                                    </div>
                                    <div className="col-lg-4 col-6">
                                       <div className="mb-2 d-block">
                                          <label className="form-label d-block mb-1">AGO</label>
                                          <input type="tel" value={helpers.formatMoney(oPrice.ago.toFixed(2))} disabled className="form-control w-100 mb-0" placeholder='eg Placeholder' />
                                       </div>
                                    </div>
                                    <div className="col-lg-4 col-6">
                                       <div className="mb-2 d-block">
                                          <label className="form-label d-block mb-1">PMS</label>
                                          <input type="tel" value={helpers.formatMoney(oPrice.pms.toFixed(2))} className="form-control w-100 mb-0" disabled placeholder='eg Placeholder' />
                                       </div>
                                    </div>
                                 </div>
                                 <div className="mb-2 d-block">
                                    <div className="d-flex align-items-center justify-content-between flex-wrap">
                                       <div>
                                          <h3 className="modal-title fs-5 inviteColor">Accumulative Daily Product Sales</h3>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="row">
                                    <div className="col-lg-4 col-6">
                                       <div className="mb-2 d-block">
                                          <label className="form-label d-block mb-1">DPK</label>
                                          <input type="text" value={helpers.formatMoney(dpkVol.toFixed(2))} disabled className="form-control w-100 mb-0" placeholder='eg Placeholder' />
                                       </div>
                                    </div>
                                    <div className="col-lg-4 col-6">
                                       <div className="mb-2 d-block">
                                          <label className="form-label d-block mb-1">AGO</label>
                                          <input type="text" value={helpers.formatMoney(agoVol.toFixed(2))} disabled className="form-control w-100 mb-0" placeholder='eg Placeholder' />
                                       </div>
                                    </div>
                                    <div className="col-lg-4 col-6">
                                       <div className="mb-2 d-block">
                                          <label className="form-label d-block mb-1">PMS</label>
                                          <input type="text" value={helpers.formatMoney(pmsVol.toFixed(2))} disabled className="form-control w-100 mb-0" placeholder='eg Placeholder' />
                                       </div>
                                    </div>
                                 </div>
                                 <div className="mb-2 d-block">
                                    <div className="d-flex align-items-center justify-content-between flex-wrap">
                                       <div>
                                          <h3 className="modal-title fs-5 inviteColor">Accumulative Daily Revenue</h3>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="row">
                                    <div className="col-lg-4 col-6">
                                       <div className="mb-2 d-block">
                                          <label className="form-label d-block mb-1">DPK</label>
                                          <input type="text" value={helpers.formatMoney(dpkPrice)} disabled className="form-control w-100 mb-0" placeholder='eg Placeholder' />
                                       </div>
                                    </div>
                                    <div className="col-lg-4 col-6">
                                       <div className="mb-2 d-block">
                                          <label className="form-label d-block mb-1">AGO</label>
                                          <input type="text" value={helpers.formatMoney(agoPrice)} disabled className="form-control w-100 mb-0" placeholder='eg Placeholder' />
                                       </div>
                                    </div>
                                    <div className="col-lg-4 col-6">
                                       <div className="mb-2 d-block">
                                          <label className="form-label d-block mb-1">PMS</label>
                                          <input type="text" value={helpers.formatMoney(pmsPrice)} disabled className="form-control w-100 mb-0" placeholder='eg Placeholder' />
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div className="modal-footer">
                                 <button type="button" className="btn btn-lg light primaryBG2 ww-50" onClick={() => props.onPress && props.onPress()}>Close</button>
                                 <button type="button" className="btn btn-lg primaryBG ww-50" onClick={(e) => {
                                    e.preventDefault()
                                    updatePriceEntry(nData1, props?.data?.entry_id, props.onPress)
                                 }}>Submit</button>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

      </div>
   )
}