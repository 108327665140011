import * as React from 'react';
import Chart from "react-apexcharts";

interface BarProps {
   title?: string,
   data: {
      PMS: any,
      DPK: any,
      AGO: any
   }
}
export default (props: BarProps) => {

   const options = {
      xaxis: {
         categories: ["PMS", "DPK", "AGO"],
         axisBorder: {
            show: false
         },
         axisTicks: {
            show: false
         },
      },
      plotOptions: {
         bar: {
            columnWidth: '45%',
            distributed: true,
            borderRadius: 5,
         },

      },
      Chart: {
         toolbar: {
            show: false
         },
         Zoom: {
            enabled: false
         },
         animations: {
            enabled: true,
            easing: 'linear',
            dynamicAnimation: {
               speed: 1000
            }
         },
      },
      legend: {
         show: false
      },
      dataLabels: {
         enabled: false
      },
      style: {
         colors: "#eee",
         fontSize: '12px'
      },
      colors: ['#2E7FC1', '#D47743', '#4BB95E'],
      tooltip: {
         y: {
            formatter: function (val: any) {
               return "LTR " + val + " million"
            }
         }
      },
      yaxis: {
         title: {
            text: 'Liters',
            style: {
               fontWeight: 500
            }
         },
      },
      grid: {
         show: true,
         xaxis: {
            lines: {
               show: true
            }
         },
         yaxis: {
            lines: {
               show: true
            }
         },
         padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
         },
      },

   };
   const series = [
      {
         name: props?.title,
         data: [props.data.PMS, props.data.DPK, props.data.AGO]
      },
   ];

   return (
      <div>
         <Chart options={options} series={series} type="bar" />
      </div>
   );
}
