import { useNavigate } from "react-router-dom";
import { AppAlertData } from "../store/storeslice/alert-slice";
import { useStoreSelector } from "../store/useStore";
import APIendPoint from "../utils/apiEndPoints";
import helpers from "../utils/helpers";

export default () => {
   const navigate = useNavigate();
   const { setAlert, setIsLoading, setHumanData, setHumanCount, setHumanEntryCount, setHumanEntryData, setHumanEntryAllData, setHumanEntryAllCount } = useStoreSelector([
      "setAlert",
      "setIsLoading",
      "setHumanCount",
      "setHumanData",
      "setHumanEntryCount",
      "setHumanEntryData",
      "setHumanEntryAllData",
      "setHumanEntryAllCount"
   ]);
   const getCompany = async () => {
      //  helpers.showToast("loading", "Loading");
      // setIsLoading(true)
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/hr/company`,
            method: "GET",
         })
         .catch((e) => e);
      if (sendRequest && sendRequest.status_code !== 200) {
         // return helpers.showErrorMessage(sendRequest);
         if (sendRequest) {
            return helpers.showToast("error", sendRequest.data?.message);
         } else {
            return helpers.showToast("error", "Please check your network and try again");
         }
      }
      // setIsLoading(false)
      setHumanData(sendRequest.data.data);
   };


   const newCompany = async (title: string, onPress?: Function) => {
      //  setIsLoading(true);

      if (!title) {
         return helpers.showToast("error", "Company name is required");
      }
      helpers.showToast("loading", "Loading");
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/hr/company`,
            method: "POST",
            json: { name: title },
         })
         .catch((e) => e);
      //  setIsLoading(false);
      if (sendRequest && sendRequest.status_code !== 200) {
         // return helpers.showErrorMessage(sendRequest);
         if (sendRequest) {
            return helpers.showToast("error", sendRequest.data?.message);
         } else {
            return helpers.showToast("error", "Please check your network and try again");
         }
      }
      helpers.showToast("success", "Company was created successful");
      onPress && onPress();
   };

   const updateCompany = async (
      title: string,
      id: string,
      onPress?: Function
   ) => {
      if (!title) {
         return helpers.showToast("error", "Company name is required");
      }
      helpers.showToast("loading", "Loading");
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/hr/company/${id}`,
            method: "PUT",
            json: { name: title },
         })
         .catch((e) => e);
      //  setIsLoading(false);
      if (sendRequest && sendRequest.status_code !== 200) {
         if (sendRequest) {
            return helpers.showToast("error", sendRequest.data?.message);
         } else {
            return helpers.showToast("error", "Please check your network and try again");
         }
      }
      getCompany();
      helpers.showToast("success", "Company was update successful");
      onPress && onPress();
   };

   const createEntry = async (
      data: {
         companyID: string;
         quater: string;
         kra: string;
         performance?: string;
      },
      onPress?: Function
   ) => {
      if (!data?.companyID) {
         return helpers.showToast("error", "Company is required");
      }
      if (!data?.quater) {
         return helpers.showToast("error", "quater is required");
      }
      // if (!data?.kra) {
      //    return helpers.showToast("error", "kra is required");
      // }
      helpers.showToast("loading", "Loading");
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/hr/entry`,
            method: "POST",
            json: {
               company_id: data?.companyID,
               quater: data.quater,
               kra: data?.kra,
               kra_status: data?.performance,
            },
         })
         .catch((e) => e);
      //  setIsLoading(false);
      if (sendRequest && sendRequest.status_code !== 200) {
         return helpers.showToast("error", sendRequest.data.message);
      }
      getEntry();
      helpers.showToast("success", "Entry was created successful");
      onPress && onPress();
   };
   const getEntry = async (
      startDate = "",
      endDate = "",
      q = "",
      page = 1,
      perPage = 50
   ) => {
      setIsLoading(true)
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/hr/entry?page=${page}&start_date=${startDate}&end_date=${endDate}&q=${q}&item_per_page=${perPage}`,
            method: "GET",
         })
         .catch((e) => e);
      setIsLoading(false)
      if (sendRequest && sendRequest.status_code !== 200) {
         // return helpers.showErrorMessage(sendRequest);
         if (sendRequest) {
            return helpers.showToast("error", sendRequest.data?.message);
         } else {
            return helpers.showToast("error", "Please check your network and try again");
         }
      }
      // setIsLoading(false)
      setHumanEntryData(sendRequest.data.data);
   };
   const getEntryCount = async (
      startDate = "",
      endDate = "",
      q = "",
      page = 1,
      perPage = 50
   ) => {
      setIsLoading(true)
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/hr/entry?page=${page}&start_date=${startDate}&end_date=${endDate}&q=${q}&item_per_page=${perPage}&component=count`,
            method: "GET",
         })
         .catch((e) => e);
      setIsLoading(false)
      if (sendRequest && sendRequest.status_code !== 200) {
         // return helpers.showErrorMessage(sendRequest);
         if (sendRequest) {
            return helpers.showToast("error", sendRequest.data?.message);
         } else {
            return helpers.showToast("error", "Please check your network and try again");
         }
      }
      // setIsLoading(false)
      setHumanEntryCount(sendRequest.data.data);
   };
   const updateEntry = async (
      data: {
         kra: string;
         performance?: string;
      },
      authID: string,
      onPress?: Function
   ) => {

      helpers.showToast("loading", "Loading");
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/hr/entry/${authID}`,
            method: "PUT",
            json: {
               kra: data?.kra,
               kra_status: data?.performance,
            },
         })
         .catch((e) => e);
      //  setIsLoading(false);
      if (sendRequest && sendRequest.status_code !== 200) {
         if (sendRequest) {
            return helpers.showToast("error", sendRequest.data?.message);
         } else {
            return helpers.showToast("error", "Please check your network and try again");
         }
      }
      getEntry();
      helpers.showToast("success", "Entry was updated successful");
      onPress && onPress();
   };

   const deleteCompany = async (id: string, onPress?: Function) => {
      helpers.showToast("loading", "Loading");
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/hr/company/${id}`,
            method: "DELETE",
         })
         .catch((e) => e);
      if (sendRequest && sendRequest.status_code !== 200) {
         // return helpers.showErrorMessage(sendRequest);
         if (sendRequest) {
            return helpers.showToast("error", sendRequest.data?.message);
         } else {
            return helpers.showToast("error", "Please check your network and try again");
         }
      }
      getCompany();
      getEntry()
      helpers.showToast("success", "Entry was deleted successful");
      onPress && onPress();
   };

   const deleteEntry = async (id: string, onPress?: Function) => {
      helpers.showToast("loading", "Loading");
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/hr/entry/${id}`,
            method: "DELETE",
         })
         .catch((e) => e);
      if (sendRequest && sendRequest.status_code !== 200) {
         // return helpers.showErrorMessage(sendRequest);
         if (sendRequest) {
            return helpers.showToast("error", sendRequest.data?.message);
         } else {
            return helpers.showToast("error", "Please check your network and try again");
         }
      }
      getEntry();
      helpers.showToast("success", "Entry was deleted successful");
      onPress && onPress();
   };

   const getAllEntry = async (
      startDate = "",
      endDate = "",
      q = "",
      page = 1,
      perPage = 50
   ) => {
      // helpers.showToast("loading", "Loading");
      setIsLoading(true)
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/hr/all-entry?page=${page}&start_date=${startDate}&end_date=${endDate}&q=${q}&item_per_page=${perPage}`,
            method: "GET",
         })
         .catch((e) => e);
      setIsLoading(false)
      if (sendRequest && sendRequest.status_code !== 200) {
         if (sendRequest) {
            return helpers.showToast("error", sendRequest.data?.message);
         } else {
            return helpers.showToast("error", "Please check your network and try again");
         }
      }
      setHumanEntryAllData(sendRequest.data.data);
   };

   return {
      getCompany,
      newCompany,
      updateCompany,
      createEntry,
      updateEntry,
      getEntry,
      getEntryCount,
      deleteCompany,
      deleteEntry,
      getAllEntry
   };
};
