import { Routes, RouteObject } from "react-router-dom"
import ComodityIndex from "../pages/comodity/ComodityIndex"


export const ComodityRoute: RouteObject = {
   path: "/comodity",
   children: [
      {
         path: "",
         element: <ComodityIndex />
      },
   ]
}