import React, { useEffect, useState } from 'react'
import { Properties as CSSProperties } from 'csstype'
import { ObjectPayload } from '../typings/general';
import hrFunction from '../hooks/hrFunction';
import { useStoreSelector } from '../store/useStore';
import BarChart2 from './BarChart2';
import BarChart from './BarChart';

export type DataState = {
   data?: ObjectPayload;
   onPress?: Function;
   containerStyle?: CSSProperties;
}
export default (props: DataState) => {
   let singlePriceData = props?.data

   let dpkPrice = singlePriceData?.dpk_vol ? (parseFloat(singlePriceData?.dpk_vol) / 1000000).toFixed(2) : 0

   let agoPrice = singlePriceData?.ago_vol ? (parseFloat(singlePriceData?.ago_vol) / 1000000).toFixed(2) : 0

   let pmsPrice = singlePriceData?.pms_vol ? (parseFloat(singlePriceData?.pms_vol) / 1000000).toFixed(2) : 0

   let data = {
      AGO: agoPrice,
      DPK: dpkPrice,
      PMS: pmsPrice
   }

   return (
      <div style={{
         position: 'fixed', left: 0, top: 0,
         display: 'flex',
         justifyContent: 'center',
         alignItems: 'center',
         backgroundColor: 'rgba(0, 0, 0, 0.3)',
         backgroundSize: "cover",
         width: '100%',
         height: '100vh',
         zIndex: 999,
      }}>
         <div style={{ borderRadius: '20px', padding: '20px', width: '600px', ...props.containerStyle }}>
            <div className='bg-white' style={{ borderRadius: "20px" }}>
               <div className="filterForm w-100">
                  <div className='popupClass1'>
                     <div className="modal-content">
                        <div className="modal-body" style={{ marginTop: "0" }}>
                           <div className="mb-3 d-block">
                              <div className="d-flex align-items-center justify-content-between flex-wrap">
                                 <div>
                                    <h3 className="modal-title fs-5 inviteColor" id="exampleModalLabel">Volume Graph</h3>
                                 </div>
                                 <i onClick={() => props.onPress && props.onPress()} style={{ fontSize: '20px', marginRight: '-5px' }} className="fa fa-times m-color-red showCursor" aria-hidden="true"></i>
                              </div>
                           </div>
                           <div className="mb-3 d-block">

                              <BarChart title="revenue" data={data} />

                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

      </div>
   )
}