import { Routes, RouteObject } from "react-router-dom"
import InputGas from "../pages/input/InputGas"
import InputMining from "../pages/input/InputMining"
import InputComodity from "../pages/input/InputComodity"
import InputHuman from "../pages/input/InputHuman"
import InputTrade from "../pages/input/InputTrade"


export const InputRoute: RouteObject = {
   path: "/input",
   children: [
      {
         path: "",
         element: <InputGas />
      },
      {
         path: "gas",
         element: <InputGas />
      },
      {
         path: "mining",
         element: <InputMining />
      },
      {
         path: "comodity",
         element: <InputComodity />
      },
      {
         path: "human",
         element: <InputHuman />
      },
      {
         path: "trading",
         element: <InputTrade />
      }
   ]
}