
import { ObjectPayload } from "../../typings/general";
import { StoreStateCreator } from "../useStore";

export interface GasSliceState {
   gasData?: ObjectPayload;
   setGasData?: (value: ObjectPayload) => void;

   singleGasData?: ObjectPayload;
   setSingleGasData?: (value: ObjectPayload) => void;

   singleOilData?: ObjectPayload;
   setSingleOilData?: (value: ObjectPayload) => void;

   singleMTData?: ObjectPayload;
   setSingleMTData?: (value: ObjectPayload) => void;

   singlePriceData?: ObjectPayload;
   setSinglePriceData?: (value: ObjectPayload) => void;

   linePriceData?: ObjectPayload;
   setLinePriceData?: (value: ObjectPayload) => void;

   gasCount?: ObjectPayload;
   setGasCount?: (value: ObjectPayload) => void;

   priceData?: ObjectPayload;
   setPriceData?: (value: ObjectPayload) => void;

   priceCount?: ObjectPayload;
   setPriceCount?: (value: ObjectPayload) => void;

   gasTankChart?: ObjectPayload;
   setGasTankChart?: (value: ObjectPayload) => void;

   gasWeeklyChart?: ObjectPayload;
   setGasWeeklyChart?: (value: ObjectPayload) => void;

   gasUserChart?: ObjectPayload;
   setGasUserChart?: (value: ObjectPayload) => void;
}

export const gasStoreSlice: StoreStateCreator<GasSliceState> = (
   set,
   get
) => ({
   singleGasData: {},
   setSingleGasData: (value: ObjectPayload) => set({ singleGasData: value } as any),

   singleOilData: {},
   setSingleOilData: (value: ObjectPayload) => set({ singleOilData: value } as any),

   singlePriceData: {},
   setSinglePriceData: (value: ObjectPayload) => set({ singlePriceData: value } as any),

   singleMTData: {},
   setSingleMTData: (value: ObjectPayload) => set({ singleMTData: value } as any),

   linePriceData: [],
   setLinePriceData: (value: ObjectPayload) => set({ linePriceData: value } as any),

   GasData: [],
   setGasData: (value: ObjectPayload) => set({ gasData: value } as any),

   gasCount: {},
   setGasCount: (value: ObjectPayload) => set({ gasCount: value }),

   priceData: [],
   setPriceData: (value: ObjectPayload) => set({ priceData: value } as any),

   priceCount: {},
   setPriceCount: (value: ObjectPayload) => set({ priceCount: value }),

   gasTankChart: [],
   setGasTankChart: (value: ObjectPayload) => set({ gasTankChart: value } as any),

   gasWeeklyChart: [],
   setGasWeeklyChart: (value: ObjectPayload) => set({ gasWeeklyChart: value } as any),

   gasUserChart: [],
   setGasUserChart: (value: ObjectPayload) => set({ gasUserChart: value } as any),
});
