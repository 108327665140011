import { useNavigate } from "react-router-dom";
import { AppAlertData } from "../store/storeslice/alert-slice";
import { useStoreSelector } from "../store/useStore";
import APIendPoint from "../utils/apiEndPoints";
import helpers from "../utils/helpers";

export default () => {
   const navigate = useNavigate();
   const { setIsLoading, setGasData, setGasCount, setGasTankChart, setGasUserChart, setGasWeeklyChart, setPriceData, setPriceCount, setSingleGasData, setSinglePriceData, setLinePriceData, setSingleMTData, setSingleOilData } = useStoreSelector([
      "setIsLoading",
      "setGasData",
      "setGasCount",
      "setGasTankChart",
      "setGasUserChart",
      "setGasWeeklyChart",
      "setPriceData",
      "setPriceCount",
      "setSingleGasData",
      "setSinglePriceData",
      "setLinePriceData",
      "setSingleMTData",
      "setSingleOilData"
   ]);

   const createOilEntry = async (

      data: { title: string, throughput: string, ndate: string, total_pms: string, total_ago: string, total_dpk: string, dis_pms: string, dis_ago: string, dis_dpk: string, pre_sold: string, vessel_output: string },
      onPress?: Function
   ) => {
      if (!data?.title) {
         return helpers.showToast("error", "title is required");
      }
      helpers.showToast("loading", "Loading");
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/oil`,
            method: "POST",
            json: {
               title: data.title,
               through_put: data?.throughput,
               entry_date: data?.ndate,
               pre_sold: data?.pre_sold,
               vessel_output: data?.vessel_output,
               ago_volume: data?.total_ago,
               pms_volume: data?.total_pms,
               dpk_volume: data?.total_dpk,
               ago_discharge: data?.dis_ago,
               pms_discharge: data?.dis_pms,
               dpk_discharge: data?.dis_dpk,
            },
         })
         .catch((e) => e);
      //  setIsLoading(false);
      if (sendRequest && sendRequest.status_code !== 200) {
         return helpers.showToast("error", sendRequest.data.message);
      }
      getOilEntry();
      helpers.showToast("success", "Entry was created successful");
      onPress && onPress();
   };
   const updateOilEntry = async (

      data: { title: string, throughput: string, ndate: string, total_pms: string, total_ago: string, total_dpk: string, dis_pms: string, dis_ago: string, dis_dpk: string, pre_sold: string, vessel_output: string },
      authID: string,
      onPress?: Function
   ) => {
      if (!data?.title) {
         return helpers.showToast("error", "title is required");
      }
      // if (!data?.dis_ago && !data.dis_dpk && !data.dis_pms) {
      //    return helpers.showToast("error", "Discharge volume is required");
      // }
      helpers.showToast("loading", "Loading");
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/oil/${authID}`,
            method: "PUT",
            json: {
               title: data.title,
               through_put: data?.throughput,
               entry_date: data?.ndate,
               pre_sold: data?.pre_sold,
               vessel_output: data?.vessel_output,
               ago_volume: data?.total_ago,
               pms_volume: data?.total_pms,
               dpk_volume: data?.total_dpk,
               ago_discharge: data?.dis_ago,
               pms_discharge: data?.dis_pms,
               dpk_discharge: data?.dis_dpk,
            },
         })
         .catch((e) => e);
      //  setIsLoading(false);
      if (sendRequest && sendRequest.status_code !== 200) {
         return helpers.showToast("error", sendRequest.data.message);
      }
      getOilEntry();
      helpers.showToast("success", "Entry was updated successful");
      onPress && onPress();
   };

   const createPriceEntry = async (

      data: { ndate1: string, unit_pms: string, unit_ago: string, unit_dpk: string, vol_pms: string, vol_ago: string, vol_dpk: string },
      onPress?: Function
   ) => {

      helpers.showToast("loading", "Loading");
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/oil/price`,
            method: "POST",
            json: {
               entry_date: data?.ndate1,
               ago_volume: data?.vol_ago,
               pms_volume: data?.vol_pms,
               dpk_volume: data?.vol_dpk,
               ago_unit: data?.unit_ago,
               pms_unit: data?.unit_pms,
               dpk_unit: data?.unit_dpk,
            },
         })
         .catch((e) => e);
      //  setIsLoading(false);
      if (sendRequest && sendRequest.status_code !== 200) {
         return helpers.showToast("error", sendRequest.data.message);
      }
      getPriceEntry();
      helpers.showToast("success", "Entry was created successful");
      onPress && onPress();
   };
   const updatePriceEntry = async (

      data: { ndate1: string, unit_pms: string, unit_ago: string, unit_dpk: string, vol_pms: string, vol_ago: string, vol_dpk: string },
      authID: string,
      onPress?: Function
   ) => {

      helpers.showToast("loading", "Loading");
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/oil/price/${authID}`,
            method: "PUT",
            json: {
               entry_date: data?.ndate1,
               ago_volume: data?.vol_ago,
               pms_volume: data?.vol_pms,
               dpk_volume: data?.vol_dpk,
               ago_unit: data?.unit_ago,
               pms_unit: data?.unit_pms,
               dpk_unit: data?.unit_dpk,
            },
         })
         .catch((e) => e);
      //  setIsLoading(false);
      if (sendRequest && sendRequest.status_code !== 200) {
         return helpers.showToast("error", sendRequest.data.message);
      }
      getPriceEntry();
      helpers.showToast("success", "Entry was created successful");
      onPress && onPress();
   };

   const getPriceEntry = async (
      startDate = "",
      endDate = "",
      q = "",
      page = 1,
      perPage = 50
   ) => {
      setIsLoading(true)
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/oil/price?page=${page}&item_per_page=${perPage}&start_date=${startDate}&end_date=${endDate}&q=${q}`,
            method: "GET",
         })
         .catch((e) => e);
      setIsLoading(false)
      if (sendRequest && sendRequest.status_code !== 200) {
         if (sendRequest) {
            return helpers.showToast("error", sendRequest.data?.message);
         } else {
            return helpers.showToast("error", "Please check your network and try again");
         }
      }
      setPriceData(sendRequest.data.data);
   };
   const getOilEntry = async (
      startDate = "",
      endDate = "",
      q = "",
      page = 1,
      perPage = 50
   ) => {
      setIsLoading(true)
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/oil?page=${page}&item_per_page=${perPage}&start_date=${startDate}&end_date=${endDate}&q=${q}`,
            method: "GET",
         })
         .catch((e) => e);
      setIsLoading(false)
      if (sendRequest && sendRequest.status_code !== 200) {
         // return helpers.showErrorMessage(sendRequest.message);
         // console.log(sendRequest)
         if (sendRequest) {
            return helpers.showToast("error", sendRequest.data?.message);
         } else {
            return helpers.showToast("error", "Please check your network and try again");
         }
      }
      setGasData(sendRequest.data.data);
   };
   const getOilEntryCountStatus = async (
      startDate = "",
      endDate = "",
      q = "",
      page = 1,
      perPage = 50
   ) => {
      setIsLoading(true)
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/oil?page=${page}&item_per_page=${perPage}&start_date=${startDate}&end_date=${endDate}&q=${q}&component=count-status`,
            method: "GET",
         })
         .catch((e) => e);
      setIsLoading(false)
      if (sendRequest && sendRequest.status_code !== 200) {
         // return helpers.showErrorMessage(sendRequest.message);
         // console.log(sendRequest)
         if (sendRequest) {
            return helpers.showToast("error", sendRequest.data?.message);
         } else {
            return helpers.showToast("error", "Please check your network and try again");
         }
      }
      setSingleOilData(sendRequest.data.data);
   };
   const getOilMTEntry = async () => {
      setIsLoading(true)
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/oil/dash-oil?component=count-status&q=MT VIRGO`,
            method: "GET",
         })
         .catch((e) => e);
      setIsLoading(false)
      if (sendRequest && sendRequest.status_code !== 200) {
         // return helpers.showErrorMessage(sendRequest);
         if (sendRequest) {
            return helpers.showToast("error", sendRequest.data?.message);
         } else {
            return helpers.showToast("error", "Please check your network and try again");
         }
      }

      setSingleMTData(sendRequest.data.data);
   };
   const getOilTankDay = async (
      startDate = "",
      endDate = "",
      q = "",
      page = 1,
   ) => {
      // setIsLoading(true)
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/oil/tank?page=${page}&start_date=${startDate}&end_date=${endDate}&q=${q}`,
            method: "GET",
         })
         .catch((e) => e);
      // setIsLoading(false)
      if (sendRequest && sendRequest.status_code !== 200) {
         if (sendRequest) {
            return helpers.showToast("error", sendRequest.data?.message);
         } else {
            return helpers.showToast("error", "Please check your network and try again");
         }
      }

      setGasTankChart(sendRequest.data.data);
   };
   const getAllOilEntryCount = async (
      page = 1,
   ) => {
      // helpers.showToast("loading", "Loading");
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/oil?page=${page}&item_per_page=50&component=count`,
            method: "GET",
         })
         .catch((e) => e);
      if (sendRequest && sendRequest.status_code !== 200) {
         if (sendRequest) {
            return helpers.showToast("error", sendRequest.data?.message);
         } else {
            return helpers.showToast("error", "Please check your network and try again");
         }
      }
      setGasCount(sendRequest.data.data);
   };
   const getAllPriceEntryCount = async (
      page = 1,
   ) => {
      // helpers.showToast("loading", "Loading");
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/oil/price?page=${page}&item_per_page=50&component=count`,
            method: "GET",
         })
         .catch((e) => e);
      if (sendRequest && sendRequest.status_code !== 200) {
         if (sendRequest) {
            return helpers.showToast("error", sendRequest.data?.message);
         } else {
            return helpers.showToast("error", "Please check your network and try again");
         }
      }
      setPriceCount(sendRequest.data.data);
   };
   const getpriceCountStatus = async (type = "today") => {
      // setIsLoading(true)
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/oil/price/?component=count-status&type=${type}`,
            method: "GET",
         })
         .catch((e) => e);
      // setIsLoading(false)
      if (sendRequest && sendRequest.status_code !== 200) {
         // return helpers.showErrorMessage(sendRequest);
         if (sendRequest) {
            return helpers.showToast("error", sendRequest.data?.message);
         } else {
            return helpers.showToast("error", "Please check your network and try again");
         }
      }
      // console.log(sendRequest)
      setSinglePriceData(sendRequest.data.data);
   };
   const getpriceCountStatus2 = async (
      startDate = "",
      endDate = "",
      q = "",
   ) => {
      // setIsLoading(true)
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/oil/price/?component=count-status&start_date=${startDate}&end_date=${endDate}&q=${q}`,
            method: "GET",
         })
         .catch((e) => e);
      // setIsLoading(false)
      if (sendRequest && sendRequest.status_code !== 200) {
         // return helpers.showErrorMessage(sendRequest);
         if (sendRequest) {
            return helpers.showToast("error", sendRequest.data?.message);
         } else {
            return helpers.showToast("error", "Please check your network and try again");
         }
      }
      // console.log(sendRequest)
      setSinglePriceData(sendRequest.data.data);
   };

   const getpriceLineStatus = async (type = "one-month") => {
      // setIsLoading(true)
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/oil/price/?component=count-month&type=${type}`,
            method: "GET",
         })
         .catch((e) => e);
      // setIsLoading(false)
      if (sendRequest && sendRequest.status_code !== 200) {
         // return helpers.showErrorMessage(sendRequest);
         if (sendRequest) {
            return helpers.showToast("error", sendRequest.data?.message);
         } else {
            return helpers.showToast("error", "Please check your network and try again");
         }
      }
      // console.log(sendRequest)
      setLinePriceData(sendRequest.data.data);
   };
   const deletePriceEntry = async (id: string, onPress?: Function) => {
      helpers.showToast("loading", "Loading");
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/oil/price/${id}`,
            method: "DELETE",
         })
         .catch((e) => e);
      if (sendRequest && sendRequest.status_code !== 200) {
         // return helpers.showErrorMessage(sendRequest);
         if (sendRequest) {
            return helpers.showToast("error", sendRequest.data?.message);
         } else {
            return helpers.showToast("error", "Please check your network and try again");
         }
      }
      getPriceEntry();
      helpers.showToast("success", "Entry was deleted successful");
      onPress && onPress();
   };
   const deleteVesselEntry = async (id: string, onPress?: Function) => {
      helpers.showToast("loading", "Loading");
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/oil/${id}`,
            method: "DELETE",
         })
         .catch((e) => e);
      if (sendRequest && sendRequest.status_code !== 200) {
         // return helpers.showErrorMessage(sendRequest);
         if (sendRequest) {
            return helpers.showToast("error", sendRequest.data?.message);
         } else {
            return helpers.showToast("error", "Please check your network and try again");
         }
      }
      getOilEntry();
      helpers.showToast("success", "Entry was deleted successful");
      onPress && onPress();
   };

   return {
      createOilEntry,
      getOilEntry,
      getOilTankDay,
      createPriceEntry,
      getPriceEntry,
      getAllPriceEntryCount,
      getAllOilEntryCount,
      updatePriceEntry,
      updateOilEntry,
      getpriceCountStatus,
      getpriceLineStatus,
      getOilMTEntry,
      deletePriceEntry,
      deleteVesselEntry,
      getOilEntryCountStatus,
      getpriceCountStatus2
   };
};
