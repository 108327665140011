import { ObjectPayload } from "../../typings/general";
import { StoreStateCreator } from "../useStore";

export interface TradeSliceState {
   tradeData?: ObjectPayload;
   setTradeData?: (value: ObjectPayload) => void;

   tradeCount?: ObjectPayload;
   setTradeCount?: (value: ObjectPayload) => void;

   tradeEntryData?: ObjectPayload;
   setTradeEntryData?: (value: ObjectPayload) => void;

   tradeEntryCount?: ObjectPayload;
   setTradeEntryCount?: (value: ObjectPayload) => void;
}

export const tradeStoreSlice: StoreStateCreator<TradeSliceState> = (
   set,
   get
) => ({
   tradeData: [],
   setTradeData: (value: ObjectPayload) => set({ tradeData: value }),

   tradeCount: {},
   setTradeCount: (value: ObjectPayload) => set({ tradeCount: value }),

   tradeEntryData: [],
   setTradeEntryData: (value: ObjectPayload) => set({ tradeEntryData: value }),

   tradeEntryCount: {},
   setTradeEntryCount: (value: ObjectPayload) => set({ tradeEntryCount: value }),

});
