import { useEffect, useState } from "react"
import AppLayout from "../../components/AppLayout"
import InputTable from "../../components/InputTable"
import { NavLink } from "react-router-dom"
import EmptyTable from "../../components/EmptyTable"
import PwdChangeModal from "../../components/PwdChangeModal"
import ProfileModal from "../../components/ProfileModal"

export default () => {
   const [newPassword, setNewPassword] = useState(false)
   const [newProfile, setNewProfile] = useState(false)
   useEffect(() => {
   }, [])
   return (
      <AppLayout active="account" title="Accounts">
         <div className="container-fluid">
            <div className="card h-auto pbottom30">
               <div className="row">
                  <div className="col-xl-12">
                     <div className="page-titles myheaderNew">
                        <div className="card-header rmBorder">
                           <h4 className="card-title ccb" style={{ fontSize: "24px" }}>Manage your Account</h4>
                        </div>

                     </div>
                  </div>
               </div>
               <div className="row" style={{ padding: "20px" }}>
                  <div className="col-xl-4 col-lg-4 col-12 mbb-40 mb-3">
                     <div className="card dz-card borderAround showCursor" id="card-title-1" onClick={() => { setNewProfile(true) }}>
                        <div className="card-header flex-wrap border-0 pb-0 ">
                           <h5 className="card-title"></h5>
                           <ul className="nav nav-tabs dzm-tabs bg-trans" id="myTab" role="tablist">
                              <li className="nav-item nav-item22" role="presentation">
                                 <i className="fa fa-user"></i>
                              </li>
                           </ul>
                        </div>
                        <div className="tab-content goUp-20" id="myTabContent">
                           <div className="tab-pane fade show active" id="CardTitle" role="tabpanel" aria-labelledby="home-tab">
                              <div className="card-body pt-0">
                                 <h5 className="card-title font-188">Profile</h5>
                                 <p className="card-text mtt-08">Manage your profile details </p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-4 col-lg-4  col-12 mbb-40 mb-3">
                     <div className="card dz-card borderAround showCursor" id="card-title-1">
                        <div className="card-header flex-wrap border-0 pb-0 ">
                           <h5 className="card-title"></h5>
                           <ul className="nav nav-tabs dzm-tabs bg-trans" id="myTab" role="tablist">
                              <li className="nav-item nav-item22" role="presentation">
                                 <i className="fa fa-bell"></i>
                              </li>
                           </ul>
                        </div>
                        <div className="tab-content goUp-20" id="myTabContent">
                           <div className="tab-pane fade show active" id="CardTitle" role="tabpanel" aria-labelledby="home-tab">
                              <div className="card-body pt-0">
                                 <h5 className="card-title font-188">Notifications</h5>
                                 <p className="card-text mtt-08">Customize your RAMS notifications with ease. </p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-12 mbb-40 mb-3">
                     <div className="card dz-card borderAround showCursor" id="card-title-1" onClick={() => { setNewPassword(true) }}>
                        <div className="card-header flex-wrap border-0 pb-0 ">
                           <h5 className="card-title"></h5>
                           <ul className="nav nav-tabs dzm-tabs bg-trans" id="myTab" role="tablist">
                              <li className="nav-item nav-item22" role="presentation">
                                 <i className="fa fa-cog"></i>
                              </li>
                           </ul>
                        </div>
                        <div className="tab-content goUp-20" id="myTabContent">
                           <div className="tab-pane fade show active" id="CardTitle" role="tabpanel" aria-labelledby="home-tab">
                              <div className="card-body pt-0">
                                 <h5 className="card-title font-188">Password Settings</h5>
                                 <p className="card-text mtt-08">Modify login details for better security of your data </p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            {newPassword &&
               <PwdChangeModal onPress={() => setNewPassword(false)} />
            }
            {newProfile &&
               <ProfileModal onPress={() => setNewProfile(false)} />
            }
         </div>
      </AppLayout>
   )
}