import React, { useEffect, useState } from 'react'
import { Properties as CSSProperties } from 'csstype'
import { ObjectPayload } from '../typings/general';
import hrFunction from '../hooks/hrFunction';
import { useStoreSelector } from '../store/useStore';
import InputTable from './InputTable';
import helpers from '../utils/helpers';
import EmptyTable from './EmptyTable';
import DeleteModal from './DeleteModal';
import commodityFunction from '../hooks/commodityFunction';
import CommodityModalUpdate from './CommodityModalUpdate';
const deleteBtn: string = require('../assets/icons/delete.svg').default
const editBtn: string = require('../assets/icons/edit.svg').default

export type DataState = {
   data?: ObjectPayload;
   onPress?: Function;
   onSubmit?: Function;
   containerStyle?: CSSProperties;
}
export default (props: DataState) => {
   const { getCommodity, deleteCommodity } = commodityFunction()
   const { commodityData } = useStoreSelector(["commodityData"])
   const [editData1, setEditData1] = useState({ data: {}, status: false })
   const [deleteModal1, setDeleteModal1] = useState({ data: {}, status: false })
   useEffect(() => {
      getCommodity()
   }, [])
   return (
      <div style={{
         position: 'fixed', left: 0, top: 0,
         display: 'flex',
         justifyContent: 'center',
         alignItems: 'center',
         backgroundColor: 'rgba(0, 0, 0, 0.3)',
         backgroundSize: "cover",
         width: '100%',
         height: '100vh',
         zIndex: 999,
      }}>
         <div style={{ borderRadius: '20px', padding: '20px', width: '400px', ...props.containerStyle }}>
            <div className='bg-white' style={{ borderRadius: "20px" }}>
               <div className="filterForm w-100">
                  <div className='popupClass1'>
                     <div className="modal-content">
                        <div className="modal-body modalBody" style={{ marginTop: "0" }}>
                           <div className="mb-4 d-block">
                              <div className="d-flex align-items-center justify-content-between flex-wrap">
                                 <div>
                                    <h3 className="modal-title fs-5 inviteColor goFonte" id="exampleModalLabel">Commodities</h3>
                                 </div>
                                 <i onClick={() => props.onPress && props.onPress()} style={{ fontSize: '20px', marginRight: '35px' }} className="fa fa-times m-color-red showCursor" aria-hidden="true"></i>
                              </div>
                           </div>
                           <div className="mb-3 d-block">
                              <div className="col-12 mbb-40" style={{ maxHeight: "600px" }}>
                                 {commodityData && commodityData.length > 0 ?
                                    (commodityData || []).map((item: any, i: any) => (
                                       <div className='row'>
                                          <div className='col-8 mb-2'>
                                             <h6 className='mm-color'>{item?.name}</h6>
                                          </div>
                                          <div className='col-4'>
                                             <div className="d-flex jspace">
                                                <a href="#" onClick={() => setEditData1({ status: true, data: item })} className="btn btn-xs sharp editText"><img src={editBtn} /></a>
                                                <a href="#" onClick={() => setDeleteModal1({ status: true, data: { auth_id: item.entry_id } })} className="btn btn-xs sharp"><img className='delete-img' src={deleteBtn} /></a>
                                             </div>
                                          </div>
                                       </div>
                                    )) : <EmptyTable />}
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         {editData1.status &&
            <CommodityModalUpdate data={editData1.data} onPress={() => setEditData1({ status: false, data: {} })} />
         }
         {deleteModal1.status &&
            <DeleteModal data={deleteModal1.data} onSubmit={deleteCommodity} onPress={() => setDeleteModal1({ status: false, data: {} })} />
         }
      </div>
   )
}