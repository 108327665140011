import { useEffect } from "react"
import oilFunction from "../hooks/oilFunction"
import { useStoreSelector } from "../store/useStore"
import BarChart from "./BarChart"
import BarChart2 from "./BarChart2"

export default () => {
   const { getpriceCountStatus } = oilFunction()
   const { isLoading, singlePriceData } = useStoreSelector(["isLoading", "singlePriceData"])
   useEffect(() => {
      getpriceCountStatus()
   }, [])
   // console.log(singlePriceData)

   let dpkPrice = singlePriceData?.dpk_price ? (parseFloat(singlePriceData?.dpk_price) / 1000000).toFixed(2) : 0
   let dpkVol = singlePriceData?.dpk_vol ? singlePriceData?.dpk_vol : 0

   let agoPrice = singlePriceData?.ago_price ? (parseFloat(singlePriceData?.ago_price) / 1000000).toFixed(2) : 0
   let agoVol = singlePriceData?.ago_vol ? singlePriceData?.ago_vol : 0

   let pmsPrice = singlePriceData?.pms_price ? (parseFloat(singlePriceData?.pms_price) / 1000000).toFixed(2) : 0
   let pmsVol = singlePriceData?.pms_vol ? singlePriceData?.pms_vol : 0

   let data = {
      AGO: agoPrice,
      DPK: dpkPrice,
      PMS: pmsPrice
   }
   return (
      <div className="card h-auto pbottom301 newHeight1">
         <div className="card-header pb-0 border-0 flex-wrap bbBottom " style={{ alignItems: "flex-start" }}>
            <div>
               <div className="chart-title mb-3">
                  {/* <h6 className="heading margin-0 font13">Accumulative Revenue</h6> */}
                  <h6 className="heading margin-0 font13">Accumulative Revenue</h6>
               </div>
            </div>
            <div className="p-static">
               <div className="d-flex align-items-center " style={{ marginBottom: "23px" }}>
                  <select className="image-select default-select dashboard-select pselect" id="box" aria-label="Default" onChange={(e) => getpriceCountStatus(e.target.value)}>
                     <option value="today">Today</option>
                     <option value="week">This Week</option>
                     <option value="month">This Month</option>
                  </select>
               </div>
            </div>
         </div>
         <div className="card-body pt-2 custome-tooltip pb-3 height250">
            <div id="activity">
               <BarChart2 title="revenue" data={data} />
            </div>
         </div>
      </div>
   )
}
