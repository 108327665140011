import { useEffect, useState } from "react"
import AppLayout from "../../components/AppLayout"
import InputTable from "../../components/InputTable"
import { NavLink } from "react-router-dom"
import EmptyTable from "../../components/EmptyTable"
import InviteModal from "../../components/InviteModal"
import userAdminFunction from "../../hooks/userAdminFunction"
import { useStoreSelector } from "../../store/useStore"
import helpers from "../../utils/helpers"
import InviteModalEdit from "../../components/InviteModalEdit"
import DeleteModal from "../../components/DeleteModal"
const deleteBtn: string = require('../../assets/icons/delete.svg').default

export default () => {
   const [newInvite, setNewInvite] = useState(false)
   const [newInviteEdit, setNewInviteEdit] = useState({ data: {}, status: false })
   const [deleteModal, setDeleteModal] = useState({ data: {}, status: false })
   const { fetchAdminList, fetchAdminCount, deleteAdmin } = userAdminFunction()
   const { isLoading, adminData, adminPageCount } = useStoreSelector(["isLoading", "adminData", "adminPageCount"])
   useEffect(() => {
      fetchAdminList()
      fetchAdminCount()
   }, [])
   let counter: number = 1
   return (
      <AppLayout active="invite" title="Invites">
         <div className="container-fluid">
            <div className="card h-auto">
               <div className="row">
                  <div className="col-xl-12">
                     <div className="page-titles myheaderNew">
                        <div className="card-header rmBorder">
                           <h4 className="card-title ccb">Invited team mates</h4>
                        </div>
                        <div className="d-flex flex-wrap my-0 my-sm-0 py-sm-2 py-xl-0">
                           <div className="invoice-btn ">
                              <div className="btn-group mb-1">
                                 <button type="button" className="btn btn-primary brandGreen" onClick={() => { setNewInvite(true) }}>Invite</button>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="row" style={{ padding: "20px" }}>
                  <div className="col-12 mbb-40">
                     {adminData && adminData.length > 0 ?
                        <InputTable data={adminData} headers={["S/N", "Name", "Email", "Designation", "Department", "Date Joined", "Action"]} tableTitle="">
                           {(adminData || []).map((item: any, i: any) => (
                              <tr key={i}>
                                 <th style={{ width: "50px" }}>{counter++}</th>
                                 <th>{item.fullname}</th>
                                 <th style={{ width: "150px" }}>{item.email}</th>
                                 <th>{item.user_type}</th>
                                 <th>{item?.department}</th>
                                 <td>{new Date(item.createdAt).toDateString()}</td>
                                 {/* <td>{helpers.get24HourTo12Hours((item.createdAt))}</td> */}
                                 <td>
                                    <div className="d-flex jspace2">
                                       <a href="#" onClick={() => setNewInviteEdit({ status: true, data: item })} className="btn btn-xs sharp editText">Update</a>
                                       <a href="#" onClick={() => setDeleteModal({ status: true, data: { auth_id: item.auth_id } })} className="btn btn-xs sharp"><img src={deleteBtn} /></a>
                                    </div>
                                 </td>
                              </tr>
                           ))}
                        </InputTable>
                        : <EmptyTable />}
                  </div>
               </div>
            </div>
            {newInvite &&
               <InviteModal onPress={() => setNewInvite(false)} />
            }
            {newInviteEdit.status &&
               <InviteModalEdit data={newInviteEdit.data} onPress={() => setNewInviteEdit({ status: false, data: {} })} />
            }
            {deleteModal.status &&
               <DeleteModal data={deleteModal.data} onSubmit={deleteAdmin} onPress={() => setDeleteModal({ status: false, data: {} })} />
            }
         </div>
      </AppLayout>
   )
}