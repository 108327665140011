import { Routes, RouteObject } from "react-router-dom"
import HumanIndex from "../pages/hr/HumanIndex"


export const HumanRoute: RouteObject = {
   path: "/human",
   children: [
      {
         path: "",
         element: <HumanIndex />
      },
   ]
}