import React, { useState } from 'react'
import { Properties as CSSProperties } from 'csstype'
import { ObjectPayload } from '../typings/general';
import userAdminFunction from '../hooks/userAdminFunction';
import { useStoreSelector } from '../store/useStore';

export type DataState = {
   data?: ObjectPayload;
   onPress?: Function;
   containerStyle?: CSSProperties;
}
export default (props: DataState) => {
   const { updateAdmin } = userAdminFunction()
   const { isLoading } = useStoreSelector(["isLoading"])
   let nname = props?.data?.fullname ? props?.data?.fullname : ""
   let nemail = props?.data?.email ? props?.data?.email : ""
   let ndepartment = props?.data?.department ? props?.data?.department : ""
   let nrole = props?.data?.user_type ? props?.data?.user_type : ""
   const [comState, setComState] = useState({ authID: props?.data?.auth_id, data: { fullname: nname, email: nemail, department: ndepartment, password: '', role: nrole } })
   return (
      <div style={{
         position: 'fixed', left: 0, top: 0,
         display: 'flex',
         justifyContent: 'center',
         alignItems: 'center',
         backgroundColor: 'rgba(0, 0, 0, 0.3)',
         backgroundSize: "cover",
         width: '100%', height: '100vh', zIndex: 999,
      }}>
         <div style={{ borderRadius: '20px', padding: '20px', width: '500px', ...props.containerStyle }}>
            <div className='bg-white' style={{ borderRadius: "20px" }}>
               <div className="filterForm w-100">
                  <div className='popupClass1'>
                     <div className="modal-content">
                        <div className="modal-body" style={{ marginTop: "0" }}>
                           <div className="mb-3 d-block">
                              <div className="d-flex align-items-center justify-content-between flex-wrap">
                                 <h3 className="modal-title fs-5 inviteColor goFonte" id="exampleModalLabel">Invitation</h3>
                                 <i onClick={() => props.onPress && props.onPress()} style={{ fontSize: '20px', marginRight: '-5px' }} className="fa fa-times m-color-red showCursor" aria-hidden="true"></i>
                              </div>
                              <p>Send out invite links to team mates</p>
                           </div>
                           <div className="mb-2 d-block">
                              <label className="form-label d-block mb-2">Full name</label>
                              <input type="text" onChange={(e) => {
                                 setComState(state => ({ ...state, data: { ...state.data, fullname: e.target.value } }))
                              }} defaultValue={props?.data?.fullname} className="form-control w-100 mb-0" />
                           </div>
                           <div className="mb-2 d-block">
                              <label className="form-label d-block mb-2">Email</label>
                              <input type="text" onChange={(e) => {
                                 setComState(state => ({ ...state, data: { ...state.data, email: e.target.value } }))
                              }} defaultValue={props?.data?.email} className="form-control w-100 mb-0" />
                           </div>
                           <div className="mb-2 d-block">
                              <label className="form-label d-block mb-2">Designation/Role</label>
                              <select className="form-control w-100 mb-0 selectH pselect4" aria-label="Default select example" onChange={(e) => {
                                 setComState(state => ({ ...state, data: { ...state.data, role: e.target.value } }))
                              }}>
                                 <option value="">Select a role</option>
                                 <option selected={props?.data?.user_type === "guest"} value="guest">Guest</option>
                                 <option selected={props?.data?.user_type === "data-officer"} value="data-officer">Data Officer</option>
                                 <option selected={props?.data?.user_type === "admin"} value="admin">Admin</option>
                                 <option selected={props?.data?.user_type === "superadmin"} value="admin">Super Admin</option>
                              </select>
                           </div>
                           <div className="mb-2 d-block">
                              <label className="form-label d-block mb-2">Department</label>
                              <select className="form-control w-100 mb-0 selectH pselect4" aria-label="Default select example" onChange={(e) => {
                                 setComState(state => ({ ...state, data: { ...state.data, department: e.target.value } }))
                              }}>
                                 <option value="">Select a department</option>
                                 <option selected={props?.data?.department === "oil & gas"} value="oil & gas">Oil & Gas</option>
                                 <option selected={props?.data?.department === "mining"} value="mining">Mining</option>
                                 <option selected={props?.data?.department === "commodity pricing"} value="commodity pricing">Commodity Pricing</option>
                                 <option selected={props?.data?.department === "trading"} value="trading">Trading</option>
                                 <option selected={props?.data?.department === "human resources"} value="human resources">Human Resources</option>
                              </select>
                           </div>
                           <div className="mb-2 d-block">
                              <label className="form-label d-block mb-2">Password</label>
                              <input type="password" className="form-control w-100 mb-0" onChange={(e) => {
                                 setComState(state => ({ ...state, data: { ...state.data, password: e.target.value } }))
                              }} />
                           </div>
                        </div>
                        <div className="modal-footer">
                           <button type="button" className="btn btn-lg light primaryBG2 ww-48" onClick={() => props.onPress && props.onPress()}>Close</button>
                           <button type="button" className="btn btn-lg primaryBG ww-48" disabled={isLoading || false} onClick={() => updateAdmin(comState.data, props.onPress, props?.data?.auth_id,)}>Update Invite</button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

      </div>
   )
}