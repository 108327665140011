import { useEffect, useState } from "react"
import AppLayout from "../../components/AppLayout"
import TradeComp from "../../components/TradeComp"
import tradeFunction from "../../hooks/tradeFunction"
import { useStoreSelector } from "../../store/useStore"
import Pagination from "../../components/Pagination"
import EmptyTable from "../../components/EmptyTable"
import Loader from "../../components/Loader"

export default () => {
   const { getAllTradeEntry, getAllTradeEntryCount } = tradeFunction()
   const [activePage, setActivePage] = useState(1)
   const { tradeEntryData, tradeEntryCount, isLoading } = useStoreSelector(["tradeEntryData", "tradeEntryCount", "isLoading"])
   useEffect(() => {
      getAllTradeEntry()
      getAllTradeEntryCount()
   }, [])
   return (
      <AppLayout active="trade" title="Trading">
         <div className="container-fluid">
            <div className="card h-auto">
               <div className="card-header pBottom">
                  <div className="tradingHeader">
                     <h4 className="card-title cardH6 headerH">Trading deal sheet</h4>
                  </div>
               </div>
               <div className="pd-10">

                  <div className="row">
                     <div className="d-flex" style={{ overflowY: "hidden", overflowX: "auto" }}>
                        <div className="tsheet tsheet2 d-flex flexDirecColomn bblr" style={{ minHeight: "500px" }}><span>STAGES</span></div>
                        <div className="w-100">
                           <div className="tsheet" style={{ padding: "10px" }}>DEALS</div>
                           {isLoading && <Loader style={{ marginTop: "5%" }} />}
                           {!isLoading && tradeEntryData && tradeEntryData.length > 0 ?
                              <TradeComp data={tradeEntryData} />
                              : !isLoading ? <EmptyTable /> : null}
                        </div>
                     </div>
                     <div className="">
                        {tradeEntryCount && tradeEntryCount?.total > 5 ?
                           < Pagination activePage={activePage} itemPerPage={5} totalPage={tradeEntryCount?.total} onPress={(nextPage) => {
                              getAllTradeEntry(nextPage)
                              setActivePage(nextPage)
                           }} />
                           : ""}
                     </div>
                  </div>

               </div>
            </div>
         </div>
      </AppLayout>
   )
}